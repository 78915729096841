import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
// import { routerReducer } from 'react-router-redux'
import auth from './auth'
import notifications from './notifications/notifications'
import common from './common'
import combos from './combos/combos'
import salesDossier from './dossier/sales/salesDossier'
import registrationDeliveryDossier from './dossier/registrationDelivery/registrationDeliveryDossier'
import paperworkTable from './dossier/common/paperworkTable'
import paperworkDossier from './dossier/paperwork/paperworkDossier'
import purchaseDossier from './dossier/purchase/purchaseDossier'
import campaignDossier from './dossier/campaign/campaignDossier'
import unit from './unit/unit'
import profile from './profile/profile'
import dashboard from './dashboard/dashboard'
import dashboardDossiersList from './dashboard/dossiersList/dossiersList'
import dashboardTask from './dashboard/dashboardTask'
import messageModal from './modals/messageModal'
import documentaryManagementModal from './dossier/common/documentaryManagementModal'
import documentEditionModal from './dossier/common/documentEditionModal'
import documentHistoricalEditionModal from './dossier/common/documentHistoricalEditionModal'
import reassignReplacePurchaseModal from './dossier/common/reassignReplacePurchaseModal'
import vehicle from './dossier/common/vehicle'
import scroller from './commons/scroller'
import windowDimensions from './commons/windowDimensions'
import statusHeader from './dossier/common/statusHeader'
import buttonsHeader from './dossier/common/buttonsHeader'
import buttonsSidebar from './dossier/common/buttonsSidebar'
import dossierRelatedDossiers from './dossier/common/dossierRelatedDossiers'
import entityMasters from './masters/entityMasters'
import roleAdmin from './admin/roleAdmin'
import userAdmin from './admin/userAdmin'
import walletReport from './reports/WalletReport'
import buyReport from './reports/BuyReport'
import stockReport from './reports/StockReport'
import usageReport from './reports/UsageReport'
import saleReport from './reports/SaleReport'
import receiptReport from './reports/ReceiptReport'
import powerbiReport from './reports/PowerbiReport'
import report from './reports/Report'
import conditionsMaintenance from './conditionsMaintenance/conditionsMaintenance'
import search from './search/search'
import sign from './sign/sign'
import documentalSearch from './search/documentalSearch'
import templates from './templates/templates'
import authActionTypes from '../constants/actions/auth'
import processingAgency from './masters/processingAgency/processingAgency'
import processingAgencyAssignation from './masters/processingAgencyAssignation/processingAgencyAssignation'
import paymentMethod from './masters/paymentMethod/paymentMethod'
import financing from './masters/financing/financing'
import documentExport from './dossier/common/documentExport'
import recurrentEntityAdmin from './admin/recurrentEntityAdmin'
import fuel from './masters/fuel/fuel'
import dossierStage from './masters/dossierStage/dossierStage'
import financingType from './masters/processingAgency/financingType'
import purchasePresentType from './masters/processingAgency/purchasePresentType'
import faq from './faq/faq'
import faqMaster from './masters/faq/faq'
import organizedUnitConfiguration from './masters/organizedUnitConfiguration/organizedUnitConfiguration'
import rating from './masters/rating/rating'
import repairshop from './masters/repairshop/repairshop'
import maritalStatus from './masters/maritalStatus/maritalStatus'
import faqSectionMaster from './masters/faqSection/faqSection'
import changeUo from './dossier/common/changeUo'
import deviceInstall from './idocmobile/deviceInstall'
import campaign from './masters/campaign/campaign'
import campaignGroup from './masters/campaignGroup/campaign'
import campaignType from './masters/campaignType/campaignType'
import documentType from './masters/documentType/documentType'
import documentTypeUse from './masters/documentTypeUse/documentTypeUse'
import organizedUnitSaleOperation from './masters/organizedUnitSaleOperation/organizedUnitSaleOperation'
import documentTypeMetadata from './masters/documentTypeMetadata/documentTypeMetadata'
import serviceDossier from './dossier/service/serviceDossier'
import stockDossier from './dossier/stock/stockDossier'
import styleTask from './masters/styleTask/styleTask'
import management from './management/management'
import download from './search/download'
import chargeQuiter from './dossier/common/chargeQuiter'
import financingTypeProduct from './masters/financing/financingTypeProduct'
import landingRequestModal from './dossier/common/landingRequestModal'
import modalMassUpload from './masters/modalUpload/modalMassUpload'
import documentVisualization from './masters/documentVisualization/documentVisualization'
import nonUpdatedDossiers from './dossier/common/nonUpdatedDossiers'
import operationType from './masters/operationType/operationType'
import unattendedProcess from './unattendedProcess/unattendedProcess'
import paperworkTypesMaster from './masters/paperworkTypes/paperwokTypesMaster'
import signatureConfig from './masters/signatureConfig/signatureConfig'
import directionUO from './masters/directionUO/directionUO'
import legalCompany from './masters/legalCompany/legalCompany'
import organizedUnit from './masters/organizedUnit/organizedUnit'
import managementAuxModals from './management/auxModals/auxModals'
import saleType from './masters/saleType/saleType'
import signType from './masters/signType/signType'
import processingAgencyOrganizedUnitConfig from './masters/processingAgencyOrganizedUnitConfig/processingAgencyOrganizedUnitConfig'
import importVehicleModels from './vehicle/importVehicleModels'
import loadSepomexFile from './masters/loadSepomexFile/loadSepomexFile'
import documentMassiveDownloadModal from './dossier/common/documentMassiveDownloadModal'
import documentDataCDAModal from './dossier/common/documentDataCDAModal'
import customField from './masters/customField/customField'
import destinationCraneMaster from './masters/destinationCrane/destinationCrane'
import processingManager from './masters/processingManager/processingManager'
import codeCommisionModal from './dossier/common/codeCommisionModal'
import header from './header/header'
import reservationContractModal from './dossier/common/reservationContractModal'
import templateDataModal from './modals/templateDataModal'

const reducers = {
  auth,
  notifications,
  common,
  profile,
  messageModal,
  unit,
  combos,
  dashboard,
  dashboardDossiersList,
  dashboardTask,
  salesDossier,
  registrationDeliveryDossier,
  paperworkTable,
  paperworkDossier,
  purchaseDossier,
  campaignDossier,
  documentaryManagementModal,
  documentEditionModal,
  documentHistoricalEditionModal,
  reassignReplacePurchaseModal,
  vehicle,
  scroller,
  windowDimensions,
  statusHeader,
  buttonsHeader,
  buttonsSidebar,
  dossierRelatedDossiers,
  entityMasters,
  roleAdmin,
  userAdmin,
  walletReport,
  buyReport,
  stockReport,
  usageReport,
  saleReport,
  receiptReport,
  powerbiReport,
  report,
  conditionsMaintenance,
  search,
  documentalSearch,
  templates,
  form: formReducer,
  // routing: routerReducer,
  processingAgency,
  paymentMethod,
  financing,
  documentExport,
  recurrentEntityAdmin,
  fuel,
  financingType,
  purchasePresentType,
  sign,
  faq,
  faqMaster,
  faqSectionMaster,
  changeUo,
  processingAgencyAssignation,
  deviceInstall,
  campaign,
  campaignGroup,
  campaignType,
  serviceDossier,
  stockDossier,
  styleTask,
  management,
  download,
  chargeQuiter,
  financingTypeProduct,
  landingRequestModal,
  documentVisualization,
  nonUpdatedDossiers,
  paperworkTypesMaster,
  modalMassUpload,
  documentType,
  documentTypeUse,
  organizedUnitSaleOperation,
  documentTypeMetadata,
  operationType,
  directionUO,
  legalCompany,
  organizedUnitConfiguration,
  rating,
  repairshop,
  maritalStatus,
  unattendedProcess,
  organizedUnit,
  managementAuxModals,
  saleType,
  signType,
  signatureConfig,
  dossierStage,
  processingAgencyOrganizedUnitConfig,
  importVehicleModels,
  loadSepomexFile,
  documentMassiveDownloadModal,
  documentDataCDAModal,
  customField,
  destinationCraneMaster,
  processingManager,
  codeCommisionModal,
  header,
  reservationContractModal,
  templateDataModal
}

// const appReducer = combineReducers(reducers)
const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  ...reducers
})

// const rootReducer = (state, action) => {
//   // TODO Review this functionality
//   if (action.type === authActionTypes.LOGOUT_SUCCESS) {
//     state = { common: state.common }
//   }
//   return appReducer(state, action)
// }

const rootReducer = (history) => (state, action) => {
  if (action.type === authActionTypes.LOGOUT_SUCCESS) {
    state = { common: state.common }
  }
  return appReducer(history)(state, action)
}

export default rootReducer

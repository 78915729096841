import React, { PureComponent } from 'react'
import { Glyphicon, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { translateDocumentStatus, translateSignatureStatus, documentStatusClass, checklistStatusIcon } from '../../../../../constants/dossier/common/documentStatus'
import { translateDocumentEntityType, documentEntityType as entityType } from '../../../../../constants/dossier/common/documentEntityType'
import settings from '../../../../../setting'
import { documentTypeUse as documentTypeUseConstants } from '../../../../../constants/backendIds'
import { templateDataFormCode } from '../../../../../constants/dossier/common/templateDataFormCode'

class DocumentListItem extends PureComponent {
  getColorBySignateStatus (value) {
    switch (parseInt(value)) {
      case 0:
        return 'glyphicon glyphicon-edit-yellow'
      case 1:
        return 'glyphicon glyphicon-edit-green'
      case 2:
        return 'glyphicon glyphicon-edit-red'
      case 3:
        return 'glyphicon glyphicon-edit-red'
      case 5:
        return 'glyphicon glyphicon-edit-red'
      case -1:
        return 'glyphicon glyphicon-edit'
      default:
        return ''
    }
  }

  templateDataForm () {
    switch (this.props.documentFile.templateFormCode) {
      case templateDataFormCode.ReservationContract:
        this.props.actions.fetchInsuranceCompanyCombo()
        this.props.actions.openReservationContractModal(this.props.dossierId, true, this.props.documentFile.checklistId)
        break
      case templateDataFormCode.InternalManagementDoc:
        this.props.actions.openInternalManagementDocModal(this.props.dossierId, this.props.documentFile.checklistId, true, null)
        break
      case templateDataFormCode.ResponsibleDeclaration:
        this.props.actions.openResponsibleDeclarationDocModal(this.props.dossierId, this.props.documentFile.checklistId, true, null)
        break
      default:
        this.props.actions.printCheckList(this.props.documentFile.checklistId)
        break
    }
  }

  render () {
    const {
      t,
      dossierId,
      dossierType,
      documentFile,
      readOnly,
      objectId,
      documentEntityType,
      select,
      selectDocument,
      hasVehicle,
      dossierIsHistorical,
      readOnlyFields,
      glyph = true,
      signStatus = true,
      actions: { openDocWarning, openDocumentEditionModalWithChecklistId, openModal, cancelDocument, addDependant, signDocument, openModalVehicleNecessary }
    } = this.props
    documentFile.signatureStatus = String(documentFile.signatureStatus)
    const tKey = 'DOSSIER_COMPONENTS.'
    const statusTooltip = (<Tooltip id="statusTooltip">{documentFile.documentStatus && t(`${tKey}DOCUMENTARY_MANAGEMENT.${translateDocumentStatus[documentFile.documentStatus]}`)}</Tooltip>)
    const signStatusTooltip = (<Tooltip id="signStatusTooltip">{documentFile.signatureStatus && documentFile.signatureStatus !== 'undefined' && t(`${tKey}DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.${translateSignatureStatus[documentFile.signatureStatus]}`)}</Tooltip>)
    const cancelTooltip = (<Tooltip id="cancelTooltip">{t(`${tKey}DOCUMENTARY_MANAGEMENT.CANCEL`)}</Tooltip>)
    const signTooltip = (<Tooltip id="signTooltip">{t(`${tKey}DOCUMENTARY_MANAGEMENT.SIGN`)}</Tooltip>)
    const printTooltip = (<Tooltip id="printTooltip">{t(`${tKey}DOCUMENTARY_MANAGEMENT.PRINT`)}</Tooltip>)
    const dependantTooltip = (<Tooltip id="dependantTooltip">{t(`${tKey}DOCUMENTARY_MANAGEMENT.ADD_DEPENDANT`) + documentFile.addTypeDescription}</Tooltip>)
    return (
      <tr id={(documentFile.documentId)} onClick={() => {
        if (readOnly) return
        if (this.props.formState !== undefined && this.props.formState === false && openDocWarning) {
          new Promise((resolve, reject) => {
            openDocWarning(resolve, reject)
          }).then(() => {
            if (select) {
              selectDocument(documentFile.documentId, documentFile.documentEntityType === entityType.ENTITY && dossierIsHistorical)
            } else {
              if (objectId) {
                if (!readOnlyFields && documentFile.documentTypeUse === documentTypeUseConstants.factory_invoice && !hasVehicle) {
                  openModalVehicleNecessary()
                } else {
                  if (!readOnlyFields || (readOnlyFields && documentFile.documentId)) {
                    openDocumentEditionModalWithChecklistId(documentFile.documentId, documentFile.documentTypeUse, documentFile.checklistId)
                  }
                }
              } else {
                const alert = documentFile.documentTypeEntityId === entityType.ENTITY ? 'entityAlert' : 'vehicleAlert'
                openModal(alert)
              }
            }
          })
        } else {
          if (select) {
            selectDocument(documentFile.documentId, documentFile.documentTypeEntityId === entityType.ENTITY && dossierIsHistorical)
          } else {
            if (objectId) {
              if (!readOnlyFields && documentFile.documentTypeUse === documentTypeUseConstants.factory_invoice && !hasVehicle) {
                openModalVehicleNecessary()
              } else {
                if (!readOnlyFields || (readOnlyFields && documentFile.documentId)) {
                  openDocumentEditionModalWithChecklistId(documentFile.documentId, documentFile.documentTypeUse, documentFile.checklistId)
                }
              }
            } else {
              const alert = documentFile.documentTypeEntityId === entityType.ENTITY ? 'entityAlert' : 'vehicleAlert'
              openModal(alert)
            }
          }
        }
      }}
      >
        {glyph && (
          <td>
            <span><Glyphicon glyph={checklistStatusIcon[documentFile.checklistStatus]}/></span>
          </td>
        )}
        <td className="text-left">
          <span>
            <OverlayTrigger placement="right" overlay={statusTooltip}>
              <i id="documentStatus" className={'ico-document-' + documentStatusClass[translateDocumentStatus[documentFile.documentStatus]]}/>
            </OverlayTrigger>
          </span>
        </td>
        {signStatus && (
          <td className="text-left">
            <span>
              <OverlayTrigger placement="left" overlay={signStatusTooltip}>
                <i id="signatureStatus" className={this.getColorBySignateStatus(documentFile.signatureStatus)}/>
              </OverlayTrigger>
            </span>
          </td>
        )}
        <td><span>{documentFile.documentName}</span></td>
        <td><span className="file-text">{(t(`${tKey}DOCUMENTARY_MANAGEMENT.${translateDocumentEntityType[documentEntityType] ?? documentEntityType}`))}</span></td>
        <td><span className="file-text">{documentFile.validationDate}</span></td>
        <td><span className="file-text">{documentFile.incorporationDate}</span></td>
        <td>
          <span className="document-modal">
            {!readOnlyFields && documentFile.showPrintButton && documentFile.checklistId &&
            (<>
              <OverlayTrigger placement="left" overlay={printTooltip}><i onClick={(e) => {
                e.stopPropagation()
                documentFile.checklistId && this.templateDataForm()
              }} className="ico-print" id="ico-print"/></OverlayTrigger></>)}
            {!readOnlyFields && documentFile.showAddButton &&
            (<OverlayTrigger placement="left" overlay={dependantTooltip}>
              <i onClick={(e) => {
                e.stopPropagation()
                addDependant(dossierType,
                  documentFile.documentId,
                  documentFile.documentEntityType,
                  documentFile.documentTypeUse)
              }}
              className="ico-document-plus" id="ico-document-plus"/>
            </OverlayTrigger>)}
            {!readOnlyFields && documentFile.showCancelButton
              ? (<OverlayTrigger placement="left" overlay={cancelTooltip}>
                <i className="ico-cerrar" onClick={(e) => {
                  e.stopPropagation()
                  cancelDocument(dossierType, documentFile.documentId, documentFile.documentEntityType, dossierId)
                }}/>
              </OverlayTrigger>
              ) : null
            }
            {!readOnlyFields && settings.showSignDocumentButton && documentFile.documentTypeUse === 111 && documentFile.checklistId &&
              (documentFile.documentId === null || documentFile.documentId === undefined)
              ? (<OverlayTrigger placement="left" overlay={signTooltip}>
                <Glyphicon glyph="edit" onClick={(e) => {
                  e.stopPropagation()
                  signDocument(documentFile.checklistId)
                }}/>
              </OverlayTrigger>
              ) : null
            }
          </span>
        </td>
      </tr>
    )
  }
}

export default DocumentListItem

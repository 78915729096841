import React, { PureComponent } from 'react'
import { Modal, Row, Col, Button, Form, OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import ReactImageZoom from 'react-image-zoom'
import ImgPage from '../../../../../containers/commons/general/ImgPage'
import SmallLoaderPage from '../../../../../containers/commons/SmallLoaderPage'
import InputSelect from '../../../../commons/form/InputSelect'
import InputText from '../../../../commons/form/InputText'
import InputNatural from '../../../../commons/form/InputNatural'
import InputMoney from '../../../../commons/form/InputMoney'
import InputDecimal from '../../../../commons/form/InputDecimal'
import InputDatePicker from '../../../../commons/form/InputDatePicker'
import InputDateTimePicker from '../../../../commons/form/InputDateTimePicker'
import InputCheckBox from '../../../../commons/form/InputCheckBox'
import SimpleInputText from '../../../../commons/simpleForm/SimpleInputText'
import { typeView as typeViewConstant } from '../../../../../constants/dossier/common/documentTypeView'
import { documentStatus as documentStatusConstant, translateDocumentStatus, documentStatusClass, translateSignatureStatus, sealStatus } from '../../../../../constants/dossier/common/documentStatus'
import { typeFields as typeFieldsConstant, codeMetadataFields } from '../../../../../constants/dossier/common/typeFields'
import { dossierTypes as dossierTypesConstant } from '../../../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../../constants/dossier/common/dossierSubType'
import settings from '../../../../../setting'
import DropZone from 'react-dropzone'
import RejectCommentModal from '../../RejectCommentModal'
import CancelSignModal from '../../CancelSignModal'
import EntityPopoverEditionModal from './EntityPopoverEditionModal'
import VehiclePopoverEditionModal from './VehiclePopoverEditionModal'
import DossierPopoverEditionModal from './DossierPopoverEditionModal'
import { validateDate, validateRegex, customValidations, getAccepFiles, validateLicensePlate as validateLicensePlateFunciton, validateMetadatoByCode } from '../../../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../../../util/normalizeFunctions'
import { entityTypesId as entityTypes, backEndIdDocumentStatus, documentTypeUse as documentTypeUseConstants } from '../../../../../constants/backendIds'
import SignTypesModal from '../../SignTypesModal'
import TrackingContainer from '../../../../../containers/tracking/trackingContainer'
import { CustomDimensionsConstant } from '../../../../../constants/tracking/CustomDimensionsConstant'
import { TrackingActions } from '../../../../../constants/tracking/TrackingActions'
import { currencies } from '../../../../../constants/dossier/common/currencies'
import { languages } from '../../../../../constants/dossier/common/languages'
import { amountToWordsCurrencyTextType, fleetMode } from '../../../../../constants/dossier/common/documentEditionModal'
import { numberToWords, numberToWordsI18n, capitalizeFirstLetter } from '../../../../../util/utils'
import { fromTemplate } from '../../../../../constants/filesToUpload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowUp } from '@fortawesome/free-solid-svg-icons'
import ReservationContractModalPage from '../../../../../containers/dossiers/common/ReservationContractModalPage'
import InternalManagementDocModalPage from '../../../../../containers/dossiers/common/templateModals/InternalManagementDocModalPage'
import { templateDataFormCode } from '../../../../../constants/dossier/common/templateDataFormCode'
import ResponsibleDeclarationDocModalPage from '../../../../../containers/dossiers/common/templateModals/ResponsibleDeclarationDocModalPage'
const averageCharacter = 'c'

class DocumentEdition extends PureComponent {
  getColorBySignateStatus (value) {
    switch (parseInt(value)) {
      case 0:
        return 'glyphicon glyphicon-edit-yellow'
      case 1:
        return 'glyphicon glyphicon-edit-green'
      case 2:
        return 'glyphicon glyphicon-edit-red'
      case 3:
        return 'glyphicon glyphicon-edit-red'
      case 5:
        return 'glyphicon glyphicon-edit-red'
      case -1:
        return 'glyphicon glyphicon-edit'
      default:
        return ''
    }
  }

  constructor (props) {
    super(props)
    this.state = {
      actualDossierCharged: null,
      onDropfileUploadSheet: false,
      onDropfileUpload: false,
      invisible: true,
      documentTitle: averageCharacter,
      documentTitleCharWidth: null,
      documentTitleCharLimit: null,
      showTitle: false,
      files: [],
      selectedDocType: null,
      showRejectCommentModal: false,
      showCancelSignModal: false,
      showSignTypesModal: false,
      hasNewDocument: false,
      showFileUploader: false,
      showSheets: false,
      showMissingDocumentTypeError: false,
      hasInvalidFile: false,
      showDocumentCarrusel: false,
      autoCloseDocumentCarrusel: false,
      isDocumentCarruselEndReached: false,
      showPageCarrusel: true,
      autoClosePageCarrusel: false,
      signTypesCombo: [],
      zoomProps: {
        width: 0,
        height: 0,
        zoomWidth: 0,
        scale: 0.9,
        img: '',
        offset: { vertical: 0, horizontal: 10 },
        zoomStyle: 'box-shadow: 0px 2px 5px rgba(0,0,0,0.3)',
        zoomLensStyle: 'background-color: rgba(0,0,0,0.3); cursor: crosshair;'
      },
      hasDropzoneImg: false,
      parametroFecha: Date.now(),
      dropzoneActive: false,
      dropzonePanelActive: false,
      carouselItemsPerPage: 20,
      carouselPaginationIdx: 1,
      signStatusId: null,
      documentId: null,
      cancelDocList: null,
      printTemplate: false
    }
    this.toggleFullScreenDocumentModal = this.toggleFullScreenDocumentModal.bind(this)
    this.toggleFullScreenDocumentSheetModal = this.toggleFullScreenDocumentSheetModal.bind(this)
    this.deleteDocumentSheet = this.deleteDocumentSheet.bind(this)
    this.cancelDocument = this.cancelDocument.bind(this)
    this.setActivePage = this.setActivePage.bind(this)
    this.updateImgSize = this.updateImgSize.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.onDragEnter = this.onDragEnter.bind(this)
    this.onDragLeave = this.onDragLeave.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.rejectDocument = this.rejectDocument.bind(this)
    this.documentTitleElementRef = React.createRef()
    this.validateDate = this.validateDate.bind(this)
    this.openCancelSignModal = this.openCancelSignModal.bind(this)
  }

  updateBaseTrackingAction () {
    this.baseTrackingAction = {
      href: window.location.href,
      documentTitle: this.props.dossierType + '_page',
      organizedUnitId: this.props.organizedUnitId,
      category: (this.props.dossierType === '' || !this.props.dossierType) ? '-' : this.props.dossierType,
      customDimensions: [
        { id: CustomDimensionsConstant.DOCUMENT_TYPE_ID, value: this.props.documentTypeId },
        { id: CustomDimensionsConstant.DOCUMENT_TYPE_USE_ID, value: this.props.documentTypeUseId },
        { id: CustomDimensionsConstant.DOCUMENT_TYPE_USE_NAME, value: this.props.documentTypeUseName }
      ]
    }
  }

  // La subida, validación, anulación, solicitud de firma, subida de hojas, borrado de hojas, apertura de la modal de documentos. Añadir información del documento si es posible.

  factoryOpenDocumentModalAction (dossierType, organizedUnitId, documentTypeUseId, documentTypeUseName) {
    this.updateBaseTrackingAction()
    var documentTypeuseDimension = this.baseTrackingAction.customDimensions.filter(x => x.id === CustomDimensionsConstant.DOCUMENT_TYPE_USE_ID)
    documentTypeuseDimension[0].value = documentTypeUseId

    dossierType = (dossierType === '' || !dossierType) ? '-' : dossierType

    var documentTypeuseNameDimension = this.baseTrackingAction.customDimensions.filter(x => x.id === CustomDimensionsConstant.DOCUMENT_TYPE_USE_NAME)
    documentTypeuseNameDimension[0].value = documentTypeUseName
    return { ...this.baseTrackingAction, action: TrackingActions.OPEN_DOCUMENT_MODAL, category: dossierType, organizedUnitId: organizedUnitId }
  }

  factoryCreateDocumentAction (documentType) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.CREATE_DOCUMENT }
  }

  factoryCreateDocumentSheetAction (file, documentId) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.CREATE_DOCUMENT_SHEET }
  }

  factoryValidateDocumentAction (documentId) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.VALIDATE_DOCUMENT }
  }

  factorycancelDocumentAction (documentId) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.CANCEL_DOCUMENT }
  }

  factorySignRequestAction (documentId) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.SIGN_REQUEST }
  }

  factorySignCancelAction (documentId) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.SIGN_CANCEL }
  }

  factoryDeleteSheetAction (documentId) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.DELETE_SHEET }
  }

  factorySaveDocumentAction (documentType) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.SAVE_DOCUMENT }
  }

  factoryRejectDocumentAction (documentType) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.REJECT_DOCUMENT }
  }

  ReturnFormatDocument (acceptFilesDropzone, tKey, t) {
    if (acceptFilesDropzone === '.jpg, .png, .jpeg') {
      return t(`${tKey}SELECT_FILES_PHOTO`)
    } else if (acceptFilesDropzone === 'application/pdf') {
      return t(`${tKey}SELECT_FILES_PDF`)
    } else {
      return t(`${tKey}SELECT_FILES`)
    }
  }

  ChangesList (idItem, idComponent, notDocument, documentId) {
    if (notDocument) {
      if (idItem === notDocument.toString()) {
        this.setState({
          canSave: true
        })
        if (documentId) {
          this.setState({
            onDropfileUpload: false
          })
        } else {
          this.setState({
            onDropfileUpload: true
          })
        }
      } else {
        this.setState({
          canSave: false
        })
      }
    }
    new Promise((resolve, reject) => {
      this.props.actions.checkDependenceMetadato(idComponent, resolve)
    }).then((result) => {
      if (result) {
        if (idItem === result.fieldDependenceValue && idComponent === result.fieldDependenceId) {
          this.setState({
            invisible: false
          })
        } else if (idItem !== result.fieldDependenceValue && idComponent === result.fieldDependenceId) {
          this.setState({
            invisible: true
          })
        }
      }
    })
  }

  closeModal (canShowAdvise = true) {
    new Promise((resolve, reject) => {
      const showAdvise = this.props.docResult &&
        this.props.docResult.buttonsPermission &&
        this.props.docResult.buttonsPermission.buttonSave &&
        this.props.docResult.fields && this.props.docResult.fields.length > 0 &&
        canShowAdvise && (this.state.hasNewDocument || !this.props.pristine)
      return this.props.actions.checkCanCloseDocumentModal(showAdvise, resolve)
    }).then((result) => {
      if (result) {
        const hasNewDoc = this.state.hasNewDocument
        this.setState({
          documentTitle: averageCharacter,
          documentTitleCharWidth: null,
          documentTitleCharLimit: null,
          showTitle: false,
          files: [],
          selectedDocType: null,
          hasNewDocument: false,
          showFileUploader: false,
          showSheets: false,
          showMissingDocumentTypeError: false,
          hasInvalidFile: false,
          zoomProps: { ...this.state.zoomProps, width: 0, height: 0, zoomWidth: 0, img: '' },
          hasDropzoneImg: false,
          showDocumentCarrusel: false,
          isDocumentCarruselEndReached: false,
          showPageCarrusel: true,
          dropzoneActive: false,
          dropzonePanelActive: false,
          carouselPaginationIdx: 1,
          canSave: false
        })
        this.props.initialize({})
        const refreshAfterChange = (hasNewDoc || !this.props.pristine) && this.props.fleetMode === null
        this.props.actions.closeAddOrEditDocumentModal(refreshAfterChange)
      }
    })
  }

  onDrop (fileToUpload, rejectedFiles, organizedUnitId) {
    if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
      this.setState({ hasInvalidFile: true })
    } else {
      this.setState({ files: [fileToUpload[0]], hasNewDocument: true, hasInvalidFile: false })
      if (fileToUpload[0]) {
        if (this.props.docResult.documentId) {
          if (this.props.docResult.sheets.length === 0 && this.props.docResult.update !== true && this.props.docResult.storageDocumentId === null) {
            new Promise((resolve, reject) => {
              this.props.actions.createDocument('', this.props.checklistId, this.props.documentTypeUseId || this.state.selectedDocType, this.props.dossierId, resolve)
              this.props.trackAction(this.factoryCreateDocumentAction(this.state.selectedDocType))
            }).then((result) => {
              if (result) {
                this.props.actions.createDocumentSheet(this.state.files[0], result.organizedUnitId, result.documentId)
                this.setState({
                  actualDossierCharged: result.documentId
                })
                this.props.trackAction(this.factoryCreateDocumentSheetAction(this.state.files[0], result.documentId))
              }
            })
          } else {
            this.props.actions.createDocumentSheet(fileToUpload[0], organizedUnitId)
            this.props.trackAction(this.factoryCreateDocumentSheetAction(fileToUpload[0]))
          }
          this.setState({
            onDropfileUploadSheet: true
          })
        } else {
          if (!this.props.dossierIds) {
            if (this.props.docResult != null && this.props.docResult.chargeIdParent && this.props.docResult.buttonRefund) {
              this.props.actions.createDocument(fileToUpload[0], this.props.checklistId, this.props.documentTypeUseId || this.state.selectedDocType, this.props.dossierId, null, null, this.props.docResult.chargeIdParent)
            } else if (this.props.docResult != null && this.props.docResult.chargeId != null) {
              this.props.actions.createDocument(fileToUpload[0], this.props.checklistId, this.props.documentTypeUseId || this.state.selectedDocType, this.props.dossierId, null, this.props.docResult.chargeId)
            } else {
              this.props.actions.createDocument(fileToUpload[0], this.props.checklistId, this.props.documentTypeUseId || this.state.selectedDocType, this.props.dossierId)
            }
          } else {
            this.props.actions.createDocumentFleets(fileToUpload[0], this.props.checklistId, this.props.documentTypeUseId || this.state.selectedDocType, this.props.dossierIds)
          }
          this.props.trackAction(this.factoryCreateDocumentAction(this.state.selectedDocType))
        }
        this.setState({
          onDropfileUpload: true
        })
      }
    }
  }

  handleGenerateAndUploadClick (templateFormCodeCalled = false, formValues = null) {
    if (this.props.templateFormCode && !templateFormCodeCalled) {
      this.templateDataForm(false)
    } else {
      let fileToUpload = { name: fromTemplate }
      this.props.actions.createDocument(fileToUpload, this.props.checklistId, this.props.documentTypeUseId || this.state.selectedDocType,
        this.props.dossierId, null, null, null, true, formValues)
      this.props.trackAction(this.factoryCreateDocumentAction(this.state.selectedDocType))
      this.setState({
        onDropfileUpload: true
      })
    }
  }

  onDragEnter (panel = false) {
    this.setState(panel ? { dropzonePanelActive: true } : { dropzoneActive: true })
  }

  onDragLeave (panel = false) {
    this.setState(panel ? { dropzonePanelActive: false } : { dropzoneActive: false })
  }

  changeAll (values) {
    Object.keys(values.entityValue).map(value => {
      this.props.change('AddOrEditDocumentForm', `entityValue.${value}`, values.entityValue[value])
    })
  }

  submitDocument (values, fleetsVisor) {
    if (this.props.fleetChargeId && this.props.fleetChargeId > -1) {
      this.updateFleetChargeDocument(values)
    } else {
      const errors = validateFunction(values, this.props)
      if (errors.entityValue && Object.keys(errors.entityValue || {}).length !== 0) return
      const valuesInFields = this.props.docResult.fields.map(field => {
        return {
          ...field, entityValue: values.entityValue[field.fieldId]
        }
      })
      if (values.validateAction) {
        this.props.actions.validateDocument(this.props.docResult.documentId, valuesInFields, values.comment)
        this.props.trackAction(this.factoryValidateDocumentAction(this.props.docResult.documentId))
      } else {
        if (this.props.docResult.update || this.props.docResult.sheets.length > 0) {
          this.props.actions.saveDocument(valuesInFields, values.comment, fleetsVisor)
        } else {
          this.props.actions.saveDocument(valuesInFields, values.comment, fleetsVisor)
          this.setState({
            onDropfileUpload: false,
            hasNewDocument: false
          })
        }
        this.props.trackAction(this.factorySaveDocumentAction())
      }
    }
  }

  submitFleetDocument (values) {
    const errors = validateFunction(values, this.props)
    if (errors.entityValue && Object.keys(errors.entityValue || {}).length !== 0) return
    const valuesInFields = this.props.docResult.fields.map(field => {
      return {
        ...field, entityValue: values.entityValue[field.fieldId]
      }
    })
    if (values.validateAction) {
      this.props.actions.validateDocument(this.props.docResult.documentId, valuesInFields, values.comment)
      this.props.trackAction(this.factoryValidateDocumentAction(this.props.docResult.documentId))
    } else {
      let infoIncome = {
        documentTypeId: this.props.documentTypeUseId,
        fields: valuesInFields,
        comment: values.comment
      }
      if (this.props.docResult.update || this.props.docResult.sheets.length > 0) {
        this.props.actions.createIncorporateDocumentFleetProcess(this.state.files[0], this.props.documentTypeUseId, infoIncome, this.props.fleetDocumentDetails, this.props.fleetId, () => this.props.actions.closeAddOrEditDocumentModalSuccess())
      } else {
        this.props.actions.createIncorporateDocumentFleetProcess(this.state.files[0], this.props.documentTypeUseId, infoIncome, this.props.fleetDocumentDetails, this.props.fleetId, () => this.props.actions.closeAddOrEditDocumentModalSuccess())
        this.setState({
          onDropfileUpload: false,
          hasNewDocument: false
        })
      }
      this.props.trackAction(this.factorySaveDocumentAction())
    }
  }

  validateFleetDocument (values) {
    const errors = validateFunction(values, this.props)
    if (errors.entityValue && Object.keys(errors.entityValue || {}).length !== 0) return
    const valuesInFields = this.props.docResult.fields.map(field => {
      return {
        ...field, entityValue: values.entityValue[field.fieldId]
      }
    })

    this.props.actions.createValidateDocumentFleetProcess(valuesInFields, values.comment, null, this.props.fleetDocumentDetails, this.props.fleetId, () => this.props.actions.closeAddOrEditDocumentModalSuccess())
  }

  updateFleetChargeDocument (values) {
    const errors = validateFunction(values, this.props)
    if (errors.entityValue && Object.keys(errors.entityValue || {}).length !== 0) return
    const valuesInFields = this.props.docResult.fields.map(field => {
      return {
        ...field, entityValue: values.entityValue[field.fieldId]
      }
    })

    this.props.actions.createUpdateDocumentFleetChargeProcess(valuesInFields, values.comment, null, this.props.fleetDocumentDetails, this.props.fleetId, () => this.props.actions.closeAddOrEditDocumentModalSuccess())
  }

  handleDocTypeChange (documentTypeId) {
    const documentType = this.props.combos.documentsTypes.find((documentsType) => documentsType.id === documentTypeId)
    if (documentType && documentType.id === documentTypeUseConstants.factory_invoice && !this.props.vehicleId) {
      this.props.change('documentType', null)
      this.props.actions.openModalVehicleNecessary()
    } else {
      if (!documentTypeId) this.setState({ files: [] })
      this.setState({ selectedDocType: documentTypeId, showMissingDocumentTypeError: !documentTypeId })

      if (!this.props.dossierIds) {
        this.props.actions.onDocumentTypeIdChange(documentTypeId, documentType.value)
      } else {
        this.props.actions.onDocumentTypeIdChangeFleets(documentTypeId, documentType.value, this.props.dossierIds)
      }
    }
  }

  copyMetaToEntityValues () {
    this.props.docResult.fields.forEach(field => {
      this.props.change(`entityValue.${field.fieldId}`, field.metaValue || '')
    })
  }

  uploadNewVersion () {
    this.setState({ hasNewDocument: true })
    this.props.docResult.fields.forEach(field => {
      this.props.change(`entityValue.${field.fieldId}`, '')
    })
    this.props.actions.uploadNewVersion()
  }

  rejectDocument (values, resolve, reject) {
    if (this.props.fleetChargeId && this.props.fleetChargeId > 0) {
      this.props.actions.setOperateActionRejectProcess(values, [], this.props.fleetId, () => this.props.actions.closeAddOrEditDocumentModalSuccess(), this.props.fleetChargeId)
    } else {
      this.props.actions.rejectDocument(this.props.docResult.documentId, values, resolve, reject)
      this.props.trackAction(this.factoryRejectDocumentAction())
    }
  }

  toggleFullScreenDocumentModal (e) {
    e.stopPropagation()
    this.props.actions.toggleFullScreenDocumentModal(this.props.docResult.documentId, false, this.props.organizedUnitId)
  }

  downloadDocument (e) {
    e.stopPropagation()
    this.props.actions.toggleFullScreenDocumentModal(this.props.docResult.documentId, true, this.props.organizedUnitId)
  }

  refreshDocument (e) {
    e.stopPropagation()
    let date = Date.now()

    this.setState({
      parametroFecha: date,
      zoomProps: {
        ...this.state.zoomProps,
        img: `${settings.url}document/${this.props.docResult.documentId}/${this.props.activeSheet !== 0 ? `sheet/${this.props.activeSheet}/` : ''}preview?token=${this.props.token}${this.props.activePage && '&pageId=' + this.props.activePage}&fecha=${date}&organizedUnitId=${this.props.organizedUnitId}`
      }
    })
    this.props.actions.refreshDocResul(this.props.docResult.documentId)
  }

  toggleFullScreenDocumentSheetModal (e, sheetId) {
    e.stopPropagation()
    this.props.actions.toggleFullScreenDocumentSheetModal(this.props.docResult.documentId, sheetId)
  }

  downloadDocumentSheet (e, sheetId) {
    e.stopPropagation()
    this.props.actions.toggleFullScreenDocumentSheetModal(this.props.docResult.documentId, sheetId, true)
  }

  deleteDocumentSheet (e, sheetId, organizedUnitId) {
    e.stopPropagation()
    new Promise((resolve, reject) => {
      var deleteDocumentSheetResult = this.props.actions.deleteDocumentSheet(this.props.docResult.documentId, sheetId, resolve, reject, organizedUnitId)
      return deleteDocumentSheetResult
    }).then(() => {
      const currentDate = Date.now()
      this.setActivePage(0, 1)
      let img = img = `${settings.url}document/${this.props.docResult.documentId}/${this.props.activeSheet !== 0 ? `sheet/${this.props.activeSheet}/` : ''}preview?token=${this.props.token}&fecha=${currentDate}&organizedUnitId=${organizedUnitId}`
      if (this.props.docResult.sheets.length === 0 || this.props.activeSheet === 0) {
        img = `${settings.url}document/${this.props.docResult.documentId}/preview?token=${this.props.token}&pageId=1&fecha=${currentDate}&organizedUnitId=${organizedUnitId}`
      }
      this.setState({
        parametroFecha: currentDate,
        zoomProps: {
          ...this.state.zoomProps,
          img: img
        }
      })
    })
  }

  getDocumentTitle (props) {
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.'
    return (props.docResult.documentId ? props.t(`${tKey}EDIT`) : props.t(`${tKey}ADD`)) + (props.documentTypeUseName ? (' - ' + props.documentTypeUseName) : '')
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let docDate = this.state.parametroFecha
    if (this.props.showDocumentEditionModal && !nextProps.showDocumentEditionModal) {
      this.setState({
        documentTitle: averageCharacter,
        documentTitleCharWidth: null,
        documentTitleCharLimit: null,
        showTitle: false,
        files: [],
        selectedDocType: null,
        dropzoneActive: false,
        dropzonePanelActive: false,
        carouselPaginationIdx: 1,
        canSave: false,
        hasNewDocument: false
      })
    }
    if (!this.props.showDocumentEditionModal && nextProps.showDocumentEditionModal) {
      nextProps.actions.setWindowDimensions(nextProps.windowWidth - 100, nextProps.windowHeight)
      window.setTimeout(() => nextProps.actions.setWindowDimensions(nextProps.windowWidth, nextProps.windowHeight), 100)
      this.props.trackAction(this.factoryOpenDocumentModalAction(nextProps.dossierType, nextProps.organizedUnitId, nextProps.documentTypeUseId, nextProps.documentTypeUseName))
    }

    if (nextProps.docResult && nextProps.docResult.documentId) {
      docDate = Date.now()
      if (this.state.documentId === nextProps.docResult.documentId && this.state.signStatusId !== nextProps.docResult.signStatusId) {
        this.setState({ parametroFecha: docDate })
      }

      this.setState({
        signStatusId: nextProps.docResult.signStatusId,
        documentId: nextProps.docResult.documentId
      })
    }

    if (nextProps.docResult && nextProps.docResult.documentId &&
      (
        this.props.docResult.documentId !== nextProps.docResult.documentId ||
        this.props.activeSheet !== nextProps.activeSheet ||
        this.props.activePage !== nextProps.activePage
      )
    ) {
      let organizedUnitId = this.props.organizedUnitId ?? nextProps.organizedUnitId
      this.setState({
        zoomProps: {
          ...this.state.zoomProps,
          img: `${settings.url}document/${nextProps.docResult.documentId}/${nextProps.activeSheet !== 0 ? `sheet/${nextProps.activeSheet}/` : ''}preview?token=${nextProps.token}${nextProps.activePage && '&pageId=' + nextProps.activePage}&fecha=${docDate}&organizedUnitId=${organizedUnitId}`
        }
      })
    }

    if (nextProps.docResult.fields !== this.props.docResult.fields && this.props.metaLoading) {
      const fields = { metaValue: {} }
      nextProps.docResult.fields && nextProps.docResult.fields.length && nextProps.docResult.fields.forEach(field => {
        fields.metaValue[field.fieldId] = field.metaValue === undefined ? null : field.metaValue
      })
      this.props.change('fields', fields)
    } else if (nextProps.docResult.fields !== this.props.docResult.fields) {
      const fields = { entityValue: {}, metaValue: {} }
      nextProps.docResult.fields && nextProps.docResult.fields.length && nextProps.docResult.fields.forEach(field => {
        fields.entityValue[field.fieldId] = field.entityValue
        fields.metaValue[field.fieldId] = field.metaValue === undefined ? null : field.metaValue
      })
      fields.documentType = nextProps.docResult.documentTypeId
      fields.comment = nextProps.docResult.comment
      this.props.initialize(fields)
    }

    if (nextProps.docResult.sheets.length !== this.props.docResult.sheets.length) {
      this.setState({ showSheets: nextProps.docResult.sheets.length > 0 })
    }
    if (nextProps.docResult.documentId !== this.props.docResult.documentId || !nextProps.docResult.documentId) {
      this.setState({ showFileUploader: !nextProps.docResult.documentId, showDocumentCarrusel: false })
    }

    if ((!this.props.showDocumentEditionModal && nextProps.showDocumentEditionModal) ||
        nextProps.docResult.fields !== this.props.docResult.fields ||
        this.props.activeSheet !== nextProps.activeSheet) {
      this.setState({
        showPageCarrusel: nextProps.docResult.numPages && (nextProps.activeSheet === 0 ? nextProps.docResult.numPages : (nextProps.docResult.sheets &&
         nextProps.activeSheet && nextProps.docResult.sheets.find((e) => e.id === nextProps.activeSheet) && nextProps.docResult.sheets.find((e) => e.id === nextProps.activeSheet).numPages) > 1)
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.showDocumentEditionModal && this.props.docResult) {
      if (this.state.documentTitleCharWidth === null && this.state.documentTitle === averageCharacter) {
        this.setState({
          documentTitle: averageCharacter.repeat(99),
          documentTitleCharWidth: this.documentTitleElementRef.offsetWidth
        })
      } else if (this.state.documentTitleCharLimit === null && this.documentTitleElementRef.current !== null && this.documentTitleElementRef.current.textContent === averageCharacter.repeat(99)) {
        this.setState({
          documentTitleCharLimit: Math.floor(this.documentTitleElementRef.offsetWidth / this.state.documentTitleCharWidth * 2)
        })
      } else if ((this.state.documentTitleCharLimit !== null && this.state.documentTitle === averageCharacter.repeat(99)) || this.props.documentTypeUseId !== prevProps.documentTypeUseId) {
        let documentTitle = this.getDocumentTitle(this.props)
        if (documentTitle.length > this.state.documentTitleCharLimit) {
          documentTitle = documentTitle.substring(0, this.state.documentTitleCharLimit) + '...'
        }
        this.setState({ documentTitle, showTitle: true })
      }
    }
    const dropzoneImg = document.querySelector('.dropzone-img-wrapper > div > img')
    if (this.state.zoomProps.img && this.props.windowWidth !== 0 && this.props.windowHeight !== 0 &&
      (
        (!this.state.hasDropzoneImg && dropzoneImg) ||
        this.state.zoomProps.img !== prevState.zoomProps.img ||
        this.state.showPageCarrusel !== prevState.showPageCarrusel ||
        this.props.windowWidth !== prevProps.windowWidth || this.props.windowHeight !== prevProps.windowHeight
      )
    ) {
      if (dropzoneImg) {
        if (this.state.zoomProps.img === prevState.zoomProps.img && dropzoneImg.naturalWidth > 0) {
          this.updateImgSize(dropzoneImg)
        } else {
          if (dropzoneImg.naturalWidth > 0) {
            dropzoneImg.src = ''
            dropzoneImg.src = this.state.zoomProps.img
          }
          dropzoneImg.addEventListener('load', (e) => {
            this.updateImgSize(e.currentTarget)
          })
        }
      }
      if (this.state.hasDropzoneImg !== !!dropzoneImg) this.setState({ hasDropzoneImg: !!dropzoneImg })
    }

    if (this.props.pristine) {
      this.isFunctionToBeExecutedInFrontActive() && this.props.docResult.fields.map(field => this.executeFieldFunction(field, this.props.docResult.fields, field.entityValue))
    }
  }

  toggleDocumentCarrusel () {
    this.setState({
      showDocumentCarrusel: !this.state.showDocumentCarrusel
    })
  }

  togglePageCarrusel () {
    this.setState({
      showPageCarrusel: !this.state.showPageCarrusel
    })
  }

  toggleCloud () {
    this.setState({
      showDocumentCarrusel: true
    })
  }

  setActivePage (sheetId, pageId) {
    if (this.props.activeSheet !== sheetId) {
      this.setState({ autoCloseDocumentCarrusel: true })
    } else if (this.props.activePage !== pageId) {
      this.setState({ autoClosePageCarrusel: true })
    }
    this.props.actions.setActivePage(sheetId, pageId)
  }

  fileCarruselMouseLeave () {
    if (this.state.autoCloseDocumentCarrusel) {
      this.setState({ autoCloseDocumentCarrusel: false })
      this.toggleDocumentCarrusel()
    }
  }

  pageCarruselMouseLeave () {
    if (this.state.autoClosePageCarrusel) {
      this.setState({ autoClosePageCarrusel: false })
      this.togglePageCarrusel()
    }
  }

  scrollToFile (side = 1) {
    const initialScrollPosition = this.refs.horizontalScrollWrapper.scrollTop
    const thumbnailWidth = 215
    let allowedMovement = (this.state.isDocumentCarruselEndReached || thumbnailWidth)
    if (allowedMovement < thumbnailWidth / 2) allowedMovement += thumbnailWidth
    const totalTimePerStep = 100
    const distancePerInterval = Math.round(allowedMovement / totalTimePerStep) * side
    let currentDistance = 0
    let lastScroll

    const interval = window.setInterval(() => {
      lastScroll = this.refs.horizontalScrollWrapper.scrollTop
      currentDistance += distancePerInterval
      this.refs.horizontalScrollWrapper.scrollTop += distancePerInterval
      if ((currentDistance * side) >= allowedMovement || lastScroll === this.refs.horizontalScrollWrapper.scrollTop) {
        if (this.state.isDocumentCarruselEndReached) {
          this.setState({ isDocumentCarruselEndReached: false })
        } else if (Math.abs(initialScrollPosition - this.refs.horizontalScrollWrapper.scrollTop) < allowedMovement) {
          this.setState({ isDocumentCarruselEndReached: Math.abs(initialScrollPosition - this.refs.horizontalScrollWrapper.scrollTop) })
        }
        window.clearInterval(interval)
      }
    }, totalTimePerStep / allowedMovement)
  }

  cancelDocument (dossierType, documentId, documentParentTypeId, dossierId, isRecurrent, fleetsVisor) {
    new Promise((resolve, reject) => {
      var cancelDocumetResult = this.props.actions.cancelDocument(dossierType, documentId, documentParentTypeId, dossierId, resolve, reject, isRecurrent, fleetsVisor)
      this.props.trackAction(this.factorycancelDocumentAction(documentId))
      return cancelDocumetResult
    }).then(() => {
      this.props.actions.closeAddOrEditDocumentModalSuccess()
      this.setState({
        documentTitle: averageCharacter,
        documentTitleCharWidth: null,
        documentTitleCharLimit: null,
        showTitle: false,
        files: [],
        selectedDocType: null,
        hasNewDocument: false,
        showFileUploader: false,
        showSheets: false,
        showMissingDocumentTypeError: false,
        hasInvalidFile: false,
        zoomProps: { ...this.state.zoomProps, width: 0, height: 0, zoomWidth: 0, img: '' },
        hasDropzoneImg: false,
        showDocumentCarrusel: false,
        isDocumentCarruselEndReached: false,
        showPageCarrusel: true,
        dropzoneActive: false,
        dropzonePanelActive: false,
        carouselPaginationIdx: 1,
        canSave: false
      })
      this.props.initialize({})
    })
  }

  sendDMS () {
    this.props.actions.sendDMS(this.props.docResult?.chargeId)
  }

  sealDocument (documentId, dossierId) {
    this.props.actions.sealStampDocument(documentId, dossierId)
  }

  updateImgSize (dropzoneImg) {
    const allowWidth = this.props.windowWidth / 2 - (this.state.showPageCarrusel ? 120 : 60) - 20
    const allowHeight = this.props.windowHeight - 60 - 20
    let percentToReduce = 1
    if (dropzoneImg.naturalWidth > allowWidth) {
      percentToReduce = Math.min(percentToReduce, (allowWidth / dropzoneImg.naturalWidth))
    }
    if (dropzoneImg.naturalHeight > allowHeight) {
      percentToReduce = Math.min(percentToReduce, (allowHeight / dropzoneImg.naturalHeight))
    }
    if (percentToReduce < 1) {
      this.setState({
        zoomProps: {
          ...this.state.zoomProps,
          width: parseInt(dropzoneImg.naturalWidth * percentToReduce),
          height: dropzoneImg.naturalHeight * percentToReduce,
          zoomWidth: dropzoneImg.naturalWidth * percentToReduce
        }
      })
    } else {
      this.setState({
        zoomProps: {
          ...this.state.zoomProps,
          width: parseInt(dropzoneImg.naturalWidth),
          height: dropzoneImg.naturalHeight,
          zoomWidth: dropzoneImg.naturalWidth
        }
      })
    }
  }

  nextCarouselPage (val) {
    let carouselPaginationIdx = 0
    if (val === -1) {
      carouselPaginationIdx = this.state.carouselPaginationIdx - 1 === 0 ? 1 : this.state.carouselPaginationIdx - 1
    } else {
      carouselPaginationIdx = this.state.carouselPaginationIdx ? this.state.carouselPaginationIdx + 1 : 2
    }

    let activeSheetIdx = 0
    if (this.props.docResult && this.props.docResult.sheets && this.props.activeSheet) {
      activeSheetIdx = this.props.docResult.sheets.findIndex((sheet, idx) => {
        return this.props.activeSheet === sheet.id
      })
    }

    const initItem = carouselPaginationIdx > 1 ? (carouselPaginationIdx - 1) * this.state.carouselItemsPerPage + 1 : 1

    this.setActivePage(activeSheetIdx, initItem)
    this.setState({ carouselPaginationIdx: carouselPaginationIdx })
  }

  selectDocument (sheetId, pageId) {
    this.setActivePage(sheetId, pageId)
    this.setState({ carouselPaginationIdx: 1 })
  }

  getIconByStatus (signStatusId) {
    switch (parseInt(signStatusId)) {
      case 0:
        return 'ico-pending'
      case 1:
        return 'ico-ok'
      case 2:
        return 'ico-document-wrong'
      case 3:
        return 'ico-wrong'
      default:
        return 'ico-document'
    }
  }

  getSignStatusText (signStatusId) {
    switch (parseInt(signStatusId)) {
      case 0:
        return 'SIGN_PENDING'
      case 1:
        return 'SIGN_SIGNED'
      case 2:
        return 'SIGN_CANCELLED'
      case 3:
        return 'SIGN_REJECTED'
      case 5:
        return 'SIGN_EXPIRED'
      case -1:
        return 'SIGN_AVAILABLE'
      default:
        return 'SIGN_DEFAULT'
    }
  }

  signDocument (values, fleetsVisor) {
    const documentId = this.props.docResult.documentId
    const callback = this.props.callback
    const refreshAfterChange = this.props.refreshAfterChange

    new Promise((resolve, reject) => {
      return this.props.actions.signTypes(resolve, reject, documentId, null, null, this.props.pristine)
    }).then((result) => {
      if (result && result.length > 1) {
        this.setState({ signTypesCombo: result, showSignTypesModal: true })
      } else {
        if (this.props.fleetMode === fleetMode.REQUEST_SIGN) {
          if (result && result.length === 1) {
            this.props.actions.createRequestSignDocumentFleetProcess(this.props.fleetDocumentDetails, this.props.fleetId, result[0].id, () => this.props.actions.closeAddOrEditDocumentModalSuccess())
          } else {
            this.props.actions.createRequestSignDocumentFleetProcess(this.props.fleetDocumentDetails, this.props.fleetId, null, () => this.props.actions.closeAddOrEditDocumentModalSuccess())
          }
        } else {
          if (this.props.docResult.data.entity.entityTypeId === undefined) {
            this.props.actions.openErrorMessageModal('ERRORS.BACKEND_TAG_TRANSLATION.SIGNATURE_CLIENT_NOT_FOUND')
          } else {
            this.changeAll(values)
            this.submitDocument(values, fleetsVisor)
            if (result && result.length === 1) {
              this.props.actions.requestSign(documentId, null, callback, refreshAfterChange, result[0].id)
            } else {
              this.props.actions.requestSign(documentId, null, callback, refreshAfterChange)
            }
          }
        }
      }
    }).catch(error => console.log(error))
  }

  executeFieldFunction (field, fields, value) {
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.'
    switch (field.functionToExecuteInFront) {
      case 'chargeAmountToWords':
        var targetFieldCode = this.getCodeTargetFieldAmountToWords(field.code)
        var targetField = fields.find(x => x.code === targetFieldCode)
        if (value || value === '0') {
          if (!isNaN(value)) {
            var integerValue = Math.floor(Math.abs(value))
            var wordsFromIntegerNumber = numberToWords(integerValue, localStorage.localeName.split('-')[0])
            var currencyText = this.getAmountToWordsCurrencyText(localStorage.locale, wordsFromIntegerNumber, value, numberToWordsI18n(parseInt(localStorage.locale)), settings.currency)

            var decimalWords = ''
            var decimalValue = (Math.abs(value) % 1).toFixed(4).substring(2, 4)
            if (decimalValue && decimalValue > 0) {
              var wordsFromDecimalNumber = numberToWords(decimalValue, localStorage.localeName.split('-')[0])
              var centText = parseInt(decimalValue) === 1 ? this.props.t(`${tKey}CENT`) : this.props.t(`${tKey}CENTS`)
              decimalWords = `${wordsFromDecimalNumber} ` + centText
            }
            this.props.change(`entityValue.${targetField.fieldId}`, `${capitalizeFirstLetter(wordsFromIntegerNumber)} ${currencyText} ${decimalWords}`)
          }
        } else {
          this.props.change(`entityValue.${targetField.fieldId}`, '')
        }
        break

      default:
        break
    }
  }

  getAmountToWordsCurrencyText (languageId, wordsFromNumber, fieldValue, i18nObject, currency = currencies.EUR) {
    if (parseInt(fieldValue) === 1) return this.getBasicCurrencyText(languageId, amountToWordsCurrencyTextType.SINGLE, currency)
    var matchedSpecialCasesForCurrencyText = []
    if (i18nObject) {
      var unitsWithPluralWord = i18nObject.units.filter(x => !!x.plural)
      var wordsFromNumberWords = wordsFromNumber.split(' ')
      var wordsFromNumberLastWord = wordsFromNumberWords[wordsFromNumberWords.length - 1]
      matchedSpecialCasesForCurrencyText = unitsWithPluralWord.filter(x => x.plural === wordsFromNumberLastWord || x.singular === wordsFromNumberLastWord)
    }

    let currencyText = ''
    switch (parseInt(languageId)) {
      case languages.SPANISH:
      case languages.CATALAN:
      case languages.SPANISH_MX:
        var currencyTextType = matchedSpecialCasesForCurrencyText.length > 0 ? amountToWordsCurrencyTextType.SPECIAL_CASE : amountToWordsCurrencyTextType.PLURAL
        currencyText = this.getBasicCurrencyText(languageId, currencyTextType, currency)
        break
      case languages.ENGLISH:
      case languages.PORTUGUESE:
        currencyText = this.getBasicCurrencyText(languageId, amountToWordsCurrencyTextType.PLURAL, currency)
        break
    }

    return currencyText
  }

  getBasicCurrencyText (languageId, typeCurrencyText, currency) {
    var basicCurrencyText = ''
    switch (typeCurrencyText) {
      case amountToWordsCurrencyTextType.SINGLE:
        basicCurrencyText = settings.currencyTextConfig[parseInt(languageId)].single
        break

      case amountToWordsCurrencyTextType.PLURAL:
        basicCurrencyText = settings.currencyTextConfig[parseInt(languageId)].plural
        break

      case amountToWordsCurrencyTextType.SPECIAL_CASE:
        basicCurrencyText = settings.currencyTextConfig[parseInt(languageId)].special
        break
      default:
        break
    }

    return basicCurrencyText
  }

  getCodeTargetFieldAmountToWords (sourceFieldCode) {
    var targetField = ''
    switch (sourceFieldCode) {
      case codeMetadataFields.ABONO_IMPORTE:
        targetField = codeMetadataFields.ABONO_IMPORTE_TEXTO
        break

      case codeMetadataFields.COBRO_IMPORTE:
        targetField = codeMetadataFields.COBRO_IMPORTE_TEXTO
        break

      case codeMetadataFields.PAGA_SEÑAL_IMPORTE:
        targetField = codeMetadataFields.PAGA_SEÑAL_IMPORTE_TEXTO
        break
      default:
        break
    }

    return targetField
  }

  customValidate (value, allValues, props, field) {
    const id = parseInt(field.split('entityValue.')[1])
    const fieldFound = props.docResult.fields !== null ? props.docResult.fields.find(f => f.fieldId === id) : null
    if (fieldFound && fieldFound.customValidations) {
      const result = customValidations(fieldFound.customValidations, value, props)
      return result === Object(result) ? undefined : result
    }
  }

  isFunctionToBeExecutedInFrontActive () {
    return this.props.docResult.isActiveAutoChargeAmountToWords
  }

  validateDate (value, allValues, props, field) {
    const id = parseInt(field.split('entityValue.')[1])
    const fieldFound = props.docResult.fields !== null ? props.docResult.fields.find(f => f.fieldId === id) : null
    let result = validateDate(value, props)
    if (result === Object(result) && fieldFound && fieldFound.customValidations) {
      result = customValidations(fieldFound.customValidations, value, props)
    }
    return result === Object(result) ? undefined : result
  }

  closeModalAction () {
    this.setState({ showSignTypesModal: false })
  }

  openCancelSignModal () {
    new Promise((resolve, reject) => {
      return this.props.actions.getCancelDocListAction(this.props.docResult.documentId, null, resolve, reject)
    }).then((result) => {
      this.setState({ showCancelSignModal: true, cancelDocList: result })
    }).catch(error => console.log(error))
  }

  closeModalFleet () {
    new Promise((resolve, reject) => {
      return this.props.actions.checkCanCloseDocumentModal(true, resolve)
    }).then((result) => {
      if (result) {
        this.directCloseModal()
      }
    })
  }

  recoverDocumentFleet () {
    this.props.actions.createReEditDocumentFleetProcess(this.props.fleetDocumentDetails, this.props.fleetId, () => this.props.actions.closeAddOrEditDocumentModalSuccess())
  }

  recoverDocumentFleetCharge () {
    this.props.actions.createRecoverDocumentFleetChargeProcess(this.props.fleetId, this.props.fleetChargeId, () => this.props.actions.closeAddOrEditDocumentModalSuccess())
  }

  cancelDocumentFleet () {
    this.props.actions.createCancelDocumentFleetProcess(this.props.fleetDocumentDetails, this.props.fleetId, () => this.props.actions.closeAddOrEditDocumentModalSuccess())
  }

  cancelSignDocument () {
    this.props.actions.createCancelSignDocumentFleetProcess(this.props.fleetDocumentDetails, this.props.fleetId, this.props.t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.CANCEL_SIGN_REASON'), () => this.props.actions.closeAddOrEditDocumentModalSuccess())
  }

  rejectDocumentFleet () {
    this.props.actions.createRejectDocumentFleetProcess(this.props.fleetDocumentDetails, this.props.fleetId, this.props.t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.REJECT_REASON'), () => this.props.actions.closeAddOrEditDocumentModalSuccess())
  }

  directCloseModal () {
    const hasNewDoc = this.state.hasNewDocument
    this.setState({
      documentTitle: averageCharacter,
      documentTitleCharWidth: null,
      documentTitleCharLimit: null,
      showTitle: false,
      files: [],
      selectedDocType: null,
      hasNewDocument: false,
      showFileUploader: false,
      showSheets: false,
      showMissingDocumentTypeError: false,
      hasInvalidFile: false,
      zoomProps: { ...this.state.zoomProps, width: 0, height: 0, zoomWidth: 0, img: '' },
      hasDropzoneImg: false,
      showDocumentCarrusel: false,
      isDocumentCarruselEndReached: false,
      showPageCarrusel: true,
      dropzoneActive: false,
      dropzonePanelActive: false,
      carouselPaginationIdx: 1,
      canSave: false
    })
    this.props.initialize({})
    this.props.actions.closeAddOrEditDocumentModal(hasNewDoc || !this.props.pristine)
  }

  viewFieldsFleet () {
    return this.props.fleetMode !== fleetMode.CANCEL && this.props.fleetMode !== fleetMode.REQUEST_SIGN && this.props.fleetMode !== fleetMode.CANCEL_SIGN && this.props.fleetMode !== fleetMode.REJECT
  }

  getSealStatus (sealStatusId) {
    switch (sealStatusId) {
      case null:
      case undefined:
      case sealStatus.PENDING_SEND:
        return 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.PENDING_SEND'
      case sealStatus.PENDING_SEAL:
        return 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.PENDING_SEAL'
      case sealStatus.SEALED:
        return 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.SEALED'
    }
  }

  printTemplate (templateFormCodeCalled = false, formValues = null) {
    if (this.props.templateFormCode && !templateFormCodeCalled) {
      this.templateDataForm(true)
    } else if (this.props.checklistId) {
      this.props.actions.printCheckList(this.props.checklistId, formValues)
    } else {
      this.props.actions.printTemplateByReferenceType(formValues)
    }
  }

  templateDataForm (printTemplate) {
    switch (this.props.templateFormCode) {
      case templateDataFormCode.ReservationContract:
        this.setState({ printTemplate: printTemplate })
        this.props.actions.fetchInsuranceCompanyCombo()
        this.props.actions.openReservationContractModal(this.props.dossierId)
        break
      case templateDataFormCode.InternalManagementDoc:
        this.setState({ printTemplate: printTemplate })
        this.props.actions.openInternalManagementDocModal(this.props.dossierId, this.props.checklistId, printTemplate, this.props.organizedUnitId)
        break
      case templateDataFormCode.ResponsibleDeclaration:
        this.setState({ printTemplate: printTemplate })
        this.props.actions.openResponsibleDeclarationDocModal(this.props.dossierId, this.props.checklistId, printTemplate, this.props.organizedUnitId)
        break
      default:
        if (printTemplate) {
          this.printTemplate(true)
        } else {
          this.handleGenerateAndUploadClick(true)
        }
        break
    }
  }

  handleSubmitReservationContractModal (formValues, printCheckList = false, checklistId = null) {
    if (printCheckList) {
      this.props.actions.printCheckList(checklistId, formValues)
    } else if (this.state.printTemplate) {
      this.printTemplate(true, formValues)
    } else {
      this.handleGenerateAndUploadClick(true, formValues)
    }
  }

  render () {
    let {
      t, showDocumentEditionModal, handleSubmit, documentTypeUseName, token, fleetsVisor, editable,
      documentTypeUseId, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, callback,
      refreshAfterChange, dossierType, dossierSubType, checkListIdReciboPagaYSenal, activeSheet, activePage,
      isRecurrent, readOnlyFields, metaLoading, referenceType, referenceId,
      docResult: {
        documentId, typeView, documentStatusId, thumbnail,
        fields = [], comment, incorporationDate, sheets, numPages = 1, data, typeObject, signStatusId, signDate,
        solpheoDocumentId, storageDocumentId, sealStatusId
      },
      actions: {
        openDocumentEditionModal,
        recoverDocument,
        cancelSign,
        requestSign,
        cancelSignMessage,
        createRequestSignDocumentFleetProcess,
        closeAddOrEditDocumentModalSuccess
      },
      combos: { documentsTypes },
      multipleSignCancel
    } = this.props
    let buttonUpload, buttonAddNewVersion, buttonNewSheet, buttonRecover, buttonDMS, buttonSave, buttonValidate, iconReload, validatedText, buttonReject, buttonCancel, buttonDownload, editRecurrentEntityDocuments, btnRequestSign, btnCancelSign, commentDocument, buttonDelete, buttonSeal
    if (this.props.docResult && this.props.docResult.buttonsPermission) {
      buttonUpload = this.props.docResult.buttonsPermission.buttonUpload && editable
      buttonAddNewVersion = this.props.docResult.buttonsPermission.buttonAddNewVersion && editable
      buttonNewSheet = this.props.docResult.buttonsPermission.buttonNewSheet && editable
      buttonRecover = this.props.docResult.buttonsPermission.buttonRecover && editable
      buttonDMS = this.props.docResult.buttonsPermission.buttonDMS && editable
      buttonSave = this.props.docResult.buttonsPermission.buttonSave && editable
      buttonValidate = this.props.docResult.buttonsPermission.buttonValidate && editable
      iconReload = this.props.docResult.buttonsPermission.iconReload && editable
      validatedText = this.props.docResult.buttonsPermission.validatedText && editable
      buttonReject = this.props.docResult.buttonsPermission.buttonReject && editable
      buttonCancel = this.props.docResult.buttonsPermission.buttonCancel && editable
      buttonDownload = this.props.docResult.buttonsPermission.buttonDownload
      editRecurrentEntityDocuments = this.props.docResult.buttonsPermission.editRecurrentEntityDocuments && editable
      btnRequestSign = this.props.docResult.buttonsPermission.buttonRequestSign && editable
      btnCancelSign = this.props.docResult.buttonsPermission.buttonCancelSign && editable
      commentDocument = this.props.docResult.buttonsPermission.commentDocument && editable
      buttonDelete = this.props.docResult.buttonsPermission.buttonDelete && editable
      buttonSeal = this.props.docResult.buttonsPermission.buttonSeal && editable
    } else {
      buttonUpload = buttonAddNewVersion = buttonNewSheet = buttonRecover = buttonDMS = buttonSave = buttonValidate = iconReload = validatedText = buttonReject = buttonCancel = buttonDownload = commentDocument = false
    }
    signStatusId = String(signStatusId)
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.'
    const signStatusTooltip = (<Tooltip id="signStatusTooltip">{signStatusId && signStatusId !== 'undefined' && t(`${tKey}${translateSignatureStatus[signStatusId]}`)}</Tooltip>)
    const statusTooltip = (<Tooltip id="statusTooltip">{documentStatusId && t(`DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.${translateDocumentStatus[documentStatusId]}`)}</Tooltip>)
    const documentTitleTooltip = (<Tooltip id="documentTitleTooltip">{this.getDocumentTitle(this.props)}</Tooltip>)
    const viewSheetTooltip = (<Tooltip id="viewTooltip">{t(`${tKey}VIEW_SHEET_BTN`)}</Tooltip>)
    const downloadTooltip = (<Tooltip id="downloadTooltip">{t(`${tKey}DOWNLOAD_BTN`)}</Tooltip>)
    const deleteSheetTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_SHEET_BTN`)}</Tooltip>)
    const uploadNewSheetTooltip = (<Tooltip id="uploadNewSheetTooltip">{t(`${tKey}UPLOAD_NEW_FILE`)}</Tooltip>)
    const uploadNewVersionTooltip = (<Tooltip id="uploadNewVersionTooltip">{t(`${tKey}UPLOAD_NEW_VERSION`)}</Tooltip>)
    const copyValuesTooltip = (<Tooltip id="copyValuesTooltip">{t(`${tKey}COPY_VALUES`)}</Tooltip>)
    const desplegarTooltip = (<Tooltip id="desplegarTooltip">{t(`${tKey}VIEW_MORE_FILES`)}</Tooltip>)
    const scrollToPreviusFileTooltip = (<Tooltip id="scrollToPreviusFileTooltip">{t(`${tKey}PREVIUS_FILE`)}</Tooltip>)
    const scrollToNextFileTooltip = (<Tooltip id="scrollToNextFileTooltip">{t(`${tKey}NEXT_FILE`)}</Tooltip>)
    const refreshTooltip = (<Tooltip id="refreshTooltip">{t(`${tKey}REFRESH_BTN`)}</Tooltip>)
    const acceptFilesDropzone = getAccepFiles()
    const maxSizeFilesDropzone = 10240000
    let fecha
    let numeroHoja
    const anchoPantalla = (window.screen.width / 2) - (((documentId && documentStatusId !== documentStatusConstant.VALIDATED) || buttonNewSheet) ? 245 : 0)
    let permissionToEdit = data && data.entity && (data.entity.entityTypeId === entityTypes.recurrentEntity && typeObject === 1 ? editRecurrentEntityDocuments : true)
    permissionToEdit = permissionToEdit === undefined || permissionToEdit === null ? true : permissionToEdit
    const editTooltip = (<Tooltip id="editTooltip">{t('DOSSIER_COMPONENTS.VEHICLE.TOOLTIP_VALIDATE_LICENSE_PLATE')}</Tooltip>)
    documentParentTypeId = this.props.docResult.typeObject ? this.props.docResult.typeObject : documentParentTypeId
    sheets.map((sheet, idx) => {
      // eslint-disable-next-line no-unused-expressions
      sheet.id === activeSheet ? (
        fecha = sheet.creationDate,
        numeroHoja = idx + 1
      ) : null
    })
    const showGenerateButtons = this.props.showGenerateButtons && (this.props.checklistId != null || (referenceType && referenceId) || this.props.templateFormCode)
    const { onlyTemplateUpload } = this.props
    return (
      <>
        <ReservationContractModalPage
          onClose={(values, printCheckList, checklistId) => this.handleSubmitReservationContractModal(values, printCheckList, checklistId)}
        />
        <ResponsibleDeclarationDocModalPage/>
        <InternalManagementDocModalPage/>
        <Modal show={showDocumentEditionModal}
          className={'document-edition-modal' +
        (showGenerateButtons && !this.props.docResult.documentId ? ' no-document-modal' : '')}
          onHide={() => this.closeModal()}>
          <Col sm={6} className="document-edition-modal-visor">
            {
              ((!this.state.showFileUploader) && (solpheoDocumentId || storageDocumentId)) && (
                <Col sm={1} className={'document-edition-modal-left-side barra-detalle-documento ' + ((this.state.showPageCarrusel && settings.clientFieldValidation !== 'toyo') ? 'toggleContent-open ' : 'toggleContent-closed ') + (buttonDownload ? 'with-download' : '')} onMouseLeave={() => this.pageCarruselMouseLeave()}>
                  <div className="btn-wrapper">
                    {settings.clientFieldValidation !== 'toyo' && <OverlayTrigger placement="right" overlay={desplegarTooltip}>
                      <Button onClick={() => this.togglePageCarrusel()} id="btn_view_sheets_more">
                        <Glyphicon glyph={this.state.showPageCarrusel ? 'chevron-left' : 'chevron-right'}/>
                      </Button>
                    </OverlayTrigger>}
                    <OverlayTrigger placement="right" overlay={viewSheetTooltip} >
                      <Button className="button-search search-img-grande" id="btn_view_sheet" onClick={(e) => activeSheet === 0 ? (this.toggleFullScreenDocumentModal(e)) : (this.toggleFullScreenDocumentSheetModal(e, activeSheet))}>
                        <i className="ico-magnifying"/>
                      </Button>
                    </OverlayTrigger>
                    {buttonDownload &&
                  (<OverlayTrigger placement="right" overlay={downloadTooltip}>
                    <Button className="button-search search-img-grande" id="btn_view_download" onClick={(e) => activeSheet === 0 ? (this.downloadDocument(e)) : (this.downloadDocumentSheet(e, activeSheet))}>
                      <Glyphicon glyph={'cloud-download'} />
                    </Button>
                  </OverlayTrigger>)}
                    {this.props.fleetMode !== fleetMode.INCORPORATE_CHARGE && <OverlayTrigger placement="right" overlay={refreshTooltip}>
                      <Button className="button-search search-img-grande button-refresh" id="btn_view_refresh" onClick={(e) => this.refreshDocument(e)}>
                        <Glyphicon glyph={'refresh'} />
                      </Button>
                    </OverlayTrigger>}
                  </div>
                  {
                    (!this.state.showFileUploader && documentId) && (
                      <div className={'carrusel-imagenes-modal ' + (buttonDownload ? 'with-download ' : '') + (numPages > this.state.carouselItemsPerPage ? '' : 'without-pagination ') + (this.state.showPageCarrusel ? '' : 'hide-page-carousel')} >
                        <div className="vertical-scroll-wrapper squares">
                          {activeSheet === 0 && numPages && (
                            (() => {
                              const pages = []
                              if (settings.clientFieldValidation !== 'toyo') {
                                const initItem = this.state.carouselPaginationIdx > 1 ? (this.state.carouselPaginationIdx - 1) * this.state.carouselItemsPerPage + 1 : 1
                                let lastItem = this.state.carouselPaginationIdx ? (initItem > 1 ? initItem + this.state.carouselItemsPerPage - 1 : this.state.carouselItemsPerPage) : numPages
                                if (lastItem > numPages) {
                                  lastItem = numPages
                                }
                                for (let i = initItem; i <= lastItem; i++) {
                                  pages.push(
                                    <div key={'0.' + i} className="img-carrusel" onClick={() => this.setActivePage(0, i)}>
                                      <ImgPage
                                        src={`${settings.url}document/${documentId}/preview?token=${token}&pageId=${i}&fecha=${this.state.parametroFecha}&organizedUnitId=${organizedUnitId}`}
                                        active={(this.props.activeSheet === 0 && this.props.activePage === i) ? 'img-active' : ''}
                                      />
                                    </div>
                                  )
                                }
                              }
                              return pages
                            })()
                          )}
                          {activeSheet !== 0 && (sheets.length > 0 || this.state.showSheets) && (
                            (() => {
                              const activeSheetIdx = sheets.findIndex((sheet, idx) => {
                                return activeSheet === sheet.id
                              })
                              const pages = []
                              if (settings.clientFieldValidation !== 'toyo') {
                                if (activeSheetIdx !== -1) {
                                  const activeSheet = sheets[activeSheetIdx]
                                  const initItem = this.state.carouselPaginationIdx > 1 ? (this.state.carouselPaginationIdx - 1) * this.state.carouselItemsPerPage + 1 : 1
                                  let lastItem = this.state.carouselPaginationIdx ? (initItem > 1 ? initItem + this.state.carouselItemsPerPage - 1 : this.state.carouselItemsPerPage) : activeSheet.numPages

                                  if (lastItem > activeSheet.numPages) {
                                    lastItem = activeSheet.numPages
                                  }
                                  for (let i = initItem; i <= lastItem; i++) {
                                    pages.push(
                                      <div key={activeSheet.id + '.' + i} className="img-carrusel" onClick={() => this.setActivePage(activeSheet.id, i)}>
                                        <ImgPage
                                          src={`${settings.url}document/${documentId}/sheet/${activeSheet.id}/preview?token=${token}&pageId=${i}&fecha=${this.state.parametroFecha}&organizedUnitId=${organizedUnitId}`}
                                          active={(this.props.activeSheet === activeSheet.id && this.props.activePage === i) ? 'img-active' : ''}
                                        />
                                      </div>
                                    )
                                  }
                                }
                              }
                              return pages
                            })()
                          )}
                        </div>
                      </div>
                    )
                  }
                  <div className="barra-detalle-page-info"><span className="numero-hoja">{t(`${tKey}SHEETS_TABLE.PAGE`) + ' ' + (activePage) + ' ' + t(`${tKey}SHEETS_TABLE.DE`) + ' ' + (activeSheet === 0 ? numPages : (sheets && sheets.find((e) => e.id === activeSheet)) ? sheets.find((e) => e.id === activeSheet).numPages : numPages)}</span></div>
                  {numPages > this.state.carouselItemsPerPage && <div>
                    <Button
                      onClick={() => this.nextCarouselPage(-1)}
                      disabled={this.state.carouselPaginationIdx === 1}>
                      {'<'}
                    </Button>
                    <Button
                      onClick={() => this.nextCarouselPage(1)}
                      disabled={this.state.carouselPaginationIdx * this.state.carouselItemsPerPage + 1 > numPages}>
                      {'>'}
                    </Button>
                  </div>}
                </Col>
              )
            }
            <Col sm={4} className={'document-edition-modal-middle-side ' + ((this.state.canSave || !this.state.showFileUploader) ? 'false' : 'full-height')}>
              <div className={'dropzone-wrapper-modal' + (this.state.hasInvalidFile || this.state.showMissingDocumentTypeError ? ' has-error' : '')} style={{ height: '100%' }}>
                {
                  iconReload
                    ? (
                      <div onClick={() => openDocumentEditionModal(documentId, documentTypeUseId, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, null, callback, refreshAfterChange, dossierType, null, null, null, null, false, editable)} className="text-wrapper">
                        <img src="images/check_list.gif" alt=""/>
                        <h2>{t(`${tKey}LOADING_MESSAGE`)}</h2>
                        <p>{t(`${tKey}TRY_FETCH_DOCUMENT`)}</p>
                      </div>
                    ) : (
                      ((!this.state.showFileUploader) && (solpheoDocumentId || storageDocumentId)) ? (
                        <div id="dropZone" className="dropzone-thumbnail" style={{ height: '100%' }}>
                          {this.props.isLoading ? (
                            <div style={{ height: '100%', border: '1px solid #b1b1b1', paddingTop: '30%' }}>
                              <SmallLoaderPage
                                background="#ffffff"
                              />
                            </div>
                          ) : (
                            <div className="dropzone-img-wrapper" style={{ width: this.state.zoomProps.width + 'px', height: this.state.zoomProps.height + 'px' }}>
                              {
                                this.state.zoomProps.img !== '' && (
                                  <ReactImageZoom {...this.state.zoomProps} />
                                )
                              }
                            </div>
                          )}
                        </div>
                      ) : !readOnlyFields && buttonUpload && (
                        onlyTemplateUpload
                          ? <div className="text-wrapper modal-subir-fichero-inicio"
                            onClick={() => this.handleGenerateAndUploadClick()}>
                            <div>
                              <div>
                                <FontAwesomeIcon className="ico-document-upload" icon={faFileArrowUp} />
                                <p className='generate-upload-subtitle'>{t(`${tKey}CLICK_HERE_FOR`)}</p>
                                <h2 className='generate-upload-title'>{t(`${tKey}GENERATE_UPLOAD`)}</h2>
                              </div>
                            </div>
                          </div>
                          : <DropZone
                            id="dropZone"
                            multiple={false}
                            onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles, organizedUnitId)}
                            disabled={(!this.state.selectedDocType && !documentTypeUseName) || !permissionToEdit}
                            accept={acceptFilesDropzone}
                            maxSize={maxSizeFilesDropzone}
                            style={{ height: '100%' }}
                            className={this.state.dropzoneActive ? 'active' : ''}
                            onDragEnter={() => this.onDragEnter(false)}
                            onDragLeave={() => this.onDragLeave(false)}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="text-wrapper modal-subir-fichero-inicio" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div onClick={() => { if (!this.state.selectedDocType && !documentTypeUseName) this.setState({ showMissingDocumentTypeError: true }) }}>
                                  <div>
                                    <i className={!documentTypeUseName ? 'ico-cloud cloud-apagado' : 'ico-cloud cloud-size'}/>
                                    {!this.state.showMissingDocumentTypeError ? [
                                      <h2 key="dropZone-title">{t(`${tKey}DROP_FILES`)}</h2>,
                                      <p key="dropZone-subtitle">{this.ReturnFormatDocument(acceptFilesDropzone, tKey, t)}</p>,
                                      this.state.hasInvalidFile && <div className="help-block text-center" key="dropZone-help-block">{t(`${tKey}VALIDATION.FILE`)}</div>
                                    ] : (
                                      <h2>{t(`${tKey}VALIDATION.DOCUMENT_TYPE_REQUIRED`)}</h2>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </DropZone>
                      )
                    )
                }
              </div>
            </Col>
            {(!readOnlyFields && !this.state.showFileUploader || this.state.canSave) && this.props.fleetMode === null && (
              <Col sm={12} className="document-edition-modal-lower-side row" >
                <div className="btn-wrapper">
                  {
                    (!fleetsVisor && (buttonValidate || buttonReject) && (solpheoDocumentId || storageDocumentId)) ? [
                      buttonValidate && permissionToEdit && (<Button
                        id="btn_validate"
                        key="btn_validate"
                        className="btn-validate-white"
                        onClick={handleSubmit(values => {
                          this.changeAll(values)
                          this.props.fleetChargeId && this.props.fleetChargeId > 0 ? this.validateFleetDocument({ ...values }) : this.submitDocument({ ...values, validateAction: true })
                        })}
                      ><i className="ico-check"/> {t(`${tKey}VALIDATE_BTN`)}</Button>),
                      buttonReject && permissionToEdit &&
                        (<Button
                          id="btn_reject"
                          key="btn_reject"
                          className="btn-reject-white"
                          onClick={() => this.setState({ showRejectCommentModal: true })}
                        ><i className="ico-cancel"/> {t(`${tKey}REJECT_BTN`)}</Button>
                        ),
                      (settings.showSignDocumentButton && dossierType === dossierTypesConstant.sales &&
                      dossierSubType === dossierSubTypeIdConstants.venta_vn && documentTypeUseId === 4) && // Recibo de paga y señal
                      (
                        <Button
                          id="btn_sign"
                          key="btn_sign"
                          className="btn-standard"
                          onClick={() => {
                            const signAppUrl = `http://signidoccar.premm.es/?entorno=${encodeURIComponent(settings.url)}&token=${token}&checkListId=${checkListIdReciboPagaYSenal}&organizedUnitId=${organizedUnitId}&documentTypeId=${documentTypeUseId}&id=${dossierId}&documentId=${documentId}&fecha=${this.state.parametroFecha}`
                            window.open(signAppUrl, '_blank')
                          }}
                        >{t(`${tKey}SIGN_BTN`)}</Button>
                      )
                    ] : null
                  }
                </div>
                <div className="btn-wrapper">
                  {
                    buttonSeal && permissionToEdit && (solpheoDocumentId || storageDocumentId) ? (
                      <Button
                        id="btn_seal"
                        className="btn-standard"
                        onClick={() => this.sealDocument(documentId, dossierId)}
                      >{t(`${tKey}SEAL`)}</Button>
                    ) : null
                  }
                  {
                    btnRequestSign ? (
                      <Button
                        id="btn_request_sign"
                        className="btn-standard"
                        onClick={handleSubmit(values => {
                        // if (error.dataFail.toLowerCase().includes('signature_client_not_found') || error.messageFail.toLowerCase().includes('signature_client_not_found')) {}
                          this.signDocument(values, fleetsVisor)
                          this.props.trackAction(this.factorySignRequestAction(documentId))
                        })}
                      >{t(`${tKey}REQUEST_SIGN`)}</Button>
                    ) : null
                  }
                  {
                    btnCancelSign ? (
                      <Button
                        id="btn_cancel_sign"
                        className="btn-reject-white"
                        onClick={() => {
                          this.openCancelSignModal()
                          this.props.trackAction(this.factorySignCancelAction(documentId))
                        }}
                      >{t(`${tKey}CANCEL_TO_SIGN`)}</Button>
                    ) : null
                  }
                  { (buttonSave && (!solpheoDocumentId || !storageDocumentId)) && permissionToEdit ? (
                    <Button
                      id="btn_save"
                      className="btn-standard"
                      onClick={handleSubmit(values => {
                        this.changeAll(values)
                        this.submitDocument(values, fleetsVisor)
                      })}
                      type="submit">
                      {t(`${tKey}SAVE_BTN`)}
                    </Button>
                  ) : null
                  }
                  {
                    buttonDMS && permissionToEdit ? (
                      <Button
                        id="btn_sendDMS"
                        className="first btn-white btn-icon"
                        onClick={() => this.sendDMS()}
                      >{t(`${tKey}SEND_DMS`)}</Button>
                    ) : null
                  }
                  {
                    buttonRecover && permissionToEdit ? (
                      <Button
                        id="btn_recover"
                        className="btn-standard"
                        onClick={() =>
                          this.props.fleetChargeId && this.props.fleetChargeId > 0
                            ? this.recoverDocumentFleetCharge()
                            : recoverDocument(documentId, documentTypeUseId, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, null, callback, refreshAfterChange, dossierType, dossierSubType, isRecurrent)}
                      >{t(`${tKey}RECOVER`)}</Button>
                    ) : null
                  }
                  {
                    buttonCancel && permissionToEdit && (solpheoDocumentId || storageDocumentId) ? (
                      <Button
                        id="btn_cancel"
                        className="btn-reject"
                        onClick={() =>
                          this.props.fleetChargeId && this.props.fleetChargeId > 0
                            ? this.cancelDocumentFleet()
                            : this.cancelDocument(dossierType, documentId, documentParentTypeId, dossierId, isRecurrent, fleetsVisor)}
                      >{t(`${tKey}CANCEL`)}</Button>
                    ) : null
                  }

                </div>
              </Col>)}
            {/* Fleet Mode Buttons */}
            {(!readOnlyFields && !this.state.showFileUploader || this.state.canSave) && this.props.fleetMode !== null && (
              <Col sm={12} className="document-edition-modal-lower-side row" >
                <div className="btn-wrapper">
                </div>
                <div className="btn-wrapper">

                  { (buttonSave && (!solpheoDocumentId || !storageDocumentId)) && permissionToEdit && this.props.fleetMode === fleetMode.INCORPORATE_CHARGE ? (
                    <Button
                      id="btn_save_fleet_document"
                      className="btn-standard"
                      onClick={handleSubmit(values => {
                        this.changeAll(values)
                        this.props.actions.setOperateDataChargeFleetProcess(this.props.docResult.documentId, this.props.organizedUnitId, this.props.dossieFleetIds,
                          this.props.docResult.fields.map(field => {
                            return {
                              ...field, entityValue: values.entityValue[field.fieldId]
                            }
                          }),
                          values.comment, this.props.fleetId, () => { this.props.actions.closeAddOrEditDocumentModalSuccess() })
                      })}
                      type="submit">
                      {t(`${tKey}SAVE_BTN`)}
                    </Button>
                  ) : null
                  }
                  {
                    buttonCancel && permissionToEdit && (solpheoDocumentId || storageDocumentId) && this.props.fleetMode === fleetMode.INCORPORATE_CHARGE ? (
                      <Button
                        id="btn_cancel"
                        className="btn-reject"
                        onClick={() => this.props.actions.closeAddOrEditDocumentModalSuccess()}
                      >{t(`${tKey}CANCEL`)}</Button>
                    ) : null
                  }
                  {(buttonSave && (!solpheoDocumentId || !storageDocumentId)) && permissionToEdit && this.props.fleetMode === fleetMode.INCORPORATE ? (
                    <Button
                      id="btn_save_fleet_document"
                      className="btn-standard"
                      onClick={handleSubmit(values => {
                        this.changeAll(values)
                        this.submitFleetDocument(values)
                      })}
                      type="submit">
                      {t(`${tKey}SAVE_BTN`)}
                    </Button>
                  ) : null
                  }
                  {
                    buttonCancel && permissionToEdit && (solpheoDocumentId || storageDocumentId) && this.props.fleetMode === fleetMode.INCORPORATE ? (
                      <Button
                        id="btn_cancel"
                        className="btn-reject"
                        onClick={() => this.closeModalFleet()}
                      >{t(`${tKey}CANCEL`)}</Button>
                    ) : null
                  }
                  { this.props.fleetMode === fleetMode.VALIDATE ? [
                    (<Button
                      id="btn_validate_fleet_document"
                      key="btn_validate"
                      className="btn-validate-white"
                      onClick={handleSubmit(values => {
                        this.changeAll(values)
                        this.validateFleetDocument({ ...values })
                      })}
                    ><i className="ico-check"/> {t(`${tKey}VALIDATE_BTN`)}</Button>),
                    (<Button
                      id="btn_cancel"
                      key="btn_cancel"
                      className="btn-reject"
                      onClick={() => this.directCloseModal()}
                    ><i className="ico-cancel"/> {t(`${tKey}CANCEL_BTN`)}</Button>
                    )
                  ] : null
                  }

                  { this.props.fleetMode === fleetMode.INCORPORATED_CHARGE ? [
                    (<Button
                      id="btn_validate_fleet_document"
                      key="btn_validate"
                      className="btn-validate-white"
                      onClick={handleSubmit(values => {
                        this.changeAll(values)
                        this.validateFleetDocument({ ...values })
                      })}
                    ><i className="ico-check"/> {t(`${tKey}VALIDATE_BTN`)}</Button>),
                    (
                      <Button
                        id="btn_reject_fleet_document"
                        className="btn-standard"
                        onClick={() => this.rejectDocumentFleet()}
                      ><i className="ico-cancel"/> {t(`${tKey}REJECT_BTN`)}</Button>
                    ),
                    (
                      <Button
                        id="btn_save_fleet_document"
                        className="btn-standard"
                        onClick={handleSubmit(values => {
                          this.changeAll(values)
                          this.submitFleetDocument(values)
                        })}
                        type="submit">
                        {t(`${tKey}SAVE_BTN`)}
                      </Button>
                    ),
                    (
                      <Button
                        id="btn_cancel_fleet_document"
                        className="btn-reject"
                        onClick={() => this.cancelDocumentFleet()}
                      >{t(`${tKey}CANCEL`)}</Button>
                    ),
                    (<Button
                      id="btn_cancel"
                      key="btn_cancel"
                      className="btn-reject"
                      onClick={() => this.directCloseModal()}
                    ><i className="ico-cancel"/> {t(`${tKey}CANCEL_BTN`)}</Button>
                    )
                  ] : null
                  }

                  { this.props.fleetMode === fleetMode.REEDIT ? [(
                    <Button
                      id="btn_recover_fleet_document"
                      className="btn-standard"
                      onClick={() => this.recoverDocumentFleet(documentId, documentTypeUseId, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, null, callback, refreshAfterChange, dossierType, dossierSubType, isRecurrent)}
                    >{t(`${tKey}RECOVER`)}</Button>
                  ), (<Button
                    id="btn_cancel"
                    key="btn_cancel"
                    className="btn-reject"
                    onClick={() => this.directCloseModal()}
                  ><i className="ico-cancel"/> {t(`${tKey}CANCEL_BTN`)}</Button>
                  )
                  ] : null
                  }
                  { this.props.fleetMode === fleetMode.CANCEL ? [(
                    <Button
                      id="btn_cancel_fleet_document"
                      className="btn-reject"
                      onClick={() => this.cancelDocumentFleet()}
                    >{t(`${tKey}CANCEL`)}</Button>
                  ), (<Button
                    id="btn_cancel"
                    key="btn_cancel"
                    className="btn-reject"
                    onClick={() => this.directCloseModal()}
                  ><i className="ico-cancel"/> {t(`${tKey}CANCEL_BTN`)}</Button>
                  )
                  ] : null
                  }
                  { this.props.fleetMode === fleetMode.REQUEST_SIGN ? [(
                    <Button
                      id="btn_request_sign_fleet"
                      className="btn-standard"
                      onClick={handleSubmit(values => {
                        this.signDocument(values, fleetsVisor)
                      })}
                    >{t(`${tKey}REQUEST_SIGN`)}</Button>
                  ), (<Button
                    id="btn_cancel"
                    key="btn_cancel"
                    className="btn-reject"
                    onClick={() => this.directCloseModal()}
                  ><i className="ico-cancel"/> {t(`${tKey}CANCEL_BTN`)}</Button>
                  )] : null}
                  { this.props.fleetMode === fleetMode.CANCEL_SIGN ? [(
                    <Button
                      id="btn_cancel_sign_fleet"
                      className="btn-reject-white"
                      onClick={() => this.cancelSignDocument()}
                    >{t(`${tKey}CANCEL_TO_SIGN`)}</Button>
                  ), (<Button
                    id="btn_cancel"
                    key="btn_cancel"
                    className="btn-reject"
                    onClick={() => this.directCloseModal()}
                  ><i className="ico-cancel"/> {t(`${tKey}CANCEL_BTN`)}</Button>
                  )] : null}
                  { this.props.fleetMode === fleetMode.REJECT ? [(
                    <Button
                      id="btn_reject_fleet_document"
                      className="btn-standard"
                      onClick={() => this.rejectDocumentFleet()}
                    ><i className="ico-cancel"/> {t(`${tKey}REJECT_BTN`)}</Button>
                  ), (<Button
                    id="btn_cancel"
                    key="btn_cancel"
                    className="btn-reject"
                    onClick={() => this.directCloseModal()}
                  ><i className="ico-cancel"/> {t(`${tKey}CANCEL_BTN`)}</Button>
                  )] : null}
                </div>
              </Col>)}
            {
              (showGenerateButtons && !this.props.docResult?.documentId)
                ? <Col sm={12} className="document-edition-modal-lower-side noDocumentModal row" >
                  <div className="btn-wrapper">
                  </div>
                </Col>
                : null
            }
          </Col>
          <Col sm={6} className="document-edition-modal-form">
            <RejectCommentModal
              t={t}
              showModal={this.state.showRejectCommentModal}
              actions={{
                rejectAction: this.rejectDocument,
                closeModal: () => this.setState({ showRejectCommentModal: false })
              }}
            />
            <CancelSignModal
              t={t}
              showModal={this.state.showCancelSignModal}
              cancelDocList={this.state.cancelDocList}
              forceCancelDocument = {this.props.docResult.forceCancelDocument}
              actions={{
                rejectAction: cancelSignMessage,
                closeModal: () => this.setState({ showCancelSignModal: false }),
                cancelSign,
                callback
              }}
              documentId={documentId}
              refreshAfterChange={refreshAfterChange}
              multipleSignCancel={multipleSignCancel}
            />
            <SignTypesModal
              t={t}
              showModal={this.state.showSignTypesModal}
              closeModalAction = {this.closeModalAction.bind(this)}
              actions={{
                requestSign,
                createRequestSignDocumentFleetProcess,
                closeAddOrEditDocumentModalSuccess
              }}
              documentId = {documentId}
              callback = {callback}
              refreshAfterChange = {refreshAfterChange}
              signTypesCombo = {this.state.signTypesCombo}
              fleetMode = {this.props.fleetMode}
              fleetDocumentDetails = {this.props.fleetDocumentDetails}
              fleetId = {this.props.fleetId}
            />
            <Form autoComplete="off" className='full-height' onSubmit={handleSubmit(values => {
              this.changeAll(values)
              this.submitDocument(values)
            })}>
              {
                // <Modal.Header closeButton></Modal.Header>
              }
              { /* MODAL BODY */ }
              <Modal.Body>
                <Row className="contenedor-img-principal">
                  <Col sm={12} style={{ height: '100%' }}>
                    <Row className="dropzone-row" style={{ height: '100%' }}>
                      { /* imagen
                  <Col sm={fields.length === 0 ? (12) : (fields.length > 5 ? (4) : (6))} style={{height: '100%', width: 'auto', margin: '0px 2%'}}>
                  </Col>
                  */}
                      { /* FORMULARIO */ }
                      <Col sm={12} className="document-fields-modal">
                        { /* cabecera */ }
                        <Modal.Header closeButton >
                          <Col sm={6}>
                            <Modal.Title>
                              {documentStatusId && (
                                <OverlayTrigger placement="right" overlay={statusTooltip}>
                                  <i id="documentStatus" className={'icono-heder-modal ico-document-' + documentStatusClass[translateDocumentStatus[documentStatusId]]}/>
                                </OverlayTrigger>
                              )}
                              <span className="document-title-ellipsis">
                                <OverlayTrigger placement="right" overlay={documentTitleTooltip}>
                                  <span id="documentTitle" ref={this.documentTitleElementRef} className={'document-title' + (!this.state.showTitle ? ' invisible' : '')}>{this.state.documentTitle}</span>
                                </OverlayTrigger>

                                {incorporationDate && <><br/> <span className="number-exp">{t(`${tKey}INCORPORATION_DATE`)}: <span className="incorporation-date">{incorporationDate}</span></span></>}
                                {(buttonSeal || (sealStatusId !== undefined && sealStatusId !== null)) && (
                                  <>
                                    <br/>
                                    <span className="number-exp">{t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.SEAL_STATE')}: {t(this.getSealStatus(sealStatusId))} </span>
                                  </>
                                )}
                                {signStatusId !== 'undefined' && signStatusId !== null && (
                                  <>
                                    <br/>
                                    <span className="number-exp">{t(`${tKey}SIGN_STATUS`)}: {t(`${tKey}` + this.getSignStatusText(signStatusId))} {}
                                      <span className="sign-status">
                                        <OverlayTrigger placement="left" overlay={signStatusTooltip}>
                                          <i id="signatureStatus" className={this.getColorBySignateStatus(signStatusId)}/>
                                        </OverlayTrigger>
                                      </span>
                                    </span>
                                  </>
                                )}
                                {signDate && <> <br/> <span className="number-exp">{t(`${tKey}SIGN_DATE`)}: <span className="sign-date">{signDate}</span></span> </>}
                              </span>
                            </Modal.Title>
                          </Col>
                          {this.props.fleetMode === null && (
                            <Col sm={6} style={{ paddingRight: '20px' }}>
                              {
                                typeObject === 1 && (
                                  <EntityPopoverEditionModal
                                    t={t}
                                    entity={data && data.entity}
                                    lives={data && data.lives}
                                    stored={data && data.stored}
                                    idx={1}
                                    id={'popover-entity' + 1}
                                  />
                                )
                              }
                              {
                                typeObject === 2 && (
                                  <VehiclePopoverEditionModal
                                    t={t}
                                    vehicle={data && data.vehicle}
                                    lives={data && data.lives}
                                    stored={data && data.stored}
                                    idx={1}
                                    id={'popover-vehicle' + 1}
                                  />
                                )
                              }
                              {
                                typeObject === 3 && data && ([
                                  <DossierPopoverEditionModal
                                    t={t}
                                    dossier={data && data.dossier}
                                    idx={1}
                                    id={'popover-entity' + 1}
                                    key={'popover-entity' + 1}
                                  />,
                                  <EntityPopoverEditionModal
                                    t={t}
                                    entity={data && data.entity}
                                    dossier={true}
                                    idx={2}
                                    id={'popover-entity' + 2}
                                    key={'popover-entity' + 2}
                                  />,
                                  <VehiclePopoverEditionModal
                                    t={t}
                                    vehicle={data && data.vehicle}
                                    dossier={true}
                                    idx={3}
                                    id={'popover-vehicle' + 3}
                                    key={'popover-vehicle' + 3}
                                  />
                                ])
                              }
                            </Col>)}
                        </Modal.Header>
                        {
                          !documentTypeUseName && this.state.showFileUploader
                            ? (
                              <Row>
                                <Field
                                  colSm={6}
                                  controlLabel={t(`${tKey}DOCUMENT_TYPE`)}
                                  id="documentType"
                                  name="documentType"
                                  placeholder={t(`${tKey}DOCUMENT_TYPE`)}
                                  component={InputSelect}
                                  menuContainerStyle={{ zIndex: 999 }}
                                  options={documentsTypes}
                                  onInputChange={(value) => this.handleDocTypeChange(value)}
                                  valueKey="id"
                                  labelKey="value"
                                  disabled={!!thumbnail}
                                  customClass="select-document-management"
                                />
                              </Row>
                            ) : null
                        }
                        { typeView && typeView !== typeViewConstant.empty && fields.length && this.viewFieldsFleet()
                          ? (<div className="form-modal-documental">
                            { typeView === typeViewConstant.entityValues
                              ? (metaLoading ? <SmallLoaderPage background="#ffffff" key="loader" specificComponentLoading={metaLoading ? 1 : 0 } /> : [<Col sm={5} key="metadata" className="columna-metadatos">
                                <Row className="row-modal" ><h3 style={{ fontSize: '13px' }}>{t(`${tKey}METADATA`)}</h3></Row>
                                <Row className="row-modal" >
                                  {
                                    fields.map((field, idx) => {
                                      return field.typeField !== typeFieldsConstant.licensePlateValidation ? (
                                        <div key={idx}>
                                          <SimpleInputText
                                            id={'metadata-' + field.code}
                                            value={field.metaValue ? field.metaValue : ''}
                                            colSm={4}
                                            controlLabel={field.name}
                                            disabled={true}
                                            paddingLeft={{ paddingLeft: '0px' }}
                                          />
                                        </div>
                                      ) : null
                                    })
                                  }
                                </Row>
                              </Col>,
                              <Col sm={2} key="copy" className="columna-boton-copiar">
                                <OverlayTrigger placement="top" overlay={copyValuesTooltip}>
                                  <Button className="button-copy-values" onClick={() => this.copyMetaToEntityValues()}>
                                    <Glyphicon glyph='log-in'/>
                                  </Button>
                                </OverlayTrigger>
                              </Col>
                              ]) : null
                            }
                            <Col sm={ typeView === typeViewConstant.entityValues ? (5) : (12)} className={ typeView === typeViewConstant.entityValues ? ('columna-datos-rellenar') : ('modal-columna-centrada') }>
                              <Row className="row-modal"><h3 style={{ fontSize: '13px' }}>{t(`${tKey}DATA_TO_FILL`)}</h3></Row>
                              <Row className="row-modal">
                                {
                                  fields.map((field, idx) => (
                                    <div key={idx}>
                                      {(() => {
                                        switch (field.typeField) {
                                          case typeFieldsConstant.number:
                                            return (
                                              <Field
                                                colSm={6}
                                                controlLabel={field.name}
                                                id={field.code}
                                                name={'entityValue.' + field.fieldId}
                                                component={InputNatural}
                                                disabled= {(!(documentId && !validatedText) || field.isReadOnly) || !permissionToEdit || documentId === null || (!buttonValidate && !buttonSave)}
                                                textAlign="right"
                                                paddingLeft={{ paddingLeft: '0px' }}
                                                maxLength = {field.maxLength ?? null}
                                              />
                                            )
                                          case typeFieldsConstant.money:
                                            return (
                                              <Field
                                                colSm={6}
                                                controlLabel={field.name}
                                                id={field.code}
                                                name={'entityValue.' + field.fieldId}
                                                component={InputMoney}
                                                disabled={(!(documentId && !validatedText) || field.isReadOnly) || !permissionToEdit || documentId === null || (!buttonValidate && !buttonSave)}
                                                textAlign="right"
                                                paddingLeft={{ paddingLeft: '0px' }}
                                                validate={[this.customValidate]}
                                                onInputChange={(event) => this.isFunctionToBeExecutedInFrontActive() && this.executeFieldFunction(field, fields, event.target.value)}
                                                maxLength = {field.maxLength ?? null}
                                              />
                                            )
                                          case typeFieldsConstant.decimal:
                                            return (
                                              <Field
                                                colSm={6}
                                                controlLabel={field.name}
                                                id={field.code}
                                                name={'entityValue.' + field.fieldId}
                                                component={InputDecimal}
                                                disabled= {(!(documentId && !validatedText) || field.isReadOnly) || !permissionToEdit || documentId === null || (!buttonValidate && !buttonSave)}
                                                textAlign="right"
                                                paddingLeft={{ paddingLeft: '0px' }}
                                                maxLength = {field.maxLength ?? null}
                                              />
                                            )
                                          case typeFieldsConstant.date:
                                            return (
                                              <Field
                                                colSm={6}
                                                controlLabel={field.name}
                                                id={field.code}
                                                name={'entityValue.' + field.fieldId}
                                                component={InputDatePicker}
                                                validate= {this.validateDate}
                                                disabled= {(!(documentId && !validatedText) || field.isReadOnly) || !permissionToEdit || documentId === null || (!buttonValidate && !buttonSave)}

                                                paddingLeft={{ paddingLeft: '0px' }}
                                              />
                                            )
                                          case typeFieldsConstant.dateTime:
                                            return (
                                              <Field
                                                colSm={6}
                                                controlLabel={field.name}
                                                id={field.code}
                                                name={'entityValue.' + field.fieldId}
                                                component={InputDateTimePicker}
                                                disabled={(!(documentId && !validatedText) || field.isReadOnly) || !permissionToEdit || documentId === null || (!buttonValidate && !buttonSave)}
                                                paddingLeft={{ paddingLeft: '0px' }}
                                              />
                                            )
                                          case typeFieldsConstant.list:
                                            return (
                                              <Field
                                                colSm={6}
                                                controlLabel={field.name}
                                                id={field.code}
                                                name={'entityValue.' + field.fieldId}
                                                placeholder={field.name}
                                                component={InputSelect}
                                                options={field.options}
                                                valueKey="id"
                                                labelKey="value"
                                                disabled= {((!(documentId && !validatedText) || field.isReadOnly) && !field.isEnabled && (this.state.canSave === undefined || !this.state.canSave)) || !permissionToEdit || documentId === null || (!buttonValidate && !buttonSave)}
                                                paddingLeft={{ paddingLeft: '0px' }}
                                                key="select-document"
                                                onInputChange={(value) => this.ChangesList(value, field.fieldId, field.notDocument, documentId)}
                                              />)
                                          case typeFieldsConstant.licensePlate:
                                            return (
                                              <Field
                                                colSm={6}
                                                controlLabel={field.name}
                                                id={field.code}
                                                name={'entityValue.' + field.fieldId}
                                                key={'entityValue.' + field.fieldId}
                                                placeholder={field.name}
                                                component={InputText}
                                                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                                                disabled={(!(documentId && !validatedText) || field.isReadOnly) || !permissionToEdit || documentId === null || (!buttonValidate && !buttonSave)}
                                                textAlign="left"
                                                paddingLeft={{ paddingLeft: '0px' }}
                                              />
                                            )
                                          case typeFieldsConstant.vinChassis:
                                            return (
                                              <Field
                                                colSm={6}
                                                maxLength={17}
                                                controlLabel={field.name}
                                                id={field.code}
                                                name={'entityValue.' + field.fieldId}
                                                placeholder={field.name}
                                                component={InputText}
                                                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                                                disabled= {(!(documentId && !validatedText) || field.isReadOnly) || !permissionToEdit || documentId === null || (!buttonValidate && !buttonSave)}
                                                textAlign="left"
                                                paddingLeft={{ paddingLeft: '0px' }}
                                              />
                                            )
                                          case typeFieldsConstant.licensePlateValidation:
                                            return ([
                                              <div key="clearfix_1" className="clearfix" />,
                                              <Col className='customCheckCol' key="col">
                                                <Field
                                                  customClass="disable-license-plate-checkbox"
                                                  controlLabel={field.name}
                                                  id={field.code}
                                                  name={'entityValue.' + field.fieldId}
                                                  key={'entityValue.' + field.fieldId}
                                                  placeholder={field.name}
                                                  component={InputCheckBox}
                                                  inline
                                                  disabled= {(!(documentId && !validatedText) || field.isReadOnly) || !permissionToEdit || documentId === null || (!buttonValidate && !buttonSave)}
                                                />
                                                <OverlayTrigger overlay={editTooltip} colSm={1}>
                                                  <Glyphicon className="disable-license-plate-icon" glyph="info-sign"/>
                                                </OverlayTrigger>
                                              </Col>,
                                              <div key="clearfix_2" className="clearfix" />
                                            ])
                                          default:
                                            return (
                                              <div style={{ display: field.fieldDependenceId && this.state.invisible ? 'none' : 'block' }}>
                                                <Field
                                                  colSm={6}
                                                  controlLabel={field.name}
                                                  id={field.code}
                                                  name={'entityValue.' + field.fieldId}
                                                  placeholder={field.name}
                                                  component={InputText}
                                                  disabled={((!(documentId && !validatedText) || field.isReadOnly) || !permissionToEdit) && !field.isEnabled && (this.state.canSave === undefined || !this.state.canSave) || (!buttonValidate && !buttonSave) }
                                                  textAlign="left"
                                                  paddingLeft={{ paddingLeft: '0px' }}
                                                  maxLength = {field.maxLength ?? null}
                                                />
                                              </div>
                                            )
                                        }
                                      })()}
                                    </div>
                                  ))
                                }
                              </Row>
                              {!permissionToEdit &&
                              <Row className="permission-recurrent-entity-invalid">
                                {t(`${tKey}PERMISSION_RECURRENT_ENTITY_INVALID`)}
                              </Row>
                              }
                            </Col>
                          </div>) : null
                        }
                        {
                          this.props.docResult.documentStatusId !== backEndIdDocumentStatus.REJECTED && commentDocument && this.viewFieldsFleet() && (
                            <Col sm={12}>
                              <Row className="row-modal"><h3 style={{ fontSize: '13px' }}>{t(`${tKey}TEXT`)}</h3></Row>
                              <Row className="row-modal">
                                <Field
                                  id='comment'
                                  name='comment'
                                  componentClass="textarea"
                                  colSm={12}
                                  placeholder={t(`${tKey}TEXT`)}
                                  component={InputText}
                                  customClass="comment-reject-modal-textarea"
                                  textAlign="left"
                                  paddingLeft={{ paddingLeft: '0px' }}
                                  disabled={(!(documentId && !validatedText)) && this.props.fleetMode !== fleetMode.REEDIT}
                                />
                              </Row>
                            </Col>
                          )}
                        {
                          comment && this.props.docResult.documentStatusId === backEndIdDocumentStatus.REJECTED && (
                            <Row className="reject-reason-row">
                              <Col sm={12}>
                                <SimpleInputText
                                  controlLabel={t(`${tKey}REJECT_REASON`)}
                                  id='comment'
                                  name='comment'
                                  value={comment}
                                  disabled={true}
                                  componentClass="textarea"
                                />
                              </Col>
                            </Row>
                          )
                        }
                      </Col>
                      { /* FIN FORMULARIO */ }
                    </Row>
                  </Col>
                </Row>

                { /* CARRUSEL IMAGENES + DROPZONE */ }
                {
                  ((!this.state.showFileUploader && documentId) && (solpheoDocumentId || storageDocumentId)) && (
                    <div className={this.state.showDocumentCarrusel ? 'carrusel-imagenes-modal toggleContent-open' : ' carrusel-imagenes-modal toggleContent-closed'} onMouseLeave={() => this.fileCarruselMouseLeave()}>
                      <OverlayTrigger placement="bottom" overlay={scrollToPreviusFileTooltip}>
                        <Button className="previus" onClick={() => this.scrollToFile(-1)}>
                          <Glyphicon glyph="chevron-left"/>
                        </Button>
                      </OverlayTrigger>
                      <div className="horizontal-scroll-wrapper squares" ref="horizontalScrollWrapper" style={{ maxHeight: anchoPantalla }}>
                        {numPages && (
                          <div key={'0.1'} className="img-carrusel" onClick={() => this.selectDocument(0, 1)}>
                            <ImgPage
                              src={`${settings.url}document/${documentId}/preview?token=${token}&pageId=${1}&fecha=${this.state.parametroFecha}&organizedUnitId=${organizedUnitId}`}
                              active={(this.props.activeSheet === 0) ? 'img-active' : ''}
                            />
                          </div>
                        )}
                        {(sheets.length > 0 || this.state.showSheets) && (
                          sheets.map((sheet, idx) => {
                            return (
                              <div key={sheet.id + '.1'} className="img-carrusel" onClick={() => this.selectDocument(sheet.id, 1)}>
                                <ImgPage
                                  src={`${settings.url}document/${documentId}/sheet/${sheet.id}/preview?token=${token}&pageId=${1}&fecha=${this.state.parametroFecha}&organizedUnitId=${organizedUnitId}`}
                                  active={(this.props.activeSheet === sheet.id) ? 'img-active' : ''}
                                />
                              </div>
                            )
                          })
                        )}
                      </div>
                      {
                        buttonNewSheet && this.props.fleetMode === null && (
                          <div className="modal-anadir-hoja">
                            <div className={'cuadro-anadir-hoja dropzone-box-wrapper-modal' + (this.state.hasInvalidFile ? ' has-error' : '')}>
                              { <DropZone
                                id="dropZone"
                                multiple={false}
                                onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles, organizedUnitId)}
                                accept={acceptFilesDropzone}
                                maxSize={maxSizeFilesDropzone}
                                style={{ height: '100%' }}
                                className={this.state.dropzonePanelActive ? 'active' : ''}
                                onDragEnter={() => this.onDragEnter(true)}
                                onDragLeave={() => this.onDragLeave(true)}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="text-wrapper" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <i className="ico-cloud"/>
                                    <p>
                                      {t(`${tKey}DROP_FILES_FULL`)}
                                      <br/>
                                      {this.state.hasInvalidFile && (
                                        <span className="help-block text-center">
                                          {t(`${tKey}VALIDATION.FILE`)}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                )}
                              </DropZone>}
                            </div>
                          </div>
                        )
                      }
                      <OverlayTrigger placement="bottom" overlay={scrollToNextFileTooltip}>
                        <Button className="next" onClick={() => this.scrollToFile(1)} id="btn_next">
                          <Glyphicon glyph="chevron-right"/>
                        </Button>
                      </OverlayTrigger>
                    </div>
                  )
                }
                { /* FIN CARRUSEL IMAGENES + DROPZONE */ }

                { /* BARRA INFO DOCUMENTO */ }
                {
                  ((!this.state.showFileUploader) && (solpheoDocumentId || storageDocumentId)) && (
                    <div className="barra-detalle-documento">
                      <OverlayTrigger placement="right" overlay={desplegarTooltip}>
                        <Button onClick={() => this.toggleDocumentCarrusel()}>
                          <Glyphicon glyph={this.state.showDocumentCarrusel ? 'chevron-down' : 'chevron-up'}/>
                        </Button>
                      </OverlayTrigger>
                      <span className="barra-detalle-documento-info">
                        <span className="numero-hoja">{t(`${tKey}SHEETS_TABLE.FILE`) + ' ' + (activeSheet === 0 ? (1) : (numeroHoja + 1)) + ' ' + t(`${tKey}SHEETS_TABLE.DE`) + ' ' + (sheets.length + 1)}</span>
                        <span className="fecha-hoja">
                          <span className="titulo-fecha-hoja">{t(`${tKey}SHEETS_TABLE.CREATION_DATE`)}</span>
                          <br/>
                          <span>{activeSheet === 0 ? (incorporationDate) : (fecha)}</span>
                        </span>
                      </span>
                      {
                        !readOnlyFields && buttonAddNewVersion && permissionToEdit && (
                          <OverlayTrigger placement="top" overlay={uploadNewVersionTooltip}>
                            <Button className="button-cloud" onClick={this.uploadNewVersion.bind(this)} id="btn_new_version">
                              <i className="ico-cloud"/>
                            </Button>
                          </OverlayTrigger>
                        )
                      }
                      {
                        !readOnlyFields && buttonNewSheet && permissionToEdit && this.props.fleetMode === null && (
                          <DropZone
                            multiple={false}
                            onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles, organizedUnitId)}
                            style={{ border: 'none', display: 'inline-block', height: '100%' }}
                            accept={acceptFilesDropzone}
                            maxSize={maxSizeFilesDropzone}
                            className={this.state.dropzonePanelActive ? 'active' : ''}
                            onDragEnter={() => this.onDragEnter(true)}
                            onDragLeave={() => this.onDragLeave(true)}
                          >
                            {() => (
                              <OverlayTrigger placement="top" overlay={uploadNewSheetTooltip}>
                                <Button className="button-cloud" onClick={() => this.toggleCloud()} id="btn_new_version_2">
                                  <i className="ico-document-plus"/>
                                </Button>
                              </OverlayTrigger>
                            )}
                          </DropZone>
                        )
                      }
                      {/*
                <OverlayTrigger placement="top" overlay={viewSheetTooltip}>
                  <Button className="button-search" onClick={(e) => activeSheet === 0 ? (this.toggleFullScreenDocumentModal(e)) : (this.toggleFullScreenDocumentSheetModal(e, activeSheet))}>
                    <i className="ico-magnifying"/>
                  </Button>
                </OverlayTrigger>
              */}
                      {buttonDelete && sheets.length > 0 && (
                        <OverlayTrigger placement="top" overlay={deleteSheetTooltip}>
                          <Button className="button-clear" onClick={(documentId) => {
                            this.deleteDocumentSheet(documentId, activeSheet, organizedUnitId)
                            this.props.trackAction(this.factoryDeleteSheetAction(documentId))
                          }} id="btn_delete_document_sheet">
                            <i className="ico-trash"/>
                          </Button>
                        </OverlayTrigger>
                      )
                      }
                    </div>
                  )
                }
                { /* FIN BARRA INFO DOCUMENTO */ }
              </Modal.Body>
              { /* FIN MODAL BODY */ }
            </Form>
            {
              (showGenerateButtons && !this.props.docResult.documentId)
                ? <Col sm={12} className="document-edition-modal-lower-side noDocumentModal row" >
                  <div className="btn-wrapper">
                    <Button
                      id="btn_generate"
                      className="btn-standard"
                      onClick={() => this.printTemplate()}
                    >{t(`${tKey}GENERATE`)}
                    </Button>
                    <Button
                      id="btn_generate_upload"
                      className="btn-standard"
                      onClick={() => this.handleGenerateAndUploadClick()}
                    >{t(`${tKey}GENERATE_UPLOAD`)}
                    </Button>
                  </div>
                </Col>
                : null
            }
          </Col>
        </Modal>
      </>
    )
  }
}
function validateFunction ({ entityValue = {} }, props) {
  const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.VALIDATION.'
  const error = { entityValue: {} }
  const { docResult: { fields = [] }, t } = props
  let aux
  const checkValidateLicensePlate = fields.find(f => f.typeField === typeFieldsConstant.licensePlateValidation)
  fields.forEach(field => {
    if (field.customValidations && field.customValidations.indexOf('regEx-') !== -1) {
      var value = entityValue[field.fieldId.toString()]

      if (value && (field.typeField === typeFieldsConstant.decimal || field.typeField === typeFieldsConstant.money)) {
        value = value.replace(',', '.')
        var parts = value.split('.')
        var wholePart = parts[0]
        var decimalPart = parts[1] ? parts[1].slice(0, 2) : ''
        if (decimalPart.length > 2) {
          decimalPart = decimalPart.slice(0, 2)
        }
        value = decimalPart ? wholePart + '.' + decimalPart : wholePart
      }

      aux = validateRegex(field.customValidations, field.name, value, props)

      if (aux !== Object(aux)) {
        error.entityValue[field.fieldId.toString()] = aux
      }
    }
    if (field.typeField === typeFieldsConstant.licensePlate && (!checkValidateLicensePlate || !entityValue[checkValidateLicensePlate.fieldId.toString()] || entityValue[checkValidateLicensePlate.fieldId.toString()] === 'False')) {
      var validateLicensePlateCountry = props?.docResult?.data?.validateLicensePlateCountry
      aux = validateLicensePlateFunciton(entityValue[field.fieldId.toString()], validateLicensePlateCountry || false, props.t('GLOBAL.FORMS.INVALID_STRING'))
      if (aux && aux !== Object(aux)) {
        error.entityValue[field.fieldId.toString()] = aux
      }
    }

    validateMetadatoByCode(entityValue, field, props, error)

    if (field.isMandatory && !entityValue[field.fieldId.toString()]) error.entityValue[field.fieldId.toString()] = t(`${tKey}MANDATORY`)
  })
  return error
}

var decoratedComponent = TrackingContainer(DocumentEdition, false)

export default reduxForm({
  form: 'AddOrEditDocumentForm',
  validate: validateFunction,
  destroyOnUnmount: false
})(decoratedComponent)

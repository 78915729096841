import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeInternalManagementDocModal } from '../../../../actions/modals/templateDataModal'
import InternalManagementDocModal from '../../../../components/dossiers/common/documentaryManagement/templateDataModal/InternalManagementDocModal'
import { printDocumentDynamicFromTemplate, createDocumentDynamicFromTemplate } from '../../../../actions/dossier/common/documentEditionModal'
export function mapStateToProps (state) {
  return {
    ...state.templateDataModal.internalManagementDocModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeInternalManagementDocModal,
      printDocumentDynamicFromTemplate,
      createDocumentDynamicFromTemplate
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(InternalManagementDocModal))

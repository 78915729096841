import templateDataModalActionTypes from '../../constants/actions/modals/templateDataModal'

export function openInternalManagementDocModal (dossierId, checkListId, action, organizedUnitId) {
  return {
    type: templateDataModalActionTypes.OPEN_INTERNAL_MANAGEMENT_DOC_MODAL,
    dossierId,
    checkListId,
    action,
    organizedUnitId
  }
}

export function closeInternalManagementDocModal () {
  return {
    type: templateDataModalActionTypes.CLOSE_INTERNAL_MANAGEMENT_DOC_MODAL
  }
}

export function openResponsibleDeclarationDocModal (dossierId, checkListId, action, organizedUnitId) {
  return {
    type: templateDataModalActionTypes.OPEN_RESPONSIBLE_DECLARATION_DOC_MODAL,
    dossierId,
    checkListId,
    action,
    organizedUnitId
  }
}

export function closeResponsibleDeclarationDocModal () {
  return {
    type: templateDataModalActionTypes.CLOSE_RESPONSIBLE_DECLARATION_DOC_MODAL
  }
}

export default {
  openInternalManagementDocModal,
  closeInternalManagementDocModal,
  openResponsibleDeclarationDocModal,
  closeResponsibleDeclarationDocModal
}

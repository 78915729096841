import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal, Col, Row, Form } from 'react-bootstrap'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import { permissions } from '../../../constants/backendIds'
import settings from '../../../setting'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { validateDate } from '../../../util/validationFunctions'
import commonRegExp from '../../../util/commonRegExp'

class ReassingPurchaseModal extends Component {
  constructor () {
    super()
    this.state = {}
    this.required = this.required.bind(this)
    this.dateValidation = this.dateValidation.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!this.props.showModal && nextProps.showModal) {
      const purchaseTypeCombo = this.props.combos?.purchaseTypeCombo || []
      let vehicle = {
        vinChassis: nextProps.vehicleOrderDetails.vinChassis || '',
        brandId: nextProps.vehicleOrderDetails.brandId || '',
        model: nextProps.vehicleOrderDetails.model || '',
        color: nextProps.vehicleOrderDetails.color || '',
        makerNumber: nextProps.vehicleOrderDetails.makerNumber || '',
        purchaseTypeId: nextProps.vehicleOrderDetails.purchaseTypeId || (purchaseTypeCombo.length === 1 ? purchaseTypeCombo[0].id : null),
        availabilityDate: nextProps.vehicleOrderDetails.availabilityDate || '',
        carlineId: nextProps.vehicleOrderDetails.carlineId || '',
        catalogueId: nextProps.vehicleOrderDetails.catalogueId || '',
        modelId: nextProps.vehicleOrderDetails.modelId || '',
        catalogueModelId: nextProps.vehicleOrderDetails.catalogueModelId || '',
        colorId: nextProps.vehicleOrderDetails.colorId || '',
        tapestryId: nextProps.vehicleOrderDetails.tapestryId || '',
        carline: nextProps.vehicleOrderDetails.carline || '',
        catalogue: nextProps.vehicleOrderDetails.catalogue || '',
        modelYear: nextProps.vehicleOrderDetails.modelYear || ''
      }
      this.props.initialize(vehicle)
    }
  }

  saveData (order) {
    this.props.actions.createOrderDossierPurchase(this.props.modalType, order)
    this.props.closeModal()
  }

  handleSubmitForm (values) {
    let order = {
      ...this.props.vehicleOrderDetails,
      dealerNumber: values.dealerNumber || '',
      vinChassis: values.vinChassis || '',
      brandId: values.brandId || '',
      model: values.model || '',
      color: values.color || '',
      makerNumber: values.makerNumber || '',
      purchaseTypeId: values.purchaseTypeId || '',
      availabilityDate: values.availabilityDate || '',
      carlineId: values.carlineId || '',
      catalogueId: values.catalogueId || '',
      modelId: values.modelId || '',
      catalogueModelId: values.catalogueModelId || '',
      colorId: values.colorId || '',
      tapestryId: values.tapestryId || '',
      carline: values.carline || '',
      catalogue: values.catalogue || '',
      modelYear: values.modelYear || ''
    }
    this.saveData(order)
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  required (value) {
    if (!value) {
      return this.props.t('GLOBAL.FORMS.REQUIRED')
    } else {
      return undefined
    }
  }

  onBrandIdChanges (brandId) {
    this.props.actions.resetCarlineCombo()
    this.props.actions.resetCatalogueCombo()
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    if (brandId) {
      this.props.actions.fetchCarlineCombo(brandId)
    }
  }

  onCarlineIdChanges (carlineId) {
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    if (carlineId) {
      this.props.actions.fetchCatalogueCombo(carlineId)
    }
  }

  onCatalogueIdChanges (catalogueId) {
    if (this.props.formValues.modelId && catalogueId) {
      this.props.actions.fetchReassignPurchaseCatalogueModel(catalogueId, this.props.formValues.modelId)
      this.props.actions.fetchColorCombo(catalogueId, this.props.formValues.modelId)
      this.props.actions.fetchTapestryCombo(catalogueId, this.props.formValues.modelId)
    }
  }

  onModelIdChanges (modelId) {
    if (modelId && this.props.formValues.catalogueId) {
      this.props.actions.fetchReassignPurchaseCatalogueModel(this.props.formValues.catalogueId, modelId)
      this.props.actions.fetchColorCombo(this.props.formValues.catalogueId, modelId)
      this.props.actions.fetchTapestryCombo(this.props.formValues.catalogueId, modelId)
    }
  }

  render () {
    const {
      t, handleSubmit, showModal, closeModal, actions: { synchroniseAvailabilityDate },
      combos: { brandCombo, purchaseTypeCombo, carlineCombo, catalogueCombo, modelCombo, colorCombo, tapestryCombo }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.REASSING_PURCHASE_MODAL.'
    const purchaseTypeComboSaleable = purchaseTypeCombo.filter(x => x.isNotSaleable === false)
    return (
      <Modal className="add-user-modal" show={showModal} onHide={closeModal}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.handleSubmitForm.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className='flexRow'>
              {this.props.mandatoryFields.purchaseTypeId.permission !== permissions.hidden && (
                <Field
                  id="purchaseTypeId"
                  name="purchaseTypeId"
                  component={InputSelect}
                  colSm={6}
                  controlLabel={t(`${tKey}PURCHASE_TYPE_ID`)}
                  placeholder={t(`${tKey}PURCHASE_TYPE_ID`)}
                  options={purchaseTypeComboSaleable}
                  labelKey="value"
                  valueKey="id"
                  onInputChange={purchaseTypeId => this.setState({ purchaseTypeId })}
                  validate={[this.required]}
                />
              )}
              {this.props.mandatoryFields.availabilityDate.permission !== permissions.hidden && (
                <Field
                  id="availabilityDate"
                  name="availabilityDate"
                  component={InputDatePicker}
                  colSm={6}
                  controlLabel={t(`${tKey}AVAILABILITY_DATE`)}
                  onBlur={availabilityDate => this.setState({ availabilityDate })}
                  validate={[this.dateValidation]}
                  onInputChange={synchroniseAvailabilityDate}
                />
              )}
              {this.props.mandatoryFields.makerNumber.permission !== permissions.hidden && (
                <Field
                  name="makerNumber"
                  id="reassingMakerNumber"
                  component={InputText}
                  colSm={6}
                  controlLabel={t(`${tKey}MAKER_NUMBER`)}
                  onBlur={makerNumber => this.setState({ makerNumber })}
                />
              )}
              {this.props.mandatoryFields.vsb.permission !== permissions.hidden && (
                <Field
                  name="dealerNumber"
                  id="dealerNumber"
                  component={InputText}
                  colSm={6}
                  controlLabel={t(`${tKey}DEALER_NUMBER`)}
                  onBlur={dealerNumber => this.setState({ dealerNumber })}
                  maxLength={settings.dealerMaxLength}
                />
              )}
              {this.props.mandatoryFields.brand.permission !== permissions.hidden && (
                <Field
                  name="brandId"
                  component={InputSelect}
                  colSm={6}
                  controlLabel={t(`${tKey}BRAND`)}
                  placeholder={t(`${tKey}BRAND`)}
                  options={brandCombo}
                  labelKey="value"
                  valueKey="id"
                  onInputChange={this.onBrandIdChanges.bind(this)}
                />
              )}

              {this.props.mandatoryFields.carlineId.permission !== permissions.hidden && (
                <Field
                  id="carlineId"
                  name="carlineId"
                  colSm={4}
                  controlLabel={t(`${tKey}CARLINE`)}
                  placeholder={t(`${tKey}CARLINE`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={carlineCombo}
                  disabled={carlineCombo.length === 0 || this.props.mandatoryFields.carlineId.permission !== permissions.editable}
                  onInputChange={this.onCarlineIdChanges.bind(this)}
                  menuPlacement="auto"
                />
              )}

              {this.props.mandatoryFields.carline.permission !== permissions.hidden && (
                <Field
                  id="carline"
                  name="carline"
                  colSm={4}
                  controlLabel={t(`${tKey}CARLINE`)}
                  placeholder={t(`${tKey}CARLINE`)}
                  component={InputText}
                  disabled={ this.props.mandatoryFields.carline.permission !== permissions.editable}
                />
              )}

              {this.props.mandatoryFields.catalogueId.permission !== permissions.hidden && (
                <Field
                  id="catalogueId"
                  name="catalogueId"
                  colSm={2}
                  controlLabel={t(`${tKey}CATALOGUE`)}
                  placeholder={t(`${tKey}CATALOGUE`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={catalogueCombo}
                  disabled={catalogueCombo.length === 0 || this.props.mandatoryFields.catalogueId.permission !== permissions.editable}
                  onInputChange={this.onCatalogueIdChanges.bind(this)}
                  menuPlacement="auto"
                />
              )}

              {this.props.mandatoryFields.catalogue.permission !== permissions.hidden && (
                <Field
                  id="catalogue"
                  name="catalogue"
                  colSm={4}
                  controlLabel={t(`${tKey}CATALOGUE`)}
                  placeholder={t(`${tKey}CATALOGUE`)}
                  component={InputText}
                  disabled={ this.props.mandatoryFields.catalogue.permission !== permissions.editable}
                />
              )}

              {this.props.mandatoryFields.modelId.permission !== permissions.hidden && (
                <Field
                  id="modelId"
                  name="modelId"
                  colSm={2}
                  controlLabel={t(`${tKey}MODEL_YEAR`)}
                  placeholder={t(`${tKey}MODEL_YEAR`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={modelCombo}
                  disabled={modelCombo.length === 0 || this.props.mandatoryFields.modelId.permission !== permissions.editable}
                  onInputChange={this.onModelIdChanges.bind(this)}
                  menuPlacement="auto"

                />
              )}
              {this.props.mandatoryFields.modelYear.permission !== permissions.hidden && (
                <Field
                  id="modelYear"
                  name="modelYear"
                  colSm={4}
                  controlLabel={t(`${tKey}MODEL_YEAR`)}
                  placeholder={t(`${tKey}MODEL_YEAR`)}
                  component={InputText}
                  disabled={ this.props.mandatoryFields.modelYear.permission !== permissions.editable}
                />
              )}
              {this.props.mandatoryFields.model.permission !== permissions.hidden && (
                <Field
                  id="model"
                  name="model"
                  colSm={4}
                  controlLabel={t(`${tKey}MODEL`)}
                  placeholder={t(`${tKey}MODEL`)}
                  component={InputText}
                  disabled={this.props.mandatoryFields.model.permission !== permissions.editable}
                />
              )}
              {this.props.mandatoryFields.colorId.permission !== permissions.hidden && (
                <Field
                  id="colorId"
                  name="colorId"
                  colSm={4}
                  controlLabel={t(`${tKey}COLOR`)}
                  placeholder={t(`${tKey}COLOR`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value" options={colorCombo}
                  disabled={colorCombo.length === 0 || this.props.mandatoryFields.colorId.permission !== permissions.editable}
                />
              )}
              {this.props.mandatoryFields.tapestryId.permission !== permissions.hidden && (
                <Field
                  id="tapestryId"
                  name="tapestryId"
                  colSm={4}
                  controlLabel={t(`${tKey}TAPESTRY`)}
                  placeholder={t(`${tKey}TAPESTRY`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={tapestryCombo}
                  disabled={tapestryCombo.length === 0 || this.props.mandatoryFields.tapestryId.permission !== permissions.editable}
                />
              )}
              {this.props.mandatoryFields.color.permission !== permissions.hidden && (
                <Field
                  id="color"
                  name="color"
                  colSm={4}
                  controlLabel={t(`${tKey}COLOR`)}
                  placeholder={t(`${tKey}COLOR`)}
                  component={InputText}
                  disabled={this.props.mandatoryFields.colorId.permission !== permissions.editable}
                />
              )}
              {this.props.mandatoryFields.tapestry.permission !== permissions.hidden && (
                <Field
                  id="tapestry"
                  name="tapestry"
                  colSm={4}
                  controlLabel={t(`${tKey}TAPESTRY`)}
                  placeholder={t(`${tKey}TAPESTRY`)}
                  component={InputText}
                  disabled={this.props.mandatoryFields.tapestry.permission !== permissions.editable}
                />
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper btn-center">
              <Button id="btn_save" className="btn-standard" type="submit">{t(`${tKey}CREATE_BUTTON`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'reassingPurchaseModal',
  validate: (values, props) => {
    const error = {}

    if (props.mandatoryFields.makerNumber.permission && props.mandatoryFields.makerNumber.permission !== permissions.hidden && !values.makerNumber &&
        props.mandatoryFields.vsb.permission && props.mandatoryFields.vsb.permission !== permissions.hidden && ((!values.dealerNumber || values.dealerNumber.lenght === 0))) {
      error.makerNumber = props.t('DOSSIER_COMPONENTS.REASSING_PURCHASE_MODAL.VALIDATION')
      error.dealerNumber = props.t('DOSSIER_COMPONENTS.REASSING_PURCHASE_MODAL.VALIDATION')
    }
    if (props.mandatoryFields.brand.permission && props.mandatoryFields.brand.permission !== permissions.hidden && (!values.brandId || values.brandId.lenght === 0)) {
      error.brandId = props.t('DOSSIER_COMPONENTS.REASSING_PURCHASE_MODAL.VALIDATION')
    }
    if (values.dealerNumber && values.dealerNumber !== '' && settings.dealerNumberRegex) {
      if (!values.dealerNumber.match(settings.dealerNumberRegex)) {
        error.dealerNumber = props.t('GLOBAL.FORMS.INVALID_STRING')
      }
    }

    if (props.mandatoryFields.modelId.permission &&
      props.mandatoryFields.modelId.permission !== permissions.hidden &&
      (!values.modelId || values.modelId.lenght === 0)) {
      error.modelId = props.t('DOSSIER_COMPONENTS.REASSING_PURCHASE_MODAL.VALIDATION')
    }

    if (props.mandatoryFields.modelYear.permission &&
      props.mandatoryFields.modelYear.permission !== permissions.hidden &&
      (!values.modelYear || !values.modelYear.match(commonRegExp.is4DigitYear))) {
      error.modelYear = props.t('GLOBAL.FORMS.INVALID_STRING')
    }
    return error
  }
})(ReassingPurchaseModal)

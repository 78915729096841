import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeResponsibleDeclarationDocModal } from '../../../../actions/modals/templateDataModal'
import ResponsibleDeclarationDocModal from '../../../../components/dossiers/common/documentaryManagement/templateDataModal/ResponsibleDeclarationDocModal'
import { printDocumentDynamicFromTemplate, createDocumentDynamicFromTemplate } from '../../../../actions/dossier/common/documentEditionModal'
export function mapStateToProps (state) {
  return {
    ...state.templateDataModal.responsibleDeclarationDocModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeResponsibleDeclarationDocModal,
      printDocumentDynamicFromTemplate,
      createDocumentDynamicFromTemplate
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ResponsibleDeclarationDocModal))

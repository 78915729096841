
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal, Col, Form, Panel } from 'react-bootstrap'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import InputCheckbox from '../../commons/form/InputCheckBox'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputMoney from '../../commons/form/InputMoney'
import { formatMoney } from '../../../util/utils'
import { documentUseCode } from '../../../constants/dossier/common/documentUseCode'

class DocumentDataCDAModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      openGeneralPanel: true,
      openRepresentativePanel: true,
      openVehiclePanel: true,
      openFinancingPanel: true,
      openTotalInitPanel: true,
      openCommercializationPanel: true,
      openObservationsPanel: true,
      openUACPanel: true
    }
  }

  sendDocumentData (incorporate, values) {
    let valuesConverted = this.addPdfCodes(values)
    if (!incorporate) {
      this.props.actions.printDocumentDynamicFromTemplate(documentUseCode.CDA_ORDER, this.props.dossierId, valuesConverted, null)
    } else {
      var sign = values.requestSignature ?? false
      new Promise((resolve, reject) => {
        this.props.actions.createSignDocumentFromTemplate(documentUseCode.CDA_ORDER, this.props.organizedUnitId, null, sign, this.props.dossierId, this.props.dossierId, null, null, valuesConverted, this.props.dossierType, this.props.lastAccessDate, resolve, reject)
      }).then((result) => {
        this.props.actions.closeCDAModal()
      }).catch(() => {
        this.props.actions.closeCDAModal()
      })
    }
  }

  componentDidUpdate () {}

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      if (this.props.documentDataOptionsCombo === undefined || this.props.documentDataOptionsCombo === null || this.props.documentDataOptionsCombo.length === 0) {
        this.props.actions.fetchDocumentDataOptions()
      }
      if (this.props.vehicletypeCombo === undefined || this.props.vehicletypeCombo === null || this.props.vehicletypeCombo.length === 0) {
        this.props.actions.fetchVehicleType()
      }
    }
    if (prevProps.showModal !== this.props.showModal && !this.props.showModal) {
      this.props.destroy()
      this.props.initialize()
    }

    return null
  }

  addPdfCodes (originalValues) {
    let values = JSON.parse(JSON.stringify(originalValues))

    let valuespdf = {}

    values.identifier ? valuespdf.identifier = { code: 'NumeroDeCliente', value: values.identifier } : null
    values.operationtype ? valuespdf.operationtype = { code: 'TipoDeOperacion', value: values.operationtype } : null
    values.paymentform ? valuespdf.paymentform = { code: 'FormaDePago', value: values.paymentform } : null
    values.cfdi ? valuespdf.cfdi = { code: 'UsoDelCFDI', value: values.cfdi } : null
    values.rfc ? valuespdf.rfc = { code: 'RFC_', value: values.rfc } : null
    values.invetory ? valuespdf.invetory = { code: 'Inventario_', value: values.invetory } : null
    values.receipt_date ? valuespdf.receipt_date = { code: 'FechaRecibo', value: values.receipt_date } : null
    values.vin_chassis ? valuespdf.vin_chassis = { code: 'VinVehiculo', value: values.vin_chassis } : null
    values.unit_value ? valuespdf.unit_value = { code: 'ValorDeLaUnidad', value: formatMoney(values.unit_value) } : null
    values.unit_balance ? valuespdf.unit_balance = { code: 'SaldoDeLaUnidad', value: formatMoney(values.unit_balance) } : null
    values.insurance ? valuespdf.insurance = { code: 'SeguroDeDaños', value: formatMoney(values.insurance) } : null
    values.life_insurance ? valuespdf.life_insurance = { code: 'SeguroDeVida', value: formatMoney(values.life_insurance) } : null
    values.ford_protect ? valuespdf.ford_protect = { code: 'FordProtec', value: formatMoney(values.ford_protect) } : null
    values.accessories ? valuespdf.accessories = { code: 'Accesorios', value: formatMoney(values.accessories) } : null
    values.monthly ? valuespdf.monthly = { code: 'Mensualidad', value: formatMoney(values.monthly) } : null
    values.hitch ? valuespdf.hitch = { code: 'Enganche', value: formatMoney(values.hitch) } : null
    values.insurance_hitch ? valuespdf.insurance_hitch = { code: 'SeguroDeDañosEnganche', value: formatMoney(values.insurance_hitch) } : null
    values.life_insurance_hitch ? valuespdf.life_insurance_hitch = { code: 'SeguroDeVidaEnganche', value: formatMoney(values.life_insurance_hitch) } : null
    values.credit_opening_hitch ? valuespdf.credit_opening_hitch = { code: 'AperturaDeCreditoEnganche', value: formatMoney(values.credit_opening_hitch) } : null
    values.plates_holding ? valuespdf.plates_holding = { code: 'PlacasYTenencia', value: formatMoney(values.plates_holding) } : null
    values.others ? valuespdf.others = { code: 'Otros', value: formatMoney(values.others) } : null
    values.full_start ? valuespdf.full_start = { code: 'TotalAlInicio', value: formatMoney(values.full_start) } : null
    values.agreement_list ? valuespdf.agreement_list = { code: 'ListaConvenio', value: values.agreement_list } : null
    values.bulletin ? valuespdf.bulletin = { code: 'Boletin', value: values.bulletin } : null
    values.prog_auto_charge ? valuespdf.prog_auto_charge = { code: 'PrograAutoCobro', value: values.prog_auto_charge } : null
    values.ford_contribution ? valuespdf.ford_contribution = { code: 'AportacionFord', value: formatMoney(values.ford_contribution) } : null
    values.distributor_contribution ? valuespdf.distributor_contribution = { code: 'AportacionDistribuidor', value: formatMoney(values.distributor_contribution) } : null
    values.pilot ? valuespdf.pilot = { code: 'Sicop', value: values.pilot } : null
    values.observations_1 ? valuespdf.observations_1 = { code: 'Observaciones_1', value: values.observations_1 } : null
    values.observations_2 ? valuespdf.observations_2 = { code: 'Observaciones_2', value: values.observations_2 } : null
    values.observations_3 ? valuespdf.observations_3 = { code: 'Observaciones_3', value: values.observations_3 } : null
    values.type_uac ? valuespdf.type_uac = { code: 'TipoUAC', value: values.type_uac } : null

    return valuespdf
  }

  render () {
    const { t, showModal, actions: { closeCDAModal }, vehicletypeCombo, documentDataOptionsCombo, handleSubmit } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALES.DOCUMENT_DATA_CDA_MODAL.'
    let operationTypeCombo = documentDataOptionsCombo?.filter(data => data.type === 'OperationType')
    let paymentFormCombo = documentDataOptionsCombo?.filter(data => data.type === 'PaymentType')
    let cfdiCombo = documentDataOptionsCombo?.filter(data => data.type === 'CFDI')
    return (
      <Modal className="rr-purchase-modal cdaModal" show={showModal} onHide={() => closeCDAModal()} backdrop={'static'}>
        <Form autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey + 'TITLE'}`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col sm={12} className="generalData">
              <Field
                id="identifier"
                name='identifier'
                placeholder={t(`${tKey}IDENTIFIER`)}
                controlLabel={t(`${tKey}IDENTIFIER`)}
                component={InputText}
                type="text"
                maxLength={20}
                colSm={4}
              />
              <Field
                id="operationtype"
                name='operationtype'
                placeholder={t(`${tKey}OPERATIONTYPE`)}
                controlLabel={t(`${tKey}OPERATIONTYPE`)}
                component={InputSelect}
                options={operationTypeCombo}
                valueKey="value"
                labelKey="value"
                colSm={4}
              />
              <Field
                id="paymentform"
                name='paymentform'
                placeholder={t(`${tKey}PAYMENTFORM`)}
                controlLabel={t(`${tKey}PAYMENTFORM`)}
                component={InputSelect}
                options={paymentFormCombo}
                valueKey="value"
                labelKey="value"
                colSm={4}
              />
              <Field
                id="cfdi"
                name='cfdi'
                placeholder={t(`${tKey}CFDI`)}
                controlLabel={t(`${tKey}CFDI`)}
                component={InputSelect}
                options={cfdiCombo}
                valueKey="value"
                labelKey="value"
                colSm={4}
              />
            </Col>

            <div className="fleet-panel dossier-panel" >
              <div className="header-panel fleet-panel-box" onClick={() => this.setState({ openRepresentativePanel: !this.state.openRepresentativePanel })}>

                <div className="name-wrapper fleet-panel-box-title">
                  <h4>{t(`${tKey}REPRESENTATIVE_DATA`)}  <span className='fleet-panel-subTitle'></span></h4>
                </div>

                <i className={this.state.openRepresentativePanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

              </div>
              <Panel expanded={this.state.openRepresentativePanel} onToggle={() => {}}>
                <Panel.Collapse>
                  <Col sm={12}>
                    <Field
                      id="rfc"
                      name='rfc'
                      placeholder={t(`${tKey}RFC`)}
                      controlLabel={t(`${tKey}RFC`)}
                      component={InputText}
                      type="text"
                      maxLength={13}
                      colSm={4}
                    />
                  </Col>
                </Panel.Collapse>
              </Panel>
            </div>
            <div className="fleet-panel dossier-panel" >
              <div className="header-panel fleet-panel-box" onClick={() => this.setState({ openVehiclePanel: !this.state.openVehiclePanel })}>

                <div className="name-wrapper fleet-panel-box-title">
                  <h4>{t(`${tKey}VEHICLE_DATA`)}  <span className='fleet-panel-subTitle'></span></h4>
                </div>

                <i className={this.state.openVehiclePanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

              </div>
              <Panel expanded={this.state.openVehiclePanel} onToggle={() => {}}>
                <Panel.Collapse>
                  <Col sm={12}>
                    <Field
                      id="invetory"
                      name='invetory'
                      placeholder={t(`${tKey}INVETORY`)}
                      controlLabel={t(`${tKey}INVETORY`)}
                      component={InputText}
                      type="text"
                      maxLength={100}
                      colSm={4}
                    />
                    <Field
                      id="receipt_date"
                      name='receipt_date'
                      placeholder={t(`${tKey}RECEIPT_DATE`)}
                      controlLabel={t(`${tKey}RECEIPT_DATE`)}
                      component={InputDatePicker}
                      colSm={4}
                    />
                    <Field
                      id="vin_chassis"
                      name='vin_chassis'
                      placeholder={t(`${tKey}VIN_CHASSIS`)}
                      controlLabel={t(`${tKey}VIN_CHASSIS`)}
                      component={InputText}
                      type="text"
                      maxLength={17}
                      colSm={4}
                    />
                  </Col>
                </Panel.Collapse>
              </Panel>
            </div>
            <div className="fleet-panel dossier-panel" >
              <div className="header-panel fleet-panel-box" onClick={() => this.setState({ openFinancingPanel: !this.state.openFinancingPanel })}>

                <div className="name-wrapper fleet-panel-box-title">
                  <h4>{t(`${tKey}FINANCING_DATA`)}  <span className='fleet-panel-subTitle'></span></h4>
                </div>

                <i className={this.state.openFinancingPanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

              </div>
              <Panel expanded={this.state.openFinancingPanel} onToggle={() => {}}>
                <Panel.Collapse>
                  <Col sm={12}>
                    <Field
                      id="unit_value"
                      name="unit_value"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}UNIT_VALUE`)}
                      placeholder={t(`${tKey}UNIT_VALUE`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                    <Field
                      id="unit_balance"
                      name="unit_balance"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}UNIT_BALANCE`)}
                      placeholder={t(`${tKey}UNIT_BALANCE`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                    <Field
                      id="insurance"
                      name="insurance"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}INSURANCE`)}
                      placeholder={t(`${tKey}INSURANCE`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                  </Col>
                  <Col sm={12}>
                    <Field
                      id="life_insurance"
                      name="life_insurance"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}LIFE_INSURANCE`)}
                      placeholder={t(`${tKey}LIFE_INSURANCE`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                    <Field
                      id="ford_protect"
                      name="ford_protect"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}FORD_PROTECT`)}
                      placeholder={t(`${tKey}FORD_PROTECT`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                    <Field
                      id="accessories"
                      name="accessories"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}ACCESSORIES`)}
                      placeholder={t(`${tKey}ACCESSORIES`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                  </Col>
                  <Col sm={12}>
                    <Field
                      id="monthly"
                      name="monthly"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}MONTHLY`)}
                      placeholder={t(`${tKey}MONTHLY`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                  </Col>
                </Panel.Collapse>
              </Panel>
            </div>
            <div className="fleet-panel dossier-panel" >
              <div className="header-panel fleet-panel-box" onClick={() => this.setState({ openTotalInitPanel: !this.state.openTotalInitPanel })}>

                <div className="name-wrapper fleet-panel-box-title">
                  <h4>{t(`${tKey}TOTAL_INIT_DATA`)}  <span className='fleet-panel-subTitle'></span></h4>
                </div>

                <i className={this.state.openTotalInitPanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

              </div>
              <Panel expanded={this.state.openTotalInitPanel} onToggle={() => {}}>
                <Panel.Collapse>
                  <Col sm={12}>
                    <Field
                      id="hitch"
                      name="hitch"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}HITCH`)}
                      placeholder={t(`${tKey}HITCH`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                    <Field
                      id="insurance_hitch"
                      name="insurance_hitch"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}INSURANCE`)}
                      placeholder={t(`${tKey}INSURANCE`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                    <Field
                      id="life_insurance_hitch"
                      name="life_insurance_hitch"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}LIFE_INSURANCE`)}
                      placeholder={t(`${tKey}LIFE_INSURANCE`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                  </Col>
                  <Col sm={12}>
                    <Field
                      id="credit_opening_hitch"
                      name="credit_opening_hitch"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}CREDIT_OPENING`)}
                      placeholder={t(`${tKey}CREDIT_OPENING`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                    <Field
                      id="plates_holding"
                      name="plates_holding"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}PLATES_HOLDING`)}
                      placeholder={t(`${tKey}PLATES_HOLDING`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                    <Field
                      id="others"
                      name="others"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}OTHERS`)}
                      placeholder={t(`${tKey}OTHERS`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                  </Col>
                  <Col sm={12}>
                    <Field
                      id="full_start"
                      name="full_start"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}FULL_START`)}
                      placeholder={t(`${tKey}FULL_START`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                  </Col>

                </Panel.Collapse>
              </Panel>
            </div>
            <div className="fleet-panel dossier-panel" >
              <div className="header-panel fleet-panel-box" onClick={() => this.setState({ openCommercializationPanel: !this.state.openCommercializationPanel })}>

                <div className="name-wrapper fleet-panel-box-title">
                  <h4>{t(`${tKey}COMMERCIAL_DATA`)}  <span className='fleet-panel-subTitle'></span></h4>
                </div>

                <i className={this.state.openCommercializationPanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

              </div>
              <Panel expanded={this.state.openCommercializationPanel} onToggle={() => {}}>
                <Panel.Collapse>
                  <Col sm={12}>
                    <Field
                      id="agreement_list"
                      name='agreement_list'
                      placeholder={t(`${tKey}AGREEMENT_LIST`)}
                      controlLabel={t(`${tKey}AGREEMENT_LIST`)}
                      component={InputText}
                      type="text"
                      maxLength={30}
                      colSm={4}
                    />
                    <Field
                      id="bulletin"
                      name='bulletin'
                      placeholder={t(`${tKey}BULLETIN`)}
                      controlLabel={t(`${tKey}BULLETIN`)}
                      component={InputText}
                      type="text"
                      maxLength={30}
                      colSm={4}
                    />
                    <Field
                      id="prog_auto_charge"
                      name='prog_auto_charge'
                      placeholder={t(`${tKey}PROG_AUTO_CHARGE`)}
                      controlLabel={t(`${tKey}PROG_AUTO_CHARGE`)}
                      component={InputText}
                      type="text"
                      maxLength={30}
                      colSm={4}
                    />
                  </Col>
                  <Col sm={12}>
                    <Field
                      id="ford_contribution"
                      name="ford_contribution"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}FORD_CONTRIBUTION`)}
                      placeholder={t(`${tKey}FORD_CONTRIBUTION`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                    <Field
                      id="distributor_contribution"
                      name="distributor_contribution"
                      type="text"
                      colSm={4}
                      controlLabel={t(`${tKey}DISTRIBUTOR_CONTRIBUTION`)}
                      placeholder={t(`${tKey}DISTRIBUTOR_CONTRIBUTION`)}
                      component={InputMoney}
                      maxLength={11}
                    />
                    <Field
                      id="pilot"
                      name="pilot"
                      placeholder={t(`${tKey}PILOT`)}
                      controlLabel={t(`${tKey}PILOT`)}
                      component={InputText}
                      type="text"
                      maxLength={30}
                      colSm={4}
                    />
                  </Col>
                </Panel.Collapse>
              </Panel>
            </div>
            <div className="fleet-panel dossier-panel" >
              <div className="header-panel fleet-panel-box" onClick={() => this.setState({ openObservationsPanel: !this.state.openObservationsPanel })}>

                <div className="name-wrapper fleet-panel-box-title">
                  <h4>{t(`${tKey}OBSERVATION_DATA`)}  <span className='fleet-panel-subTitle'></span></h4>
                </div>

                <i className={this.state.openObservationsPanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

              </div>
              <Panel expanded={this.state.openObservationsPanel} onToggle={() => {}}>
                <Panel.Collapse>
                  <Col sm={12}>
                    <Field
                      id="observations_1"
                      name='observations_1'
                      placeholder={t(`${tKey}OBSERVATIONS_1`)}
                      controlLabel={t(`${tKey}OBSERVATIONS_1`)}
                      component={InputText}
                      type="text"
                      maxLength={60}
                      colSm={12}
                    />
                    <Field
                      id="observations_2"
                      name='observations_2'
                      placeholder={t(`${tKey}OBSERVATIONS_2`)}
                      controlLabel={t(`${tKey}OBSERVATIONS_2`)}
                      component={InputText}
                      type="text"
                      maxLength={60}
                      colSm={12}
                    />
                    <Field
                      id="observations_3"
                      name='observations_3'
                      placeholder={t(`${tKey}OBSERVATIONS_3`)}
                      controlLabel={t(`${tKey}OBSERVATIONS_3`)}
                      component={InputText}
                      type="text"
                      maxLength={60}
                      colSm={12}
                    />
                  </Col>
                </Panel.Collapse>
              </Panel>
            </div>
            <div className="fleet-panel dossier-panel" >
              <div className="header-panel fleet-panel-box" onClick={() => this.setState({ openUACPanel: !this.state.openUACPanel })}>

                <div className="name-wrapper fleet-panel-box-title">
                  <h4>{t(`${tKey}UAC_DATA`)}  <span className='fleet-panel-subTitle'></span></h4>
                </div>

                <i className={this.state.openUACPanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

              </div>
              <Panel expanded={this.state.openUACPanel} onToggle={() => {}}>
                <Panel.Collapse>
                  <Col sm={12}>
                    <Field
                      id="type_uac"
                      name='type_uac'
                      placeholder={t(`${tKey}TYPE_UAC`)}
                      controlLabel={t(`${tKey}TYPE_UAC`)}
                      component={InputSelect}
                      options={vehicletypeCombo}
                      valueKey="value"
                      labelKey="value"
                    />
                  </Col>
                </Panel.Collapse>
              </Panel>
            </div>
            <div className='generalData '>
              <Col sm={12}>
                <Field
                  id="requestSignature"
                  name="requestSignature"
                  colSm={4}
                  controlLabel={t(`${tKey}REQUEST_SIGNATURE`)}
                  placeholder={t(`${tKey}REQUEST_SIGNATURE`)}
                  component={InputCheckbox}
                />
              </Col>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Col sm={9} className="btn-wrapper">
              <Button
                className="btn-standard"
                id='btn_generate'
                type='submit'
                onClick={handleSubmit(values => {
                  this.sendDocumentData(false, values)
                })}
              >
                {t(`${tKey + 'GENERATE'}`)}
              </Button>
              <Button
                className=""
                id='btn_incorporate'
                type='submit'
                onClick={handleSubmit(values => {
                  this.sendDocumentData(true, values)
                })}
              >
                {t(`${tKey + 'GENERATE_INCOPORATE'}`)}
              </Button>
            </Col>
            <Col sm={3} className="btn-wrapper alignRight">
              <Button
                className="btn-exit"
                id='btn_cancel'
                onClick={() => closeCDAModal()}
              >
                {t(`${tKey + 'CANCEL'}`)}
              </Button>
            </Col>

          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

const validate = (values, { t }) => {
  const tKey = 'DOSSIER_COMPONENTS.VALIDATIONS.'
  const errors = {}

  if (!values.identifier) errors.identifier = t(`${tKey}MANDATORY_FIELD`)
  if (!values.invetory) errors.invetory = t(`${tKey}MANDATORY_FIELD`)

  return errors
}

export default reduxForm({
  form: 'documentDataCDAModal',
  validate,
  shouldValidate: () => true
})(DocumentDataCDAModal)

import apiFetch from '../apiFetch'

export default function (documentTypeUseCode, token, lastAccessDate, dossierId, dynamicValues, checkListId, organizedUnitId, documentTypeId, id, chargeId, referenceType, referenceId) {
  const queryParamsArray = [
    documentTypeUseCode ? `documentTypeUseCode=${documentTypeUseCode}` : null,
    dossierId ? `dossierId=${dossierId}` : null,
    checkListId ? `checklistId=${checkListId}` : null,
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null,
    documentTypeId ? `documentTypeId=${documentTypeId}` : null,
    id ? `id=${id}` : null,
    chargeId ? `chargeId=${chargeId}` : null,
    referenceType ? `referenceType=${referenceType}` : null,
    referenceId ? `referenceId=${referenceId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `Document/CreateDocumentFromDynamicTemplate${queryParams}`, method: 'POST', body: dynamicValues, token: token, lastAccessDate })
}

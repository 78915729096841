import React, { PureComponent } from 'react'
import { Modal, Row, Col, Button, Form, OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import ReactImageZoom from 'react-image-zoom'
import ImgPage from '../../../../../containers/commons/general/ImgPage'
import SmallLoaderPage from '../../../../../containers/commons/SmallLoaderPage'
import InputSelect from '../../../../commons/form/InputSelect'
import InputText from '../../../../commons/form/InputText'
import InputNatural from '../../../../commons/form/InputNatural'
import InputMoney from '../../../../commons/form/InputMoney'
import InputCheckBox from '../../../../commons/form/InputCheckBox'
import InputDatePicker from '../../../../commons/form/InputDatePicker'
import InputDateTimePicker from '../../../../commons/form/InputDateTimePicker'
import SimpleInputText from '../../../../commons/simpleForm/SimpleInputText'
import { typeView as typeViewConstant } from '../../../../../constants/dossier/common/documentTypeView'
import { documentStatus as documentStatusConstant, translateDocumentStatus, documentStatusClass } from '../../../../../constants/dossier/common/documentStatus'
import { typeFields as typeFieldsConstant } from '../../../../../constants/dossier/common/typeFields'
import { dossierTypes as dossierTypesConstant } from '../../../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../../constants/dossier/common/dossierSubType'
import settings from '../../../../../setting'
import DropZone from 'react-dropzone'
import RejectCommentModal from '../../RejectCommentModal'
import EntityPopoverEditionModal from '../../../../dossiers/common/documentaryManagement/documentEditionModal/EntityPopoverEditionModal'
import VehiclePopoverEditionModal from '../../../../dossiers/common/documentaryManagement/documentEditionModal/VehiclePopoverEditionModal'
import DossierPopoverEditionModal from '../../../../dossiers/common/documentaryManagement/documentEditionModal/DossierPopoverEditionModal'
import { validateDate, validateRegex, customValidations, getAccepFiles } from '../../../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../../../util/normalizeFunctions'
import { entityTypesId as entityTypes, backEndIdDocumentStatus } from '../../../../../constants/backendIds'
import TrackingContainer from '../../../../../containers/tracking/trackingContainer'
import { CustomDimensionsConstant } from '../../../../../constants/tracking/CustomDimensionsConstant'
import { TrackingActions } from '../../../../../constants/tracking/TrackingActions'

const averageCharacter = 'c'

class DocumentEdition extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      documentTitle: averageCharacter,
      documentTitleCharWidth: null,
      documentTitleCharLimit: null,
      showTitle: false,
      files: [],
      selectedDocType: null,
      showRejectCommentModal: false,
      hasNewDocument: false,
      showFileUploader: false,
      showSheets: false,
      showMissingDocumentTypeError: false,
      hasInvalidFile: false,
      showDocumentCarrusel: false,
      autoCloseDocumentCarrusel: false,
      isDocumentCarruselEndReached: false,
      showPageCarrusel: true,
      autoClosePageCarrusel: false,
      zoomProps: {
        width: 0,
        height: 0,
        zoomWidth: 0,
        scale: 0.9,
        img: '',
        offset: { vertical: 0, horizontal: 10 },
        zoomStyle: 'box-shadow: 0px 2px 5px rgba(0,0,0,0.3)',
        zoomLensStyle: 'background-color: rgba(0,0,0,0.3); cursor: crosshair;'
      },
      hasDropzoneImg: false,
      parametroFecha: Date.now(),
      dropzoneActive: false,
      dropzonePanelActive: false,
      carouselItemsPerPage: 20,
      carouselPaginationIdx: 1
    }
    this.toggleFullScreenDocumentModal.bind(this)
    this.toggleFullScreenDocumentSheetModal.bind(this)
    this.deleteDocumentSheet.bind(this)
    this.cancelDocument.bind(this)
    this.setActivePage.bind(this)
    this.updateImgSize.bind(this)
    this.closeModal.bind(this)
    this.onDragEnter.bind(this)
    this.onDragLeave.bind(this)
    this.rejectDocument = this.rejectDocument.bind(this)
    this.documentTitleElementRef = React.createRef()
    // this.documentTitleElementRef = null
    // this.setDocumentTitleElementRef = element => {
    //   this.documentTitleElementRef = element
    // }
  }

  updateBaseTrackingAction () {
    this.baseTrackingAction = {
      href: window.location.href,
      documentTitle: this.props.dossierType + 'Historical_page',
      organizedUnitId: this.props.organizedUnitId,
      category: (this.props.dossierType === '' || !this.props.dossierType) ? '-' : this.props.dossierType,
      customDimensions: [
        { id: CustomDimensionsConstant.DOCUMENT_TYPE_ID, value: this.props.documentTypeId },
        { id: CustomDimensionsConstant.DOCUMENT_TYPE_USE_ID, value: this.props.documentTypeUseId },
        { id: CustomDimensionsConstant.DOCUMENT_TYPE_USE_NAME, value: this.props.documentTypeUseName }
      ]
    }
  }

  // La subida, validación, anulación, solicitud de firma, subida de hojas, borrado de hojas, apertura de la modal de documentos. Añadir información del documento si es posible.

  factoryOpenDocumentModalAction (dossierType, organizedUnitId, documentTypeUseId, documentTypeUseName) {
    this.updateBaseTrackingAction()
    var documentTypeuseDimension = this.baseTrackingAction.customDimensions.filter(x => x.id === CustomDimensionsConstant.DOCUMENT_TYPE_USE_ID)
    documentTypeuseDimension[0].value = documentTypeUseId

    dossierType = (dossierType === '' || !dossierType) ? '-' : dossierType

    var documentTypeuseNameDimension = this.baseTrackingAction.customDimensions.filter(x => x.id === CustomDimensionsConstant.DOCUMENT_TYPE_USE_NAME)
    documentTypeuseNameDimension[0].value = documentTypeUseName
    return { ...this.baseTrackingAction, action: TrackingActions.OPEN_DOCUMENT_MODAL, category: dossierType, organizedUnitId: organizedUnitId }
  }

  factoryCreateDocumentAction (documentType) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.CREATE_DOCUMENT }
  }

  factoryCreateDocumentSheetAction (file, documentId) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.CREATE_DOCUMENT_SHEET }
  }

  factoryValidateDocumentAction (documentId) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.VALIDATE_DOCUMENT }
  }

  factorycancelDocumentAction (documentId) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.CANCEL_DOCUMENT }
  }

  factorySignRequestAction (documentId) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.SIGN_REQUEST }
  }

  factoryDeleteSheetAction (documentId) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.DELETE_SHEET }
  }

  factorySaveDocumentAction (documentType) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.SAVE_DOCUMENT }
  }

  factoryRejectDocumentAction (documentType) {
    this.updateBaseTrackingAction()
    return { ...this.baseTrackingAction, action: TrackingActions.REJECT_DOCUMENT }
  }

  closeModal () {
    this.props.actions.closeAddOrEditDocumentModal(this.state.hasNewDocument)
    this.setState({
      documentTitle: averageCharacter,
      documentTitleCharWidth: null,
      documentTitleCharLimit: null,
      showTitle: false,
      files: [],
      selectedDocType: null,
      hasNewDocument: false,
      showFileUploader: false,
      showSheets: false,
      showMissingDocumentTypeError: false,
      hasInvalidFile: false,
      zoomProps: { ...this.state.zoomProps, width: 0, height: 0, zoomWidth: 0, img: '' },
      hasDropzoneImg: false,
      showDocumentCarrusel: false,
      isDocumentCarruselEndReached: false,
      showPageCarrusel: true,
      dropzoneActive: false,
      dropzonePanelActive: false,
      carouselPaginationIdx: 1
    })
    this.props.initialize({})
  }

  onDrop (fileToUpload, rejectedFiles, organizedUnitId) {
    if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
      this.setState({ hasInvalidFile: true })
    } else {
      this.setState({ files: [fileToUpload[0]], hasNewDocument: true, hasInvalidFile: false })
      if (fileToUpload[0]) {
        if (this.props.docResult.documentId) {
          this.props.actions.createDocumentSheet(fileToUpload[0], organizedUnitId)
          this.props.trackAction(this.factoryCreateDocumentSheetAction(fileToUpload[0], this.props.docResult.documentId))
        } else {
          this.props.actions.createDocument(fileToUpload[0], this.props.checklistId, this.props.documentTypeUseId || this.state.selectedDocType, this.props.dossierId)
          this.props.trackAction(this.factoryCreateDocumentAction(this.state.selectedDocType))
        }
      }
    }
  }

  onDragEnter (panel = false) {
    this.setState(panel ? { dropzonePanelActive: true } : { dropzoneActive: true })
  }

  onDragLeave (panel = false) {
    this.setState(panel ? { dropzonePanelActive: false } : { dropzoneActive: false })
  }

  changeAll (values) {
    Object.keys(values.entityValue).map(value => {
      this.props.change('AddOrEditHistoricalDocumentForm', `entityValue.${value}`, values.entityValue[value])
    })
  }

  submitDocument (values) {
    const errors = validateFunction(values, this.props)
    if (errors.entityValue && Object.keys(errors.entityValue || {}).length !== 0) return
    const valuesInFields = this.props.docResult.fields.map(field => {
      return {
        ...field, entityValue: values.entityValue[field.fieldId]
      }
    })
    if (values.validateAction) {
      this.props.actions.validateDocument(this.props.docResult.documentId, valuesInFields)
      this.props.trackAction(this.factoryValidateDocumentAction(this.props.docResult.documentId))
    } else {
      this.props.actions.saveDocument(valuesInFields)
      this.props.trackAction(this.factorySaveDocumentAction())
    }
  }

  handleDocTypeChange (documentTypeId) {
    const documentType = this.props.combos.documentsTypes.find((documentsType) => documentsType.id === documentTypeId)
    if (!documentTypeId) this.setState({ files: [] })
    this.setState({ selectedDocType: documentTypeId, showMissingDocumentTypeError: !documentTypeId })
    this.props.actions.onDocumentTypeIdChange(documentTypeId, documentType.value)
  }

  copyMetaToEntityValues () {
    this.props.docResult.fields.forEach(field => {
      this.props.change(`entityValue.${field.fieldId}`, field.metaValue || '')
    })
  }

  uploadNewVersion () {
    this.setState({ hasNewDocument: true })
    this.props.docResult.fields.forEach(field => {
      this.props.change(`entityValue.${field.fieldId}`, '')
    })
    this.props.actions.uploadNewVersion()
  }

  rejectDocument (values, resolve, reject) {
    this.props.actions.rejectDocument(this.props.docResult.documentId, values, resolve, reject)
    this.props.trackAction(this.factoryRejectDocumentAction())
  }

  toggleFullScreenDocumentModal (e) {
    e.stopPropagation()
    this.props.actions.toggleFullScreenDocumentModal(this.props.docResult.documentId)
  }

  downloadDocument (e) {
    e.stopPropagation()
    this.props.actions.toggleFullScreenDocumentModal(this.props.docResult.documentId, true)
  }

  toggleFullScreenDocumentSheetModal (e, sheetId) {
    e.stopPropagation()
    this.props.actions.toggleFullScreenDocumentSheetModal(this.props.docResult.documentId, sheetId)
  }

  downloadDocumentSheet (e, sheetId) {
    e.stopPropagation()
    this.props.actions.toggleFullScreenDocumentSheetModal(this.props.docResult.documentId, sheetId, true)
  }

  deleteDocumentSheet (e, sheetId, organizedUnitId) {
    e.stopPropagation()
    this.setActivePage(0, 1)
    new Promise((resolve, reject) => {
      var deleteDocumentSheetresult = this.props.actions.deleteDocumentSheet(this.props.docResult.documentId, sheetId, resolve, reject, organizedUnitId)
      this.props.trackAction(this.factoryDeleteSheetAction(this.props.docResult.documentId))
      return deleteDocumentSheetresult
    }).then(() => {
      const currentDate = Date.now()
      this.setState({
        parametroFecha: currentDate,
        zoomProps: {
          ...this.state.zoomProps,
          img: `${settings.url}documentHistorical/${this.props.docResult.documentId}/${this.props.activeSheet !== 0 ? `sheet/${this.props.activeSheet}/` : ''}preview?token=${this.props.token}&fecha=${currentDate}`
        }
      })
    })
  }

  getDocumentTitle (props) {
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.'
    return (props.docResult.documentId ? props.t(`${tKey}EDIT`) : props.t(`${tKey}ADD`)) + (props.documentTypeUseName ? (' - ' + props.documentTypeUseName) : '')
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.showDocumentEditionModal && !nextProps.showDocumentEditionModal) {
      this.setState({
        documentTitle: averageCharacter,
        documentTitleCharWidth: null,
        documentTitleCharLimit: null,
        showTitle: false,
        files: [],
        selectedDocType: null,
        dropzoneActive: false,
        dropzonePanelActive: false,
        carouselPaginationIdx: 1
      })
    }
    if (!this.props.showDocumentEditionModal && nextProps.showDocumentEditionModal) {
      nextProps.actions.setWindowDimensions(nextProps.windowWidth - 100, nextProps.windowHeight)
      window.setTimeout(() => nextProps.actions.setWindowDimensions(nextProps.windowWidth, nextProps.windowHeight), 100)
      this.props.trackAction(this.factoryOpenDocumentModalAction(nextProps.dossierType, nextProps.organizedUnitId, nextProps.documentTypeUseId, nextProps.documentTypeUseName))
    }
    if (nextProps.docResult && nextProps.docResult.documentId &&
      (
        this.props.docResult.documentId !== nextProps.docResult.documentId ||
        this.props.activeSheet !== nextProps.activeSheet ||
        this.props.activePage !== nextProps.activePage
      )
    ) {
      this.setState({
        zoomProps: {
          ...this.state.zoomProps,
          img: `${settings.url}documentHistorical/${nextProps.docResult.documentId}/${nextProps.activeSheet !== 0 ? `sheet/${nextProps.activeSheet}/` : ''}preview?token=${nextProps.token}${nextProps.activePage && '&pageId=' + nextProps.activePage}&fecha=${this.state.parametroFecha}`
        }
      })
    }
    if (nextProps.docResult.fields !== this.props.docResult.fields) {
      const fields = { entityValue: {}, metaValue: {} }
      nextProps.docResult.fields && nextProps.docResult.fields.length && nextProps.docResult.fields.forEach(field => {
        fields.entityValue[field.fieldId] = field.entityValue
        fields.metaValue[field.fieldId] = field.metaValue
      })
      fields.documentType = nextProps.docResult.documentTypeId
      fields.comment = nextProps.docResult.comment
      this.props.initialize(fields)
    }
    if (nextProps.docResult.sheets.length !== this.props.docResult.sheets.length) {
      this.setState({ showSheets: nextProps.docResult.sheets.length > 0 })
    }
    if (nextProps.docResult.documentId !== this.props.docResult.documentId || !nextProps.docResult.documentId) {
      this.setState({ showFileUploader: !nextProps.docResult.documentId })
    }
    if ((!this.props.showDocumentEditionModal && nextProps.showDocumentEditionModal) ||
      nextProps.docResult.fields !== this.props.docResult.fields ||
      this.props.activeSheet !== nextProps.activeSheet) {
      this.setState({ showPageCarrusel: nextProps.docResult.numPages && (nextProps.activeSheet === 0 ? nextProps.docResult.numPages : nextProps.docResult.sheets.find((e) => e.id === nextProps.activeSheet).numPages) > 1 })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.showDocumentEditionModal && this.props.docResult) {
      if (this.state.documentTitleCharWidth === null && this.documentTitleElementRef && this.documentTitleElementRef.current && this.state.documentTitle === averageCharacter) {
        this.setState({
          documentTitle: averageCharacter.repeat(19),
          documentTitleCharWidth: this.documentTitleElementRef.current.offsetWidth
        })
      }

      if (this.state.documentTitleCharLimit === null && this.documentTitleElementRef && this.documentTitleElementRef.current && this.state.documentTitle === averageCharacter.repeat(19)) {
        this.setState({
          documentTitleCharLimit: Math.floor(this.documentTitleElementRef.current.offsetWidth / this.state.documentTitleCharWidth * 2)
        })
      }

      if ((this.state.documentTitleCharLimit !== null && this.state.documentTitle === averageCharacter.repeat(19))) {
        let documentTitle = this.getDocumentTitle(this.props)
        if (documentTitle.length > this.state.documentTitleCharLimit) {
          documentTitle = documentTitle.substring(0, this.state.documentTitleCharLimit) + '...'
        }
        this.setState({ documentTitle, showTitle: true })
      }
    }
    const dropzoneImg = document.querySelector('.dropzone-img-wrapper > div > img')
    if (this.state.zoomProps.img && this.props.windowWidth !== 0 && this.props.windowHeight !== 0 &&
      (
        (!this.state.hasDropzoneImg && dropzoneImg) ||
        this.state.zoomProps.img !== prevState.zoomProps.img ||
        this.state.showPageCarrusel !== prevState.showPageCarrusel ||
        this.props.windowWidth !== prevProps.windowWidth || this.props.windowHeight !== prevProps.windowHeight
      )
    ) {
      if (dropzoneImg) {
        if (this.state.zoomProps.img === prevState.zoomProps.img && dropzoneImg.naturalWidth > 0) {
          this.updateImgSize(dropzoneImg)
        } else {
          if (dropzoneImg.naturalWidth > 0) {
            dropzoneImg.src = ''
            dropzoneImg.src = this.state.zoomProps.img
          }
          dropzoneImg.addEventListener('load', (e) => {
            this.updateImgSize(e.currentTarget)
          })
        }
      }
      if (this.state.hasDropzoneImg !== !!dropzoneImg) this.setState({ hasDropzoneImg: !!dropzoneImg })
    }
  }

  toggleDocumentCarrusel () {
    this.setState({
      showDocumentCarrusel: !this.state.showDocumentCarrusel
    })
  }

  togglePageCarrusel () {
    this.setState({
      showPageCarrusel: !this.state.showPageCarrusel
    })
  }

  toggleCloud () {
    this.setState({
      showDocumentCarrusel: true
    })
  }

  setActivePage (sheetId, pageId) {
    if (this.props.activeSheet !== sheetId) {
      this.setState({ autoCloseDocumentCarrusel: true })
    } else if (this.props.activePage !== pageId) {
      this.setState({ autoClosePageCarrusel: true })
    }
    this.props.actions.setActivePage(sheetId, pageId)
  }

  fileCarruselMouseLeave () {
    if (this.state.autoCloseDocumentCarrusel) {
      this.setState({ autoCloseDocumentCarrusel: false })
      this.toggleDocumentCarrusel()
    }
  }

  pageCarruselMouseLeave () {
    if (this.state.autoClosePageCarrusel) {
      this.setState({ autoClosePageCarrusel: false })
      this.togglePageCarrusel()
    }
  }

  scrollToFile (side = 1) {
    const initialScrollPosition = this.refs.horizontalScrollWrapper.scrollTop
    const thumbnailWidth = 215
    let allowedMovement = (this.state.isDocumentCarruselEndReached || thumbnailWidth)
    if (allowedMovement < thumbnailWidth / 2) allowedMovement += thumbnailWidth
    const totalTimePerStep = 100
    const distancePerInterval = Math.round(allowedMovement / totalTimePerStep) * side
    let currentDistance = 0
    let lastScroll

    const interval = window.setInterval(() => {
      lastScroll = this.refs.horizontalScrollWrapper.scrollTop
      currentDistance += distancePerInterval
      this.refs.horizontalScrollWrapper.scrollTop += distancePerInterval
      if ((currentDistance * side) >= allowedMovement || lastScroll === this.refs.horizontalScrollWrapper.scrollTop) {
        if (this.state.isDocumentCarruselEndReached) {
          this.setState({ isDocumentCarruselEndReached: false })
        } else if (Math.abs(initialScrollPosition - this.refs.horizontalScrollWrapper.scrollTop) < allowedMovement) {
          this.setState({ isDocumentCarruselEndReached: Math.abs(initialScrollPosition - this.refs.horizontalScrollWrapper.scrollTop) })
        }
        window.clearInterval(interval)
      }
    }, totalTimePerStep / allowedMovement)
  }

  cancelDocument (dossierType, documentId, documentParentTypeId, dossierId) {
    new Promise((resolve, reject) => {
      var cancelDocumetResult = this.props.actions.cancelDocument(dossierType, documentId, documentParentTypeId, dossierId, resolve, reject)
      this.props.trackAction(this.factorycancelDocumentAction(documentId))
      return cancelDocumetResult
    }).then(() => {
      this.closeModal()
    })
  }

  updateImgSize (dropzoneImg) {
    const allowWidth = this.props.windowWidth / 2 - (this.state.showPageCarrusel ? 120 : 60) - 20
    const allowHeight = this.props.windowHeight - 60 - 20
    let percentToReduce = 1
    if (dropzoneImg.naturalWidth > allowWidth) {
      percentToReduce = Math.min(percentToReduce, (allowWidth / dropzoneImg.naturalWidth))
    }
    if (dropzoneImg.naturalHeight > allowHeight) {
      percentToReduce = Math.min(percentToReduce, (allowHeight / dropzoneImg.naturalHeight))
    }
    if (percentToReduce < 1) {
      this.setState({
        zoomProps: {
          ...this.state.zoomProps,
          width: parseInt(dropzoneImg.naturalWidth * percentToReduce),
          height: dropzoneImg.naturalHeight * percentToReduce,
          zoomWidth: dropzoneImg.naturalWidth * percentToReduce
        }
      })
    } else {
      this.setState({
        zoomProps: {
          ...this.state.zoomProps,
          width: parseInt(dropzoneImg.naturalWidth),
          height: dropzoneImg.naturalHeight,
          zoomWidth: dropzoneImg.naturalWidth
        }
      })
    }
  }

  nextCarouselPage (val) {
    let carouselPaginationIdx = 0
    if (val === -1) {
      carouselPaginationIdx = this.state.carouselPaginationIdx - 1 === 0 ? 1 : this.state.carouselPaginationIdx - 1
    } else {
      carouselPaginationIdx = this.state.carouselPaginationIdx ? this.state.carouselPaginationIdx + 1 : 2
    }

    let activeSheetIdx = 0
    if (this.props.docResult && this.props.docResult.sheets && this.props.activeSheet) {
      activeSheetIdx = this.props.docResult.sheets.findIndex((sheet, idx) => {
        return this.props.activeSheet === sheet.id
      })
    }

    const initItem = carouselPaginationIdx > 1 ? (carouselPaginationIdx - 1) * this.state.carouselItemsPerPage + 1 : 1

    this.setActivePage(activeSheetIdx, initItem)
    this.setState({ carouselPaginationIdx: carouselPaginationIdx })
  }

  selectDocument (sheetId, pageId) {
    this.setActivePage(sheetId, pageId)
    this.setState({ carouselPaginationIdx: 1 })
  }

  render () {
    const {
      t, showDocumentEditionModal, handleSubmit, documentTypeUseName, token,
      documentTypeUseId, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, callback,
      refreshAfterChange, dossierType, dossierSubType, checkListIdReciboPagaYSenal, activeSheet, activePage, readOnlyFields,
      docResult: {
        documentId, typeView, documentStatusId, thumbnail,
        fields = [], comment, incorporationDate, sheets, numPages = 1, data, typeObject
      },
      actions: {
        openDocumentEditionModal,
        recoverDocument
      },
      combos: { documentsTypes }
    } = this.props
    let buttonUpload, buttonAddNewVersion, buttonNewSheet, buttonRecover, buttonSave, buttonValidate, iconReload, validatedText, buttonReject, buttonCancel, buttonDownload, editRecurrentEntityDocuments, commentDocument
    if (this.props.docResult && this.props.docResult.buttonsPermission) {
      buttonUpload = this.props.docResult.buttonsPermission.buttonUpload
      buttonAddNewVersion = this.props.docResult.buttonsPermission.buttonAddNewVersion
      buttonNewSheet = this.props.docResult.buttonsPermission.buttonNewSheet
      buttonRecover = this.props.docResult.buttonsPermission.buttonRecover
      buttonSave = this.props.docResult.buttonsPermission.buttonSave
      buttonValidate = this.props.docResult.buttonsPermission.buttonValidate
      iconReload = this.props.docResult.buttonsPermission.iconReload
      validatedText = this.props.docResult.buttonsPermission.validatedText
      buttonReject = this.props.docResult.buttonsPermission.buttonReject
      buttonCancel = this.props.docResult.buttonsPermission.buttonCancel
      buttonDownload = this.props.docResult.buttonsPermission.buttonDownload
      editRecurrentEntityDocuments = this.props.docResult.buttonsPermission.editRecurrentEntityDocuments
      commentDocument = this.props.docResult.buttonsPermission.commentDocument
    } else {
      buttonUpload = buttonAddNewVersion = buttonNewSheet = buttonRecover = buttonSave = buttonValidate = iconReload = validatedText = buttonReject = buttonCancel = buttonDownload = commentDocument = false
    }

    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.'
    const statusTooltip = (<Tooltip id="statusTooltip">{documentStatusId && t(`DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.${translateDocumentStatus[documentStatusId]}`)}</Tooltip>)
    const documentTitleTooltip = (<Tooltip id="documentTitleTooltip">{this.getDocumentTitle(this.props)}</Tooltip>)
    const viewSheetTooltip = (<Tooltip id="viewTooltip">{t(`${tKey}VIEW_SHEET_BTN`)}</Tooltip>)
    const downloadTooltip = (<Tooltip id="downloadTooltip">{t(`${tKey}DOWNLOAD_BTN`)}</Tooltip>)
    const deleteSheetTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_SHEET_BTN`)}</Tooltip>)
    const uploadNewSheetTooltip = (<Tooltip id="uploadNewSheetTooltip">{t(`${tKey}UPLOAD_NEW_FILE`)}</Tooltip>)
    const uploadNewVersionTooltip = (<Tooltip id="uploadNewVersionTooltip">{t(`${tKey}UPLOAD_NEW_VERSION`)}</Tooltip>)
    const copyValuesTooltip = (<Tooltip id="copyValuesTooltip">{t(`${tKey}COPY_VALUES`)}</Tooltip>)
    const desplegarTooltip = (<Tooltip id="desplegarTooltip">{t(`${tKey}VIEW_MORE_FILES`)}</Tooltip>)
    const scrollToPreviusFileTooltip = (<Tooltip id="scrollToPreviusFileTooltip">{t(`${tKey}PREVIUS_FILE`)}</Tooltip>)
    const scrollToNextFileTooltip = (<Tooltip id="scrollToNextFileTooltip">{t(`${tKey}NEXT_FILE`)}</Tooltip>)
    const acceptFilesDropzone = getAccepFiles(this.props.documentTypeUseId)
    const maxSizeFilesDropzone = 10240000
    const editTooltip = (<Tooltip id="editTooltip">{t('DOSSIER_COMPONENTS.VEHICLE.TOOLTIP_VALIDATE_LICENSE_PLATE')}</Tooltip>)
    let fecha
    let numeroHoja
    const anchoPantalla = (window.screen.width / 2) - ((documentId && documentStatusId !== documentStatusConstant.VALIDATED) ? 245 : 0)

    const canBeAddedInHistorical = buttonUpload
    const permissionToEdit = data && data.entity && (data.entity.entityTypeId === entityTypes.recurrentEntity && typeObject === 1 ? editRecurrentEntityDocuments : canBeAddedInHistorical)

    sheets.map((sheet, idx) => {
      sheet.id === activeSheet ? (
        fecha = sheet.creationDate,
        numeroHoja = idx + 1
      ) : null
    })
    return (
      <Modal show={showDocumentEditionModal} className="document-edition-modal" onHide={() => this.closeModal()}>
        <Col sm={6} className="document-edition-modal-visor">
          {
            !this.state.showFileUploader && (
              <Col sm={1} className={'document-edition-modal-left-side barra-detalle-documento ' + (this.state.showPageCarrusel && settings.clientFieldValidation !== 'toyo' ? 'toggleContent-open ' : 'toggleContent-closed ') + (buttonDownload ? 'with-download' : '')} onMouseLeave={() => this.pageCarruselMouseLeave()}>
                <div className="btn-wrapper">
                  {settings.clientFieldValidation !== 'toyo' && <OverlayTrigger placement="right" overlay={desplegarTooltip}>
                    <Button onClick={() => this.togglePageCarrusel()}>
                      <Glyphicon glyph={this.state.showPageCarrusel ? 'chevron-left' : 'chevron-right'} />
                    </Button>
                  </OverlayTrigger>}
                  <OverlayTrigger placement="bottom" overlay={viewSheetTooltip}>
                    <Button className="button-search search-img-grande" onClick={(e) => activeSheet === 0 ? (this.toggleFullScreenDocumentModal(e)) : (this.toggleFullScreenDocumentSheetModal(e, activeSheet))}>
                      <i className="ico-magnifying" />
                    </Button>
                  </OverlayTrigger>
                  {buttonDownload &&
                    (<OverlayTrigger placement="right" overlay={downloadTooltip}>
                      <Button className="button-search search-img-grande" onClick={(e) => activeSheet === 0 ? (this.downloadDocument(e)) : (this.downloadDocumentSheet(e, activeSheet))}>
                        <Glyphicon glyph={'cloud-download'} />
                      </Button>
                    </OverlayTrigger>)}
                </div>
                {
                  (!this.state.showFileUploader && documentId) && (
                    <div className={'carrusel-imagenes-modal ' + (buttonDownload ? 'with-download ' : '') + (numPages > this.state.carouselItemsPerPage ? '' : 'without-pagination ') + (this.state.showPageCarrusel ? '' : 'hide-page-carousel')}>
                      <div className="vertical-scroll-wrapper squares">
                        {activeSheet === 0 && numPages && (
                          (() => {
                            const pages = []
                            if (settings.clientFieldValidation !== 'toyo') {
                              const initItem = this.state.carouselPaginationIdx > 1 ? (this.state.carouselPaginationIdx - 1) * this.state.carouselItemsPerPage + 1 : 1
                              let lastItem = this.state.carouselPaginationIdx ? (initItem > 1 ? initItem + this.state.carouselItemsPerPage - 1 : this.state.carouselItemsPerPage) : numPages

                              if (lastItem > numPages) {
                                lastItem = numPages
                              }
                              for (let i = initItem; i <= lastItem; i++) {
                                pages.push(
                                  <div key={'0.' + i} className="img-carrusel" onClick={() => this.setActivePage(0, i)}>
                                    <ImgPage
                                      src={`${settings.url}documentHistorical/${documentId}/preview?token=${token}&pageId=${i}&fecha=${this.state.parametroFecha}`}
                                      active={(this.props.activeSheet === 0 && this.props.activePage === i) ? 'img-active' : ''}
                                    />
                                  </div>
                                )
                              }
                            }
                            return pages
                          })()
                        )}
                        {activeSheet !== 0 && (sheets.length > 0 || this.state.showSheets) && (
                          (() => {
                            const activeSheetIdx = sheets.findIndex((sheet, idx) => {
                              return activeSheet === sheet.id
                            })
                            const pages = []
                            if (settings.clientFieldValidation !== 'toyo') {
                              if (activeSheetIdx !== -1) {
                                const activeSheet = sheets[activeSheetIdx]
                                const initItem = this.state.carouselPaginationIdx > 1 ? (this.state.carouselPaginationIdx - 1) * this.state.carouselItemsPerPage + 1 : 1
                                let lastItem = this.state.carouselPaginationIdx ? (initItem > 1 ? initItem + this.state.carouselItemsPerPage - 1 : this.state.carouselItemsPerPage) : activeSheet.numPages

                                if (lastItem > activeSheet.numPages) {
                                  lastItem = activeSheet.numPages
                                }
                                for (let i = initItem; i <= lastItem; i++) {
                                  pages.push(
                                    <div key={activeSheet.id + '.' + i} className="img-carrusel" onClick={() => this.setActivePage(activeSheet.id, i)}>
                                      <ImgPage
                                        src={`${settings.url}documentHistorical/${documentId}/sheet/${activeSheet.id}/preview?token=${token}&pageId=${i}&fecha=${this.state.parametroFecha}`}
                                        active={(this.props.activeSheet === activeSheet.id && this.props.activePage === i) ? 'img-active' : ''}
                                      />
                                    </div>
                                  )
                                }
                              }
                            }
                            return pages
                          })()
                        )}
                      </div>
                    </div>
                  )
                }
                <div className="barra-detalle-page-info"><span className="numero-hoja">{t(`${tKey}SHEETS_TABLE.PAGE`) + ' ' + (activePage) + ' ' + t(`${tKey}SHEETS_TABLE.DE`) + ' ' + (activeSheet === 0 ? numPages : sheets.find((e) => e.id === activeSheet).numPages)}</span></div>
                {numPages > this.state.carouselItemsPerPage && <div>
                  <Button
                    onClick={() => this.nextCarouselPage(-1)}
                    disabled={this.state.carouselPaginationIdx === 1}>
                    {'<'}
                  </Button>
                  <Button
                    onClick={() => this.nextCarouselPage(1)}
                    disabled={this.state.carouselPaginationIdx * this.state.carouselItemsPerPage + 1 > numPages}>
                    {'>'}
                  </Button>
                </div>}
              </Col>
            )
          }

          <Col sm={4} className="document-edition-modal-middle-side">
            <div className={'dropzone-wrapper-modal' + (this.state.hasInvalidFile || this.state.showMissingDocumentTypeError ? ' has-error' : '')} style={{ height: '100%' }}>
              {
                iconReload
                  ? (
                    <div onClick={() => openDocumentEditionModal(documentId, documentTypeUseId, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, null, callback, refreshAfterChange, dossierType, null, null, null, null, null, readOnlyFields)} className="text-wrapper">
                      <img src="images/check_list.gif" alt="" />
                      <h2>{t(`${tKey}LOADING_MESSAGE`)}</h2>
                      <p>{t(`${tKey}TRY_FETCH_DOCUMENT`)}</p>
                    </div>
                  ) : (
                    !this.state.showFileUploader ? (
                      <div id="dropZone" className="dropzone-thumbnail" style={{ height: '100%' }}>
                        {this.props.isLoading ? (
                          <div style={{ height: '100%', border: '1px solid #b1b1b1', paddingTop: '30%' }}>
                            <SmallLoaderPage
                              background="#ffffff"
                            />
                          </div>
                        ) : (
                          <div className="dropzone-img-wrapper" style={{ width: this.state.zoomProps.width + 'px', height: this.state.zoomProps.height + 'px' }}>
                            {
                              this.state.zoomProps.img !== '' && (
                                <ReactImageZoom {...this.state.zoomProps} />
                              )
                            }
                          </div>
                        )}
                      </div>
                    ) : buttonUpload && (
                      <DropZone
                        id="dropZone"
                        multiple={false}
                        onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles, organizedUnitId)}
                        disabled={(!this.state.selectedDocType && !documentTypeUseName) || !permissionToEdit}
                        accept={acceptFilesDropzone}
                        maxSize={maxSizeFilesDropzone}
                        style={{ height: '100%' }}
                        className={this.state.dropzoneActive ? 'active' : 'unactive'}
                        onDragEnter={() => this.onDragEnter(false)}
                        onDragLeave={() => this.onDragLeave(false)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="text-wrapper modal-subir-fichero-inicio" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div onClick={() => { if (!this.state.selectedDocType && !documentTypeUseName) this.setState({ showMissingDocumentTypeError: true }) }}>
                              <i className={!documentTypeUseName ? 'ico-cloud cloud-apagado' : 'ico-cloud cloud-size'} />
                              {!this.state.showMissingDocumentTypeError ? [
                                <h2 key="dropZone-title">{t(`${tKey}DROP_FILES`)}</h2>,
                                <p key="dropZone-subtitle">{t(`${tKey}SELECT_FILES`)}</p>,
                                this.state.hasInvalidFile && <div className="help-block text-center" key="dropZone-help-block">{t(`${tKey}VALIDATION.FILE`)}</div>
                              ] : (
                                <h2>{t(`${tKey}VALIDATION.DOCUMENT_TYPE_REQUIRED`)}</h2>
                              )}
                            </div>
                          </div>
                        )}
                      </DropZone>
                    )
                  )
              }
            </div>
          </Col>

          {
            !this.state.showFileUploader && (
              <Col sm={12} className="document-edition-modal-lower-side row" >
                <div className="btn-wrapper">
                  {
                    buttonValidate && permissionToEdit ? [
                      <Button
                        id="btn_validate"
                        key="btn_validate"
                        className="btn-validate-white"
                        onClick={handleSubmit(values => {
                          this.changeAll(values)
                          this.submitDocument({ ...values, validateAction: true })
                        })}
                      ><i className="ico-check" /> {t(`${tKey}VALIDATE_BTN`)}</Button>,
                      buttonReject && (
                        <Button
                          id="btn_reject"
                          key="btn_reject"
                          className="btn-reject-white"
                          onClick={() => this.setState({ showRejectCommentModal: true })}
                        ><i className="ico-cancel" /> {t(`${tKey}REJECT_BTN`)}</Button>
                      ),
                      (settings.showSignDocumentButton && dossierType === dossierTypesConstant.sales &&
                        dossierSubType === dossierSubTypeIdConstants.venta_vn && documentTypeUseId === 4) && // Recibo de paga y señal
                      (
                        <Button
                          id="btn_sign"
                          key="btn_sign"
                          className="btn-standard"
                          onClick={() => {
                            this.props.trackAction(this.factorySignRequestAction(documentId))
                            const signAppUrl = `http://signidoccar.premm.es/?entorno=${encodeURIComponent(settings.url)}&token=${token}&checkListId=${checkListIdReciboPagaYSenal}&organizedUnitId=${organizedUnitId}&documentTypeId=${documentTypeUseId}&id=${dossierId}&documentId=${documentId}&fecha=${this.state.parametroFecha}`
                            window.open(signAppUrl, '_blank')
                          }}
                        >{t(`${tKey}SIGN_BTN`)}</Button>
                      )
                    ] : null
                  }
                </div>
                <div className="btn-wrapper">
                  {
                    buttonSave && permissionToEdit ? (
                      <Button
                        id="btn_save"
                        className="btn-standard"
                        onClick={handleSubmit(values => {
                          this.changeAll(values)
                          this.submitDocument(values)
                        })}
                        type="submit">
                        {t(`${tKey}SAVE_BTN`)}
                      </Button>
                    ) : null
                  }
                  {
                    buttonRecover && permissionToEdit ? (
                      <Button
                        id="btn_recover"
                        className="btn-standard"
                        onClick={() => recoverDocument(documentId, documentTypeUseId, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, null, callback, refreshAfterChange, dossierType)}
                      >{t(`${tKey}RECOVER`)}</Button>
                    ) : null
                  }
                  {
                    buttonCancel && permissionToEdit ? (
                      <Button
                        id="btn_cancel"
                        className="btn-reject"
                        onClick={() => this.cancelDocument(dossierType, documentId, documentParentTypeId, dossierId)}
                      >{t(`${tKey}CANCEL`)}</Button>
                    ) : null
                  }
                </div>
              </Col>
            )
          }
        </Col>
        <Col sm={6} className="document-edition-modal-form">
          <RejectCommentModal
            t={t}
            showModal={this.state.showRejectCommentModal}
            actions={{
              rejectAction: this.rejectDocument,
              closeModal: () => this.setState({ showRejectCommentModal: false })
            }}
          />
          <Form autoComplete="off" style={{ height: '100%' }} onSubmit={handleSubmit(values => {
            this.changeAll(values)
            this.submitDocument(values)
          })}>
            {
              // <Modal.Header closeButton></Modal.Header>
            }
            { /* MODAL BODY */}
            <Modal.Body>
              <Row className="contenedor-img-principal">
                <Col sm={12} style={{ height: '100%' }}>
                  <Row className="dropzone-row" style={{ height: '100%' }}>
                    { /* imagen
                  <Col sm={fields.length === 0 ? (12) : (fields.length > 5 ? (4) : (6))} style={{height: '100%', width: 'auto', margin: '0px 2%'}}>
                  </Col>
                  */}
                    { /* FORMULARIO */}
                    <Col sm={12} style={{ height: '100%' }}>
                      { /* cabecera */}
                      <Modal.Header closeButton>
                        <Col sm={6}>
                          <Modal.Title>
                            {documentStatusId && (
                              <OverlayTrigger placement="right" overlay={statusTooltip}>
                                <i id="documentStatus" className={'icono-heder-modal ico-document-' + documentStatusClass[translateDocumentStatus[documentStatusId]]} />
                              </OverlayTrigger>
                            )}
                            <span className="document-title-ellipsis">
                              <OverlayTrigger placement="right" overlay={documentTitleTooltip}>
                                <span id="documentTitle" ref={this.documentTitleElementRef} className={'document-title' + (!this.state.showTitle ? ' invisible' : '')}>{this.state.documentTitle}</span>
                              </OverlayTrigger>
                              <br />
                              {incorporationDate && <span className="number-exp">{t(`${tKey}INCORPORATION_DATE`)}: <span className="incorporation-date">{incorporationDate}</span></span>}
                            </span>
                          </Modal.Title>
                        </Col>
                        <Col sm={6} style={{ paddingRight: '20px' }}>
                          {
                            typeObject === 1 && (
                              <EntityPopoverEditionModal
                                t={t}
                                entity={data && data.entity}
                                lives={data && data.lives}
                                stored={data && data.stored}
                                idx={1}
                                id={'popover-entity' + 1}
                              />
                            )
                          }
                          {
                            typeObject === 2 && (
                              <VehiclePopoverEditionModal
                                t={t}
                                vehicle={data && data.vehicle}
                                lives={data && data.lives}
                                stored={data && data.stored}
                                idx={1}
                                id={'popover-vehicle' + 1}
                              />
                            )
                          }
                          {
                            typeObject === 3 && ([
                              <DossierPopoverEditionModal
                                t={t}
                                dossier={data && data.dossier}
                                idx={1}
                                id={'popover-entity' + 1}
                                key={'popover-entity' + 1}
                              />,
                              <EntityPopoverEditionModal
                                t={t}
                                entity={data && data.entity}
                                dossier={true}
                                idx={2}
                                id={'popover-entity' + 2}
                                key={'popover-entity' + 2}
                              />,
                              <VehiclePopoverEditionModal
                                t={t}
                                vehicle={data && data.vehicle}
                                dossier={true}
                                idx={3}
                                id={'popover-vehicle' + 3}
                                key={'popover-vehicle' + 3}
                              />
                            ])
                          }
                        </Col>
                      </Modal.Header>
                      {
                        !documentTypeUseName && this.state.showFileUploader
                          ? (
                            <Row>
                              <Field
                                colSm={6}
                                controlLabel={t(`${tKey}DOCUMENT_TYPE`)}
                                id="documentType"
                                name="documentType"
                                placeholder={t(`${tKey}DOCUMENT_TYPE`)}
                                component={InputSelect}
                                menuContainerStyle={{ zIndex: 999 }}
                                options={documentsTypes}
                                onInputChange={(value) => this.handleDocTypeChange(value)}
                                valueKey="id"
                                labelKey="value"
                                disabled={!!thumbnail}
                                customClass="select-document-management"
                              />
                            </Row>
                          ) : null
                      }
                      {typeView && typeView !== typeViewConstant.empty && fields.length
                        ? (<div className="form-modal-documental">
                          { typeView === typeViewConstant.entityValues
                            ? ([<Col sm={5} key="metadata" className="columna-metadatos">
                              <Row className="row-modal"><h3 style={{ fontSize: '13px' }}>{t(`${tKey}METADATA`)}</h3></Row>
                              <Row className="row-modal">
                                {
                                  fields.map((field, idx) => (
                                    <div key={idx}>
                                      <SimpleInputText
                                        colSm={4}
                                        controlLabel={field.name}
                                        disabled={true}
                                        paddingLeft={{ paddingLeft: '0px' }}
                                        id={field.code}
                                      />
                                    </div>
                                  ))
                                }
                              </Row>
                            </Col>,
                            <Col sm={2} key="copy" className="columna-boton-copiar">
                              <OverlayTrigger placement="top" overlay={copyValuesTooltip}>
                                <Button className="button-copy-values" onClick={() => this.copyMetaToEntityValues()}>
                                  <Glyphicon glyph='log-in' />
                                </Button>
                              </OverlayTrigger>
                            </Col>
                            ]) : null
                          }
                          <Col sm={typeView === typeViewConstant.entityValues ? (5) : (12)} className={typeView === typeViewConstant.entityValues ? ('columna-datos-rellenar') : ('modal-columna-centrada')}>
                            <Row className="row-modal"><h3 style={{ fontSize: '13px' }}>{t(`${tKey}DATA_TO_FILL`)}</h3></Row>
                            <Row className="row-modal">
                              {
                                fields.map((field, idx) => (
                                  <div key={idx}>
                                    {(() => {
                                      switch (field.typeField) {
                                        case typeFieldsConstant.number:
                                          return (
                                            <Field
                                              colSm={6}
                                              controlLabel={field.name}
                                              id={field.code}
                                              name={'entityValue.' + field.fieldId}
                                              component={InputNatural}
                                              disabled={(!(documentId && !validatedText)) || !permissionToEdit}
                                              textAlign="right"
                                              paddingLeft={{ paddingLeft: '0px' }}
                                            />
                                          )
                                        case typeFieldsConstant.money:
                                          return (
                                            <Field
                                              colSm={6}
                                              controlLabel={field.name}
                                              id={field.code}
                                              name={'entityValue.' + field.fieldId}
                                              component={InputMoney}
                                              disabled={(!(documentId && !validatedText)) || !permissionToEdit}
                                              textAlign="right"
                                              paddingLeft={{ paddingLeft: '0px' }}
                                            />
                                          )
                                        case typeFieldsConstant.decimal:
                                          return (
                                            <Field
                                              colSm={6}
                                              controlLabel={field.name}
                                              id={field.code}
                                              name={'entityValue.' + field.fieldId}
                                              component={InputNatural}
                                              disabled={(!(documentId && !validatedText)) || !permissionToEdit}
                                              textAlign="right"
                                              paddingLeft={{ paddingLeft: '0px' }}
                                            />
                                          )
                                        case typeFieldsConstant.date:
                                          return (
                                            <Field
                                              colSm={6}
                                              controlLabel={field.name}
                                              id={field.code}
                                              name={'entityValue.' + field.fieldId}
                                              component={InputDatePicker}
                                              validate={(value, allValues, props) => {
                                                let result = validateDate(value, props)
                                                if (result === Object(result) && field.customValidations) {
                                                  result = customValidations(field.customValidations, value, props)
                                                }
                                                return result === Object(result) ? undefined : result
                                              }}
                                              disabled={(!(documentId && !validatedText)) || !permissionToEdit}
                                              paddingLeft={{ paddingLeft: '0px' }}
                                            />
                                          )
                                        case typeFieldsConstant.dateTime:
                                          return (
                                            <Field
                                              colSm={6}
                                              controlLabel={field.name}
                                              id={field.code}
                                              name={'entityValue.' + field.fieldId}
                                              component={InputDateTimePicker}
                                              disabled={(!(documentId && !validatedText)) || !permissionToEdit}
                                              paddingLeft={{ paddingLeft: '0px' }}
                                            />
                                          )
                                        case typeFieldsConstant.list:
                                          return (
                                            <Field
                                              colSm={6}
                                              controlLabel={field.name}
                                              id={field.code}
                                              name={'entityValue.' + field.fieldId}
                                              placeholder={field.name}
                                              component={InputSelect}
                                              options={field.options}
                                              valueKey="id"
                                              labelKey="value"
                                              disabled={(!(documentId && !validatedText)) || !permissionToEdit}
                                              paddingLeft={{ paddingLeft: '0px' }}
                                              key="select-document"
                                            />)
                                        case typeFieldsConstant.licensePlate:
                                          return (
                                            <Field
                                              colSm={6}
                                              controlLabel={field.name}
                                              id={field.code}
                                              name={'entityValue.' + field.fieldId}
                                              placeholder={field.name}
                                              component={InputText}
                                              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                                              disabled={(!(documentId && !validatedText)) || !permissionToEdit}
                                              textAlign="left"
                                              paddingLeft={{ paddingLeft: '0px' }}
                                            />)
                                        case typeFieldsConstant.vinChassis:
                                          return (
                                            <Field
                                              colSm={6}
                                              maxLength={17}
                                              controlLabel={field.name}
                                              id={field.code}
                                              name={'entityValue.' + field.fieldId}
                                              placeholder={field.name}
                                              component={InputText}
                                              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                                              disabled={(!(documentId && !validatedText)) || !permissionToEdit}
                                              textAlign="left"
                                              paddingLeft={{ paddingLeft: '0px' }}
                                            />
                                          )
                                        case typeFieldsConstant.licensePlateValidation:
                                          return ([
                                            <Col className='customCheckCol' key="col">
                                              <Field
                                                customClass="disable-license-plate-checkbox"
                                                controlLabel={field.name}
                                                id={field.code}
                                                name={'entityValue.' + field.fieldId}
                                                key={'entityValue.' + field.fieldId}
                                                placeholder={field.name}
                                                component={InputCheckBox}
                                                inline
                                                disabled= {(!(documentId && !validatedText) || field.isReadOnly) || !permissionToEdit || documentId === null || (!buttonValidate && !buttonSave)}
                                              />
                                              <OverlayTrigger overlay={editTooltip} colSm={1}>
                                                <Glyphicon className="disable-license-plate-icon" glyph="info-sign"/>
                                              </OverlayTrigger>
                                            </Col>,
                                            <div key="clearfix_2" className="clearfix" />
                                          ])
                                        default:
                                          return (
                                            <Field
                                              colSm={6}
                                              controlLabel={field.name}
                                              id={field.code}
                                              name={'entityValue.' + field.fieldId}
                                              placeholder={field.name}
                                              component={InputText}
                                              disabled={(!(documentId && !validatedText)) || !permissionToEdit}
                                              textAlign="left"
                                              paddingLeft={{ paddingLeft: '0px' }}
                                            />
                                          )
                                      }
                                    })()}
                                  </div>
                                ))
                              }
                            </Row>
                          </Col>
                        </div>) : null
                      }
                      {this.props.docResult.documentStatusId !== backEndIdDocumentStatus.REJECTED && commentDocument && (
                        <Col sm={12}>
                          <Row className="row-modal"><h3 style={{ fontSize: '13px' }}>{t(`${tKey}TEXT`)}</h3></Row>
                          <Row className="row-modal">
                            <Field
                              id='comment'
                              name='comment'
                              componentClass="textarea"
                              colSm={12}
                              placeholder={t(`${tKey}TEXT`)}
                              component={InputText}
                              customClass="comment-reject-modal-textarea"
                              textAlign="left"
                              paddingLeft={{ paddingLeft: '0px' }}
                              disabled={(!(documentId && !validatedText))}
                            />
                          </Row>
                        </Col>
                      )}
                      {
                        this.props.docResult.documentStatusId === backEndIdDocumentStatus.REJECTED && comment && (
                          <Row className="reject-reason-row">
                            <Col sm={12}>
                              <SimpleInputText
                                controlLabel={t(`${tKey}REJECT_REASON`)}
                                id='comment'
                                name='comment'
                                value={comment}
                                disabled={true}
                                componentClass="textarea"
                              />
                            </Col>
                          </Row>
                        )
                      }
                    </Col>
                    { /* FIN FORMULARIO */}
                  </Row>
                </Col>
              </Row>
              { /* CARRUSEL IMAGENES + DROPZONE */}
              {
                (!this.state.showFileUploader && documentId) && (
                  <div className={this.state.showDocumentCarrusel ? 'carrusel-imagenes-modal toggleContent-open' : ' carrusel-imagenes-modal toggleContent-closed'} onMouseLeave={() => this.fileCarruselMouseLeave()}>
                    <OverlayTrigger placement="bottom" overlay={scrollToPreviusFileTooltip}>
                      <Button className="previus" onClick={() => this.scrollToFile(-1)}>
                        <Glyphicon glyph="chevron-left" />
                      </Button>
                    </OverlayTrigger>
                    <div className="horizontal-scroll-wrapper squares" ref="horizontalScrollWrapper" style={{ maxHeight: anchoPantalla }}>
                      {numPages && (
                        <div key={'0.1'} className="img-carrusel" onClick={() => this.selectDocument(0, 1)}>
                          <ImgPage
                            src={`${settings.url}documentHistorical/${documentId}/preview?token=${token}&pageId=${1}&fecha=${this.state.parametroFecha}`}
                            active={(this.props.activeSheet === 0) ? 'img-active' : ''}
                          />
                        </div>
                      )}
                      {(sheets.length > 0 || this.state.showSheets) && (
                        sheets.map((sheet, idx) => {
                          return (
                            <div key={sheet.id + '.1'} className="img-carrusel" onClick={() => this.selectDocument(sheet.id, 1)}>
                              <ImgPage
                                src={`${settings.url}documentHistorical/${documentId}/sheet/${sheet.id}/preview?token=${token}&pageId=${1}&fecha=${this.state.parametroFecha}`}
                                active={(this.props.activeSheet === sheet.id) ? 'img-active' : ''}
                              />
                            </div>
                          )
                        })
                      )}
                    </div>
                    {
                      buttonNewSheet && (
                        <div className="modal-anadir-hoja">
                          <div className={'cuadro-anadir-hoja dropzone-box-wrapper-modal' + (this.state.hasInvalidFile ? ' has-error' : '')}>
                            <DropZone
                              id="dropZone"
                              multiple={false}
                              onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles, organizedUnitId)}
                              accept={acceptFilesDropzone}
                              maxSize={maxSizeFilesDropzone}
                              style={{ height: '100%' }}
                              className={this.state.dropzonePanelActive ? 'active' : ''}
                              onDragEnter={() => this.onDragEnter(true)}
                              onDragLeave={() => this.onDragLeave(true)}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="text-wrapper" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <i className="ico-cloud" />
                                  <p>
                                    {t(`${tKey}DROP_FILES_FULL`)}
                                    <br />
                                    {this.state.hasInvalidFile && (
                                      <span className="help-block text-center">
                                        {t(`${tKey}VALIDATION.FILE`)}
                                      </span>
                                    )}
                                  </p>
                                </div>)}
                            </DropZone>
                          </div>
                        </div>
                      )
                    }
                    <OverlayTrigger placement="bottom" overlay={scrollToNextFileTooltip}>
                      <Button className="next" onClick={() => this.scrollToFile(1)}>
                        <Glyphicon glyph="chevron-right" />
                      </Button>
                    </OverlayTrigger>
                  </div>
                )
              }
              { /* FIN CARRUSEL IMAGENES + DROPZONE */}

              { /* BARRA INFO DOCUMENTO */}
              {
                !this.state.showFileUploader && (
                  <div className="barra-detalle-documento">
                    <OverlayTrigger placement="right" overlay={desplegarTooltip}>
                      <Button onClick={() => this.toggleDocumentCarrusel()}>
                        <Glyphicon glyph={this.state.showDocumentCarrusel ? 'chevron-down' : 'chevron-up'} />
                      </Button>
                    </OverlayTrigger>
                    <span className="barra-detalle-documento-info">
                      <span className="numero-hoja">{t(`${tKey}SHEETS_TABLE.FILE`) + ' ' + (activeSheet === 0 ? (1) : (numeroHoja + 1)) + ' ' + t(`${tKey}SHEETS_TABLE.DE`) + ' ' + (sheets.length + 1)}</span>
                      <span className="fecha-hoja">
                        <span className="titulo-fecha-hoja">{t(`${tKey}SHEETS_TABLE.CREATION_DATE`)}</span>
                        <br />
                        <span>{activeSheet === 0 ? (incorporationDate) : (fecha)}</span>
                      </span>
                    </span>
                    {
                      !readOnlyFields && buttonAddNewVersion && permissionToEdit && (
                        <OverlayTrigger placement="top" overlay={uploadNewVersionTooltip}>
                          <Button className="button-cloud" onClick={this.uploadNewVersion.bind(this)}>
                            <i className="ico-cloud" />
                          </Button>
                        </OverlayTrigger>
                      )
                    }
                    {
                      !readOnlyFields && buttonNewSheet && permissionToEdit && (
                        <DropZone
                          multiple={false}
                          onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles, organizedUnitId)}
                          style={{ border: 'none', display: 'inline-block', height: '100%' }}
                          accept={acceptFilesDropzone}
                          maxSize={maxSizeFilesDropzone}
                          className={this.state.dropzonePanelActive ? 'active' : ''}
                          onDragEnter={() => this.onDragEnter(true)}
                          onDragLeave={() => this.onDragLeave(true)}
                        >
                          {() => (
                            <OverlayTrigger placement="top" overlay={uploadNewSheetTooltip}>
                              <Button className="button-cloud" onClick={() => this.toggleCloud()}>
                                <i className="ico-document-plus" />
                              </Button>
                            </OverlayTrigger>
                          )}
                        </DropZone>
                      )
                    }
                    {/*
                <OverlayTrigger placement="top" overlay={viewSheetTooltip}>
                  <Button className="button-search" onClick={(e) => activeSheet === 0 ? (this.toggleFullScreenDocumentModal(e)) : (this.toggleFullScreenDocumentSheetModal(e, activeSheet))}>
                    <i className="ico-magnifying"/>
                  </Button>
                </OverlayTrigger>
              */}
                    {
                      sheets.length > 0 && (
                        <OverlayTrigger placement="top" overlay={deleteSheetTooltip}>
                          <Button className="button-clear" onClick={(e) => this.deleteDocumentSheet(e, activeSheet, organizedUnitId)}>
                            <i className="ico-trash" />
                          </Button>
                        </OverlayTrigger>
                      )
                    }
                  </div>
                )
              }
              { /* FIN BARRA INFO DOCUMENTO */}

            </Modal.Body>
            { /* FIN MODAL BODY */}
          </Form>
        </Col>
      </Modal>
    )
  }
}

function validateFunction ({ entityValue = {} }, props) {
  const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.VALIDATION.'
  const error = { entityValue: {} }
  const { docResult: { fields = [] }, t } = props
  let aux
  fields.forEach(field => {
    if (field.customValidations && field.customValidations.indexOf('regEx-') !== -1) {
      aux = validateRegex(field.customValidations, field.name, entityValue[field.fieldId.toString()], props)
      if (aux !== Object(aux)) {
        error.entityValue[field.fieldId.toString()] = aux
      }
    }
    if (field.isMandatory && !entityValue[field.fieldId.toString()]) error.entityValue[field.fieldId.toString()] = t(`${tKey}MANDATORY`)
  })
  return error
}

var decoratedComponent = TrackingContainer(DocumentEdition, false)

export default reduxForm({
  form: 'AddOrEditHistoricalDocumentForm',
  validate: validateFunction,
  destroyOnUnmount: false
})(decoratedComponent)

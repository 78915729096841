import React, { PureComponent } from 'react'
import { reduxForm, Field, reset } from 'redux-form'
import { Modal, Row, Col, Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import InputText from '../../../../commons/form/InputText'
import InputCheckBox from '../../../../commons/form/InputCheckBox'
import { validateLicensePlate as validateLicensePlateFunciton } from '../../../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../../../util/normalizeFunctions'

class ResponsibleDeclarationDocModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      disabledValidateLicensePlate: false
    }
    this.validateLicensePlate = this.validateLicensePlate.bind(this)
    this.required = this.required.bind(this)
  }

  required (value) {
    if (value || value === 0) {
      return undefined
    } else {
      return this.props.t('GLOBAL.FORMS.REQUIRED')
    }
  }

  submitResponsibleDeclarationDocModal (values) {
    var valuespdf = this.addPdfCodes(values)
    if (this.props.printAction) {
      this.props.actions.printDocumentDynamicFromTemplate(this.props.documentTypeUseId, this.props.dossierId, valuespdf, this.props.checkListId)
    } else {
      this.props.actions.createDocumentDynamicFromTemplate(this.props.documentTypeUseId, this.props.dossierId, valuespdf, this.props.checkListId, this.props.organizedUnitId)
    }
    this.closeModalActions()
  }

  addPdfCodes (originalValues) {
    let values = JSON.parse(JSON.stringify(originalValues))

    let valuespdf = {}

    values.licensePlate ? valuespdf.licensePlate = { code: 'Matrícula del vehículo afectado', value: values.licensePlate } : null

    return valuespdf
  }

  closeModalActions () {
    this.props.dispatch(reset('responsibleDeclarationDocModal'))
    this.props.actions.closeResponsibleDeclarationDocModal()
  }

  normalizeLicensePlate (value) {
    multipleNormalize(value, [toUpperCase, trimAll])
  }

  changeBoolLicensePlate (value) {
    this.setState({ disabledValidateLicensePlate: value })
  }

  validateLicensePlate (value) {
    return validateLicensePlateFunciton(value, false, this.props.t('GLOBAL.FORMS.INVALID_STRING'))
  }

  render () {
    const {
      t, handleSubmit,
      showModal
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.TEMPLATE_DATA_MODAL.INTERNAL_MANAGEMENT_DOC.'

    const editTooltip = (<Tooltip id="editTooltip">{t('DOSSIER_COMPONENTS.VEHICLE.TOOLTIP_VALIDATE_LICENSE_PLATE')}</Tooltip>)

    if (!showModal) {
      return null
    }

    return (
      <Modal show={showModal} bsSize="large" className="role-modal languages-modal modal-templateDataModal" onHide={this.closeModalActions.bind(this)} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitResponsibleDeclarationDocModal.bind(this))}>
          <Modal.Header closeButton onHide={this.closeModalActions.bind(this)}>
            <Modal.Title>{t(`${tKey}TITLE_RESPONSIBLE_DECLARATION`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body >

            <Row id="responsibleDeclarationDocModal_DamagedVehicle" name="responsibleDeclarationDocModal_DamagedVehicle" className="inputs-wrapper-templateDataModal flexRow">
              <Col sm={12} className="custom-width-col">
                <h2>{t(`${tKey}DAMAGED_VEHICLE`)}</h2>
              </Col>
              <Field
                id="responsibleDeclarationDocModal_licensePlate"
                name='licensePlate'
                colSm={12}
                placeholder={t(`${tKey}LICENSEPLATE`)}
                controlLabel={t(`${tKey}LICENSEPLATE`)}
                component={InputText}
                maxLength={50}
                validate={[this.required]}
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              />
              <Field
                colSm={4}
                id="responsibleDeclarationDocModal_licensePlateFormat"
                name='licensePlateFormat'
                placeholder={t(`${tKey}LICENSEPLATE_FORMAT`)}
                controlLabel={t(`${tKey}LICENSEPLATE_FORMAT`)}
                component={InputCheckBox}
                onChange= {(value) => (this.changeBoolLicensePlate(value))}
              />
              <OverlayTrigger placement="right" overlay={editTooltip}
                colSm={1}>
                <Glyphicon glyph="info-sign"/>
              </OverlayTrigger>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="buttons-wrapper-templateDataModal">
              <Button id="responsibleDeclarationDocModal-submit-button" type="submit" className="btn-standard" >
                {t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.GROUP_DOCUMENTS_CREATE_MODAL.ACCEPT')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
  if (!values.licensePlate) {
    errors.licensePlate = props.t('GLOBAL.FORMS.REQUIRED')
  } else {
    if (values.licensePlateFormat !== true) {
      errors.licensePlate = validateLicensePlateFunciton(values.licensePlate, false, props.t('GLOBAL.FORMS.INVALID_STRING'))
    }
  }

  return errors
}

export default reduxForm({
  form: 'responsibleDeclarationDocModal',
  validate,
  destroyOnUnmount: true
})(ResponsibleDeclarationDocModal)

export const dossierTypes = {
  sales: 1,
  purchase: 2,
  campaign: 3,
  paperwork: 4,
  damageClaim: 5,
  closureReport: 6,
  registrationDelivery: 7,
  service: 8,
  stock: 9
}

export const dossierSubTypes = {
  sale_vn: 1,
  sale_vo: 2,
  purchase_vn: 3,
  purchase_vo: 4,
  campaign: 5,
  specialHelp: 6,
  paperwork: 7,
  registrationDelivery: 11,
  service: 12,
  service_pap: 13,
  deliveryPreparation: 14,
  stock: 16
}

export const dossierExtraTypeId = {
  accesories: 1,
  optionals: 2,
  noRecoverableCosts: 3
}

export const urgencyTypesId = {
  todas: 0,
  ontime: 1,
  high: 2,
  medium: 3,
  low: 4
}

export const urgencyTypesIdToUrgencyTabsId = {
  0: 0,
  [urgencyTypesId.high]: 1,
  [urgencyTypesId.medium]: 2,
  [urgencyTypesId.low]: 3
}

export const urgencyTabsIdToUrgencyTypesId = {
  0: 0,
  1: [urgencyTypesId.high],
  2: [urgencyTypesId.medium],
  3: [urgencyTypesId.low]
}

export const urgencyTypesClass = {
  4: 'green',
  3: 'yellow',
  2: 'red'
}

export const urgencyTypesName = {
  4: 'LOW',
  3: 'MEDIUM',
  2: 'HIGH'
}

export const entityTypesId = {
  privateEntity: 1,
  businessEntity: 2,
  recurrentEntity: 3,
  claimClient: 4,
  recurrentService: 5
}

export const recurrentEntitySubTypes = {
  maker: 1,
  distributor: 2,
  renting: 3,
  trading: 4
}

export const paymentSignalStatus = {
  down_payment: 1,
  instalment_down_payment: 2,
  finished_payment: 3
}

export const documentTypeUse = {
  down_payment: 3,
  instalment_down_payment: 5,
  dni: 21,
  charge: 1,
  command: 79,
  damage: 127,
  factory_invoice: 216,
  chasisPic: 118,
  damageGrl: 119,
  damageDetail: 120,
  otherPic: 123,
  purchaseMandate: 394,
  stockPhoto: 405
}

export const permissions = {
  hidden: 0,
  readOnly: 1,
  editable: 2
}

export const dateType = {
  standard: 'DD/MM/YYYY'
}

export const buttonsPermissionCodes = {
  hotfolder_load: 'hotfolder_load',
  landing_request_documents: 'landing_request_documents',
  landing_cancel_request_documents: 'landing_cancel_request_documents'
}

export const conditionNoDocumentalTypes = {
  other: 1,
  data: 2
}

export const conditionActionConst = {
  noAction: 0
}

export const dossierStageStoredAndCancelled = [
  110,
  111,
  112,
  113,
  114,
  115,
  116,
  117,
  100,
  101,
  102,
  103,
  104,
  105,
  106,
  107,
  145,
  150,
  151,
  152,
  163,
  164,
  157,
  158,
  170,
  171,
  181,
  182
]

export const errorTagSufixSeparator = '#$#'

export const conditionTypes = {
  conditionDocumental: 1,
  conditionNoDocumental: 2
}

export const showFieldType = {
  saleField: 1,
  purchaseField: 2,
  paperworkField: 3,
  salePaperworkField: 4,
  additionalInfoField: 5,
  tuneUpDestination: 6,
  marginField: 7,
  paymentMethodField: 8,
  paperworkPurchaseType: 9,
  maritalStatus: 10,
  brandAndFuel: 11
}

export const functionToPassTypeView = {
  total: 1,
  limited: 2
}

export const dashboardSalesmanColumnDossierSubType = [
  1,
  2
]

export const approvalRoleCode = {
  approveSalesman: 1,
  approveSalesManager: 2,
  approveSalesLogistics: 3,
  approveSalesAdmin: 4,
  approveManager: 5,
  approveSalesFleets: 6,
  approveSalesCommercialDirector: 7,
  approveSalesController: 8,
  approveLicensePlateFormat: 10,
  approveServiceLogistics: 11,
  approveServiceCompletePap: 12,
  approveSalesManagerVO: 13,
  approveBPA: 14,
  approveCFO: 15,
  approveAdministrationManager: 16,
  agentOperation: 17,
  approveNationalVO: 18,
  approveExportControl: 19

}

export const backEndIdDocumentStatus = {
  REJECTED: 4,
  PENDING: 2,
  INCORPORATED: 3,
  VALIDATED: 5,
  REMOVED: 1,
  INCORPORATION_PENDING: 2,
  EXPIRED: -1
}

export const brands = {
  AUDI: 1,
  SEAT: 2,
  VW: 3
}

export const dashboardUploadExcel = {
  FLEET_QUADIS: 1
}

export const homologationClassification = {
  TIPO: 1,
  REFORMA: 2
}

export const additionalInfoIds = {
  FLEETS_VN: 11,
  FLEETS_VO: 12
}

export const clientInformation = {
  VGR: 'vgr',
  DAG: 'da',
  ALL: 'all',
  FERPER: 'ferper',
  TOYOTA: 'toyo',
  QUADIS: 'quadis',
  PORSCHE: 'porsche',
  BERGE: 'bal',
  DEMO: 'demo',
  KIA: 'kia',
  CONQ: 'conq',
  CANA: 'cana',
  BUSS: 'buss',
  PENSKE: 'penske',
  EUROMOTORS: 'eur',
  MCONDE: 'mconde'
}

export const signTypes = {
  BIOMETRIC: 1,
  DIGITAL: 2,
  HANDWRITING: 3,
  SIGNATUREURL: 4,
  SIGNATURESMS: 5,
  SIGNATUREEMAIL: 6,
  IMAGE: 7
}

export const typeTravelCombo = {
  travelDisabledbyCode: 1,
  travelDisabledbyCpLicenseDate: 2
}

export const documentTypeIds = {
  damage: 127
}

export const styleTasks = {
  Bold: 'Bold',
  BoldBlue: 'BoldBlue',
  BoldRed: 'BoldRed'
}

export const styleTasksIds = {
  Normal: 1,
  Bold: 2,
  BoldBlue: 3,
  BoldRed: 4
}

export const paymentMethodCampaign = {
  payment: 1,
  invoice: 2
}

export const campaignStages = {
  verificationCAM: 72,
  approvalCAM: 74,
  verificationAY: 73,
  approvalAY: 75
}

export const icoPosition = {
  LEFT: 1,
  RIGHT: 2,
  CUSTOM: 3
}

export const iemValues = {
  other: 10
}

export const reportCode = {
  default: 'default',
  powerbi: 'powerbi'
}

export const paperworkTypeId = {
  registration: 2
}

export const clientsForContactAndEntityChildMandatoryValidation = [
  clientInformation.VGR,
  clientInformation.DAG,
  clientInformation.FERPER,
  clientInformation.PORSCHE,
  clientInformation.BERGE
]

export const signStatus = {
  Available: -1,
  Pending: 0,
  Signed: 1,
  Cancelled: 2,
  Rejected: 3
}

export const typeFrontEntities = {
  ENTITY: 1,
  VEHICLE: 2,
  DOSSIER: 3,
  SIGN: 4
}

export const operationTypeCodes = {
  FLEETS: 'fleets'
}

export const loadTemplatesNames = {
  orgUnit: 'OU_Template_Load_Massive.xlsx',
  procAg: 'ProcAg_Template_Load_Massive.xlsx',
  users: 'User_Template_Load_Massive.xlsx',
  recEntity: 'RecEntity_Template_Load_Massive.xlsx',
  fleetDossiers: 'Fleet_Dossier_Load_Massive.xlsx'
}

export const templateTypes = {
  DELIVERY_ALB: 1,
  RECEIPT_ALB: 2,
  COMMAND_PERSON: 3,
  COMMAND_COMPANY: 4,
  DOCUMENTARY_LIST: 5,
  PAYMENT_RECEIPT: 6,
  REFUND_RECEIPT: 7,
  RECEIPT: 8,
  PURCH_S_CONTRACT: 9,
  UAC_NOT_SAME_CLIENT: 10,
  P_SALE_CONTRACT: 11,
  GDPR: 12,
  AUTOWARRANTY: 13,
  CHECKLISTVO: 14,
  SUMMARY_SALES: 15,
  OWNERSHIP_CHANGE: 16,
  PURCHASE_OWNERSHIP_CHANGE: 17,
  SANITATION_CERTIFICATE: 18,
  KM_CERTIFICATE: 19,
  RETURN_WARRANTY_CERTIFICATE: 20,
  PREPARATION_CERTIFICATE: 23,
  HISTORY_VERIFICATION_CERTIFICATE: 24
}

export const massiveDownloadStates = {
  PENDING: 1,
  PROCESSING: 2,
  READY: 3,
  ERROR: 4,
  EXPIRED: -1
}

export const months = {
  1: 31,
  2: 29,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31
}

export const genericRfc = {
  privateRfc: 'XAXX010101000',
  foreignRfc: 'XEXX010101000'
}

export const associatedCostsCodes = {
  RAPPEL_TO_PAY: 'rappel_to_pay'
}

export const associatedCostsColumnKeys = {
  COST: 'cost'
}

export const documentTypeView = {
  WITHOUT_ALL: 1,
  ONLY_DATA: 2,
  META_AND_DATA: 3
}

export const dateToApply = {
  REQUIRED_DATE: 1,
  APPLICATION_DATE: 2
}

export const suppliedCodes = {
  MAINTENANCE: 'MAN',
  INSURANCE: 'SEG',
  OTHER: 'OTR'
}

export const validationTypes = {
  INDIVIDUAL_IDENTIFIER: 'INDIVIDUAL_IDENTIFIER',
  COMPANY_IDENTIFIER: 'COMPANY_IDENTIFIER',
  FREELANCE_IDENTIFIER: 'FREELANCE_IDENTIFIER',
  RECURRENT_IDENTIFIER: 'RECURRENT_IDENTIFIER'
}

export const auditDetailsType = {
  NONE: 0,
  GENERIC: 1,
  GROUPED_DOCUMENTS: 2
}

export const saleTypes = {
  PRIVATE: 1,
  SALE: 120,
  SALE_CREDIT: 119
}

import templateDataModalActionTypes from '../../constants/actions/modals/templateDataModal'

export function initialState () {
  return {
    internalManagementDocModal: {
      showModal: false,
      dossierId: null,
      checkListId: null,
      printAction: null,
      organizedUnitId: null
    },
    responsibleDeclarationDocModal: {
      showModal: false,
      dossierId: null,
      checkListId: null,
      printAction: null,
      organizedUnitId: null
    }
  }
}

function openInternalManagementDocModal (state, { dossierId, checkListId, action, organizedUnitId }) {
  return {
    ...state,
    internalManagementDocModal: {
      showModal: true,
      dossierId,
      checkListId,
      printAction: action,
      organizedUnitId
    }
  }
}

function closeInternalManagementDocModal (state) {
  return {
    ...state,
    internalManagementDocModal: {
      ...initialState().internalManagementDocModal
    }

  }
}

function openResponsibleDeclarationDocModal (state, { dossierId, checkListId, action, organizedUnitId }) {
  return {
    ...state,
    responsibleDeclarationDocModal: {
      showModal: true,
      dossierId,
      checkListId,
      printAction: action,
      organizedUnitId
    }
  }
}

function closeResponsibleDeclarationDocModal (state) {
  return {
    ...state,
    responsibleDeclarationDocModal: {
      ...initialState().responsibleDeclarationDocModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case templateDataModalActionTypes.OPEN_INTERNAL_MANAGEMENT_DOC_MODAL:
      return openInternalManagementDocModal(state, action)
    case templateDataModalActionTypes.CLOSE_INTERNAL_MANAGEMENT_DOC_MODAL:
      return closeInternalManagementDocModal(state, action)
    case templateDataModalActionTypes.OPEN_RESPONSIBLE_DECLARATION_DOC_MODAL:
      return openResponsibleDeclarationDocModal(state, action)
    case templateDataModalActionTypes.CLOSE_RESPONSIBLE_DECLARATION_DOC_MODAL:
      return closeResponsibleDeclarationDocModal(state, action)
    default:
      return state
  }
}

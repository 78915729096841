import React, { PureComponent } from 'react'
import { reduxForm, Field, reset } from 'redux-form'
import { Modal, Row, Col, Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import InputText from '../../../../commons/form/InputText'
import InputCheckBox from '../../../../commons/form/InputCheckBox'
import InputCheckboxSlider from '../../../../commons/form/InputCheckboxSlider'
import { validateVinChassis, validateLicensePlate as validateLicensePlateFunciton } from '../../../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../../../util/normalizeFunctions'

class InternalManagementDocModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      disabledValidateLicensePlate: false
    }
    this.validateLicensePlate = this.validateLicensePlate.bind(this)
    this.vinChassisValidation = this.vinChassisValidation.bind(this)
  }

  submitInternalManagementDocModal (values) {
    var valuespdf = this.addPdfCodes(values)
    if (this.props.printAction) {
      this.props.actions.printDocumentDynamicFromTemplate(this.props.documentTypeUseId, this.props.dossierId, valuespdf, this.props.checkListId)
    } else {
      this.props.actions.createDocumentDynamicFromTemplate(this.props.documentTypeUseId, this.props.dossierId, valuespdf, this.props.checkListId, this.props.organizedUnitId)
    }
    this.closeModalActions()
  }

  addPdfCodes (originalValues) {
    let values = JSON.parse(JSON.stringify(originalValues))

    let valuespdf = {}

    values.category ? valuespdf.category = { code: 'Categoría Turismos M1Furgonetas N1', value: values.category } : null
    values.position ? valuespdf.position = { code: 'Cargo', value: values.position } : null
    values.damagedVehicleCategory ? valuespdf.damagedVehicleCategory = { code: 'Categoría Turismos M1Furgonetas N1_2', value: values.damagedVehicleCategory } : null
    values.brand ? valuespdf.brand = { code: 'Marca_2', value: values.brand } : null
    values.model ? valuespdf.model = { code: 'Modelo_2', value: values.model } : null
    values.licensePlate ? valuespdf.licensePlate = { code: 'Matrícula_2', value: values.licensePlate } : null
    values.vinChassis ? valuespdf.vinChassis = { code: 'Bastidor_2', value: values.vinChassis } : null
    values.numDossier ? valuespdf.numDossier = { code: 'Núm expediente', value: values.numDossier } : null

    valuespdf.declaration = { code: 'SíNoRow1', value: values.declaration ? this.props.t('DOSSIER_COMPONENTS.TOTALS.YES') : this.props.t('DOSSIER_COMPONENTS.TOTALS.NO') }
    valuespdf.autonomous = { code: 'SíNoRow2', value: values.autonomous ? this.props.t('DOSSIER_COMPONENTS.TOTALS.YES') : this.props.t('DOSSIER_COMPONENTS.TOTALS.NO') }
    valuespdf.fisicalEntity = { code: 'SíNoRow3', value: values.fisicalEntity ? this.props.t('DOSSIER_COMPONENTS.TOTALS.YES') : this.props.t('DOSSIER_COMPONENTS.TOTALS.NO') }
    valuespdf.privateBusinessCorporations = { code: 'SíNoRow4', value: values.privateBusinessCorporations ? this.props.t('DOSSIER_COMPONENTS.TOTALS.YES') : this.props.t('DOSSIER_COMPONENTS.TOTALS.NO') }
    valuespdf.asociations = { code: 'SíNoRow5', value: values.asociations ? this.props.t('DOSSIER_COMPONENTS.TOTALS.YES') : this.props.t('DOSSIER_COMPONENTS.TOTALS.NO') }
    valuespdf.civilSociety = { code: 'SíNoRow6', value: values.civilSociety ? this.props.t('DOSSIER_COMPONENTS.TOTALS.YES') : this.props.t('DOSSIER_COMPONENTS.TOTALS.NO') }
    valuespdf.autonomousHelpCertificate = { code: 'SíNoRow7', value: values.autonomousHelpCertificate ? this.props.t('DOSSIER_COMPONENTS.TOTALS.YES') : this.props.t('DOSSIER_COMPONENTS.TOTALS.NO') }
    valuespdf.reducedMobility = { code: 'SíNoRow8', value: values.reducedMobility ? this.props.t('DOSSIER_COMPONENTS.TOTALS.YES') : this.props.t('DOSSIER_COMPONENTS.TOTALS.NO') }
    valuespdf.certificateAccountHolder = { code: 'SíNoRow9', value: values.certificateAccountHolder ? this.props.t('DOSSIER_COMPONENTS.TOTALS.YES') : this.props.t('DOSSIER_COMPONENTS.TOTALS.NO') }

    return valuespdf
  }

  closeModalActions () {
    this.props.dispatch(reset('internalManagementDocModal'))
    this.props.actions.closeInternalManagementDocModal()
  }

  normalizeLicensePlate (value) {
    multipleNormalize(value, [toUpperCase, trimAll])
  }

  changeBoolLicensePlate (value) {
    this.setState({ disabledValidateLicensePlate: value })
  }

  validateLicensePlate (value) {
    return validateLicensePlateFunciton(value, false, this.props.t('GLOBAL.FORMS.INVALID_STRING'))
  }

  vinChassisValidation (value, allValues, props) {
    const result = validateVinChassis(value, props)
    return result === Object(result) ? undefined : result
  }

  render () {
    const {
      t, handleSubmit,
      showModal
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.TEMPLATE_DATA_MODAL.INTERNAL_MANAGEMENT_DOC.'

    const editTooltip = (<Tooltip id="editTooltip">{t('DOSSIER_COMPONENTS.VEHICLE.TOOLTIP_VALIDATE_LICENSE_PLATE')}</Tooltip>)

    if (!showModal) {
      return null
    }

    return (
      <Modal show={showModal} bsSize="large" className="role-modal languages-modal ReservationContractModal modal-templateDataModal" onHide={this.closeModalActions.bind(this)} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitInternalManagementDocModal.bind(this))}>
          <Modal.Header closeButton onHide={this.closeModalActions.bind(this)}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <Row id="internalManagementDocModal_category" name='internalManagementDocModal_category' className="inputs-wrapper-templateDataModal flexRow">
              <Col sm={12} className="custom-width-col">
                <h2>{t(`${tKey}VEHICLE_ACQUIRED`)}</h2>
              </Col>
              <Field
                id="internalManagementDocModal_category"
                name='category'
                colSm={12}
                placeholder={t(`${tKey}CATEGORY`)}
                controlLabel={t(`${tKey}CATEGORY`)}
                component={InputText}
                maxLength={15}
              />
            </Row>
            <Row id="internalManagementDocModal_LegalEntity" name="internalManagementDocModal_LegalEntity" className="inputs-wrapper-templateDataModal flexRow">
              <Col sm={12} className="custom-width-col">
                <h2>{t(`${tKey}LEGAL_ENTITY`)}</h2>
              </Col>
              <Field
                id="internalManagementDocModal_position"
                name='position'
                colSm={12}
                placeholder={t(`${tKey}POSITION`)}
                controlLabel={t(`${tKey}POSITION`)}
                component={InputText}
                maxLength={50}
              />
            </Row>
            <Row id="internalManagementDocModal_DamagedVehicle" name="internalManagementDocModal_DamagedVehicle" className="inputs-wrapper-templateDataModal flexRow">
              <Col sm={12} className="custom-width-col">
                <h2>{t(`${tKey}DAMAGED_VEHICLE`)}</h2>
              </Col>
              <Field
                id="internalManagementDocModal_damagedVehicleCategory"
                name='damagedVehicleCategory'
                colSm={6}
                placeholder={t(`${tKey}CATEGORY`)}
                controlLabel={t(`${tKey}CATEGORY`)}
                component={InputText}
                maxLength={15}
              />
              <Field
                id="internalManagementDocModal_brand"
                name='brand'
                colSm={6}
                placeholder={t(`${tKey}BRAND`)}
                controlLabel={t(`${tKey}BRAND`)}
                component={InputText}
                maxLength={50}
              />
              <Field
                id="internalManagementDocModal_model"
                name='model'
                colSm={6}
                placeholder={t(`${tKey}MODEL`)}
                controlLabel={t(`${tKey}MODEL`)}
                component={InputText}
                maxLength={50}
              />
              <Field
                id="internalManagementDocModal_licensePlate"
                name='licensePlate'
                colSm={6}
                placeholder={t(`${tKey}LICENSEPLATE`)}
                controlLabel={t(`${tKey}LICENSEPLATE`)}
                component={InputText}
                maxLength={50}
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              />
              <Field
                id="internalManagementDocModal_vinChassis"
                name='vinChassis'
                colSm={6}
                placeholder={t(`${tKey}VINCHASSIS`)}
                controlLabel={t(`${tKey}VINCHASSIS`)}
                component={InputText}
                maxLength={17}
                minLength={17}
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                validate={[this.vinChassisValidation]}
              />
              <Field
                colSm={5}
                id="internalManagementDocModal_licensePlateFormat"
                name='licensePlateFormat'
                placeholder={t(`${tKey}LICENSEPLATE_FORMAT`)}
                controlLabel={t(`${tKey}LICENSEPLATE_FORMAT`)}
                component={InputCheckBox}
                onChange= {(value) => (this.changeBoolLicensePlate(value))}
              />
              <OverlayTrigger placement="right" overlay={editTooltip}
                colSm={1}>
                <Glyphicon glyph="info-sign"/>
              </OverlayTrigger>
            </Row>
            <Row id="internalManagementDocModal_reclamation" name="internalManagementDocModal_reclamation" className="inputs-wrapper-templateDataModal flexRow">
              <Col sm={12} className="custom-width-col">
                <h2>{t(`${tKey}RECLAMATION`)}</h2>
              </Col>
              <Field
                id="internalManagementDocModal_numDossier"
                name='numDossier'
                colSm={12}
                placeholder={t(`${tKey}NUMDOSSIER`)}
                controlLabel={t(`${tKey}NUMDOSSIER`)}
                component={InputText}
                maxLength={50}
              />
            </Row>
            <Row id="internalManagementDocModal_documents" name="internalManagementDocModal_documents" className="inputs-wrapper-templateDataModal flexRow">
              <Col sm={12} className="custom-width-col">
                <h2>{t(`${tKey}DOCUMENTS`)}</h2>
              </Col>
              <Col sm={11}>
                {t(`${tKey}DECLARATION`)}
              </Col>
              <Col sm={1}>
                <Field
                  component={InputCheckboxSlider}
                  colSm={12}
                  id="internalManagementDocModal_declaration"
                  name="declaration"
                />
              </Col>
              <Col sm={11} className='margin-top-10'>
                {t(`${tKey}AUTONOMOUS`)}
              </Col>
              <Col sm={1}>
                <Field
                  component={InputCheckboxSlider}
                  colSm={12}
                  id="internalManagementDocModal_autonomous"
                  name="autonomous"
                />
              </Col>
              <Col sm={11} className='margin-top-10'>
                {t(`${tKey}FISICALENTITY`)}
              </Col>
              <Col sm={1}>
                <Field
                  component={InputCheckboxSlider}
                  colSm={12}
                  id="internalManagementDocModal_fisicalEntity"
                  name="fisicalEntity"
                />
              </Col>
              <Col sm={11} className='margin-top-10'>
                {t(`${tKey}PRIVATE_BUSINESS_CORPORATIONS`)}
              </Col>
              <Col sm={1}>
                <Field
                  component={InputCheckboxSlider}
                  colSm={12}
                  id="internalManagementDocModal_privateBusinessCorporations"
                  name="privateBusinessCorporations"
                />
              </Col>
              <Col sm={11} className='margin-top-10'>
                {t(`${tKey}ASOCIATIONS`)}
              </Col>
              <Col sm={1}>
                <Field
                  component={InputCheckboxSlider}
                  colSm={12}
                  id="internalManagementDocModal_asociations"
                  name="asociations"
                />
              </Col>
              <Col sm={11} className='margin-top-10'>
                {t(`${tKey}CIVIL_SOCIETY`)}
              </Col>
              <Col sm={1}>
                <Field
                  component={InputCheckboxSlider}
                  colSm={12}
                  id="internalManagementDocModal_civilSociety"
                  name="civilSociety"
                />
              </Col>
              <Col sm={11} className='margin-top-10'>
                {t(`${tKey}AUTONOMOUS_HELP_CERTIFICATE`)}
              </Col>
              <Col sm={1}>
                <Field
                  component={InputCheckboxSlider}
                  colSm={12}
                  id="internalManagementDocModal_autonomousHelpCertificate"
                  name="autonomousHelpCertificate"
                />
              </Col>
              <Col sm={11} className='margin-top-10'>
                {t(`${tKey}REDUCED_MOBILITY`)}
              </Col>
              <Col sm={1}>
                <Field
                  component={InputCheckboxSlider}
                  colSm={12}
                  id="internalManagementDocModal_reducedMobility"
                  name="reducedMobility"
                />
              </Col>
              <Col sm={11} className='margin-top-10'>
                {t(`${tKey}CERTIFICATE_ACCOUNT_HOLDER`)}
              </Col>
              <Col sm={1}>
                <Field
                  component={InputCheckboxSlider}
                  colSm={12}
                  id="internalManagementDocModal_certificateAccountHolder"
                  name="certificateAccountHolder"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="buttons-wrapper-templateDataModal">
              <Button id="internalManagementDocModal-submit-button" type="submit" className="btn-standard" >
                {t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.GROUP_DOCUMENTS_CREATE_MODAL.ACCEPT')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}

  if (!values.category) {
    errors.category = props.t('GLOBAL.FORMS.REQUIRED')
  }

  if (!values.position) {
    errors.position = props.t('GLOBAL.FORMS.REQUIRED')
  }

  if (!values.damagedVehicleCategory) {
    errors.damagedVehicleCategory = props.t('GLOBAL.FORMS.REQUIRED')
  }

  if (!values.brand) {
    errors.brand = props.t('GLOBAL.FORMS.REQUIRED')
  }

  if (!values.model) {
    errors.model = props.t('GLOBAL.FORMS.REQUIRED')
  }

  if (!values.licensePlate) {
    errors.licensePlate = props.t('GLOBAL.FORMS.REQUIRED')
  } else {
    if (values.licensePlateFormat !== true) {
      errors.licensePlate = validateLicensePlateFunciton(values.licensePlate, false, props.t('GLOBAL.FORMS.INVALID_STRING'))
    }
  }

  if (!values.vinChassis) {
    errors.vinChassis = props.t('GLOBAL.FORMS.REQUIRED')
  }

  if (!values.numDossier) {
    errors.numDossier = props.t('GLOBAL.FORMS.REQUIRED')
  }

  return errors
}

export default reduxForm({
  form: 'internalManagementDocModal',
  validate,
  destroyOnUnmount: true
})(InternalManagementDocModal)

import apiFetch from '../apiFetch'

export default function (documentTypeUseCode, token, dossierId, dynamicValues, checkListId) {
  const queryParamsArray = [
    documentTypeUseCode ? `documentTypeUseCode=${documentTypeUseCode}` : null,
    dossierId ? `dossierId=${dossierId}` : null,
    checkListId ? `checklistId=${checkListId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `Document/GenerateDocumentFromDynamicTemplate${queryParams}`, parseBlobAndAssign: true, method: 'POST', body: dynamicValues, token: token })
}

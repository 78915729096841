import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DocumentEdition from '../../../../_v2/components/dossiers/common/documentaryManagement/documentEditionModal/DocumentEditionModal'
import documentEditionModalActions from '../../../../actions/dossier/common/documentEditionModal'
import { sendDMS } from '../../../../actions/charges/dms/dms'
import { cancelDocument, openModalVehicleNecessary, printCheckList, printTemplateByReferenceType } from '../../../../actions/dossier/common/documentManagementModal'
import { showSmallLoader, hideSmallLoader } from '../../../../actions/common'
import { setWindowDimensions } from '../../../../actions/commons/windowDimensions'
import { translate } from 'react-polyglot'
import { closeOperateFleetModal } from '../../../../actions/management/management'
import { setOperateDataChargeFleetProcess, createIncorporateDocumentFleetProcess, createValidateDocumentFleetProcess, createReEditDocumentFleetProcess, createCancelDocumentFleetProcess, createRequestSignDocumentFleetProcess, createCancelSignDocumentFleetProcess, createRejectDocumentFleetProcess } from '../../../../actions/unattendedProcess/unattendedProcess'
import { openReservationContractModal } from '../../../../actions/dossier/common/reservationContractModal'
import { fetchInsuranceCompanyCombo } from '../../../../actions/combos/combos'
import messageModalActions from '../../../../actions/modals/messageModal'
import { openInternalManagementDocModal, openResponsibleDeclarationDocModal } from '../../../../actions/modals/templateDataModal'

export function mapStateToProps (state) {
  return {
    ...state.documentEditionModal,
    windowWidth: state.windowDimensions.width,
    windowHeight: state.windowDimensions.height,
    checkListIdReciboPagaYSenal: state.documentaryManagementModal.checkListIdReciboPagaYSenal,
    token: state.auth.token,
    combos: {
      documentsTypes: state.combos.documentsTypes
    },
    isLoading: state.common.componentLoading
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...documentEditionModalActions,
      cancelDocument,
      showSmallLoader,
      hideSmallLoader,
      setWindowDimensions,
      openModalVehicleNecessary,
      printCheckList,
      closeOperateFleetModal,
      setOperateDataChargeFleetProcess,
      createIncorporateDocumentFleetProcess,
      createValidateDocumentFleetProcess,
      createReEditDocumentFleetProcess,
      createCancelDocumentFleetProcess,
      createRequestSignDocumentFleetProcess,
      createCancelSignDocumentFleetProcess,
      createRejectDocumentFleetProcess,
      sendDMS,
      printTemplateByReferenceType,
      openReservationContractModal,
      fetchInsuranceCompanyCombo,
      openInternalManagementDocModal,
      openResponsibleDeclarationDocModal,
      ...messageModalActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentEdition))
